import React, { useEffect, useState } from "react";
import "@/css/thermalPrint.css";
import API_URLS from "@/config";
import { useTranslation } from "react-i18next";
// import axios from "axios";
import { useAuth } from "@/provider/authProvider";
import { useNavigate } from "react-router-dom";
import DataFetcher from "@/provider/DataFetcher";
import QRCode from "react-qr-code";

interface CompanyData {
  name: string;
  id: number;
  tin: string;
  employees: number;
  additionalInfo: string;
  city: string;
  country: string;
  phoneNumber: string;
  email: string;
  fieldOfActivity: string;
  fiscalizationCertificate: string;
  isSeasonal: boolean;
  subscriptionStartDateUtc: string;
  subscriptionEndDateUtc: string;
}

type Product = {
  id: string;
  barcode: string;
  price: number;
  uoMId: number;
  vatRateId: number;
  categoryId: string;
  vatRate: number;
  isRawMaterial: boolean;
  productId: number;
  name: string;
  quantity: number;
  unitPrice: number;
  total: number; // This is
};
interface Employee {
  id: string;
  name: string;
}

interface ExchangeRate {
  currency1Id: number;
  currency2Id: number;
  rate: number;
}

type InvoiceProps = {
  orderProducts: Product[];
  totalAmountExVat: number;
  vatAmount: number;
  totalAmountIncVat: number;
  tableId: string; // Add tableId to InvoiceProps
  // employeeID: string;
  employeeId: string;
  tablename: string; // Add tableId to InvoiceProps
  orderNo: undefined;
};
interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}

type VatRate = {
  id: number;
  rate: number;
};


// Define TypeScript interface for a Currency
interface Currency {
  id: number;
  name: string;
  symbol:string;
}

const decodeJWT = (token: string) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join("")
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Failed to decode token", error);
    return null;
  }
};
const LocalPrint = React.forwardRef<HTMLDivElement, InvoiceProps>((props, ref) => {
  const {
    orderProducts,
    totalAmountExVat,
    vatAmount,
    totalAmountIncVat,
    tablename,
    employeeId,
    orderNo,
  } = props;

  const ExchangeRate_STORAGE_KEY = "exchangerateData";
  const EMPLOYEE_STORAGE_KEY = "employeeSalesData";
      const Currency_STORAGE_KEY = "currencyData";
const [productsMap, setProductsMap] = useState<{ [key: string]: string }>({});
 const VAT_STORAGE_KEY = "vatData";
   const PRODUCT_STORAGE_KEY = "productData";
  const [, setVatRates] = useState<VatRate[]>([]);
  const [, setProductList] = useState<Product[]>([]);

  const [companyData, setCompanyData] = useState<CompanyData | null>(null);
  const [exchangeRate, setExchangeRate] = useState<number | null>(null);
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [printerType, setPrinterType] = useState();
  const { setToken, token } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
 const [baseCurrencyName, setBaseCurrencyName] = useState("Lekë"); // Default currency
const [SecondCurrencyName, setSecondCurrencyName] = useState("Euro"); // Default currency
const [SecondCurrencySymbol, setSecondCurrencySymbol] = useState("€"); // Default currency symbol

const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L"); // Default currency symbol
  const [, setCurrencies] = useState<Currency[]>([]); // Holds list of currencies


  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        // Fetch the list of companies from the API
        const response = await DataFetcher.getData<CompanyData[]>(
          `${API_URLS.BASE_URL}${API_URLS.BUSINESSREGISTER}`,
          "businessData"
        );

        if (response && response.length > 0) {
          // Decode the token to get the tenantId
          if (token) {
            const decodedToken = decodeJWT(token);
            if (decodedToken && decodedToken.tenantId) {
              const tenantId = Number(decodedToken.tenantId); // Ensure tenantId is a number

              // Find the company data that matches the tenantId
              const matchedCompany = response.find(
                (company) => company.id === tenantId
              );

              if (matchedCompany) {
                setCompanyData(matchedCompany);
              } else {
                console.error(
                  "No matching company data found for tenantId:",
                  tenantId
                );
                // Handle the case where no matching company is found
                setCompanyData(null); // Or any other default value or error handling
              }
            } else {
              console.error("Invalid token data");
              // Handle invalid token data
              setCompanyData(null); // Or any other default value or error handling
            }
          } else {
            console.error("Token is null or undefined");
            // Handle the case where the token is not available
            setCompanyData(null); // Or any other default value or error handling
          }
        } else {
          console.error("No company data found");
          // Handle the case where no company data is returned
          setCompanyData(null); // Or any other default value or error handling
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
        // Handle any errors during the fetch
        setCompanyData(null); // Or any other default value or error handling
      }
    };


    const fetchEmployeeData = async () => {
      const employeeData = await DataFetcher.getData<Employee>(
        `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}/${employeeId}`,
        EMPLOYEE_STORAGE_KEY
      );

      if (employeeData) {
        setEmployee(employeeData);
      } else {
        throw new Error("Failed to fetch employee data");
      }
    };
    const fetchVatRatesAndProducts = async () => {
      // First, fetch VAT rates
      const vatRatesResponse = await DataFetcher.getData<VatRate[]>(
        `${API_URLS.BASE_URL}${API_URLS.VAT}`,
        VAT_STORAGE_KEY
      );
      if (vatRatesResponse) {
        setVatRates(vatRatesResponse);

        // After VAT rates are fetched, fetch products
        const productsResponse = await DataFetcher.getData<Product[]>(
          `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`,
          PRODUCT_STORAGE_KEY
        );
        if (productsResponse) {
          const filteredProducts = productsResponse.filter(
            (product) => !product.isRawMaterial
          );

          if (filteredProducts) {
            setProductList(
              filteredProducts.map((product) => ({
                ...product,
                quantity: 0,
                vatRate:
                  vatRatesResponse.find((rate) => rate.id === product.vatRateId)
                    ?.rate || 0,
              }))
            );
  //   //  // Create a map of productId to productName for easy lookups
         const productsById: { [key: string]: string } = {};
          filteredProducts.forEach((product) => {
            productsById[product.id] = product.name;
          });
          setProductsMap(productsById);
        }
      }
    }
  };

    fetchCompanyData();
    fetchEmployeeData();
        fetchVatRatesAndProducts()

  }, [token, setToken, navigate, employeeId]);




useEffect(() => {
  // Fetch available currencies using DataFetcher with caching
  const fetchCurrencyData = async () => {
    try {
      const response = await DataFetcher.getData<Currency[]>(
        `${API_URLS.BASE_URL}${API_URLS.Currency}`,  // API endpoint
        Currency_STORAGE_KEY,                       // LocalStorage key
      );

      setCurrencies(response || []); // Set fetched currencies, default to an empty array if none

      // Retrieve business configs from localStorage
      const businessConfigs: BusinessConfigs = JSON.parse(
        localStorage.getItem("businessConfigs") || "{}"
      );

      const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1
      const secondCurrencyId = businessConfigs.secondCurrencyId ?? 2; // Default to 2

      const baseCurrency = response?.find(currency => currency.id === baseCurrencyId);
      const secondCurrency = response?.find(currency => currency.id === secondCurrencyId);

      setBaseCurrencyName(baseCurrency?.name || "Leke"); // Default to "Leke"
      setBaseCurrencySymbol(baseCurrency?.symbol || "L"); // Default to "L"
      setSecondCurrencyName(secondCurrency?.name || "Euro")
      setSecondCurrencySymbol(secondCurrency?.symbol || "L")
      // Fetch exchange rates and find the correct one
      const exchangeRates = await DataFetcher.getData<ExchangeRate[]>(
        `${API_URLS.BASE_URL}${API_URLS.ExchangeRates}`,  // API endpoint
        ExchangeRate_STORAGE_KEY                         // LocalStorage key
      );

      if (exchangeRates && exchangeRates.length > 0) {
        // Find the exchange rate between the base and second currency
        const rate = exchangeRates.find(
          (exchange) =>
            exchange.currency1Id === baseCurrencyId &&
            exchange.currency2Id === secondCurrencyId
        );

        if (rate) {
          setExchangeRate(rate.rate);
        } else {
          console.error("No exchange rate found between the selected currencies.");
        }
      } else {
        console.error("Exchange rates data is empty or null.");
      }
    } catch (error) {
      console.error("Error fetching currencies or exchange rates:", error);
    }
  };

  fetchCurrencyData();
}, [t]);


  const truncateString = (str: string, num: number): string => {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    }
    return str;
  };

  const calculateTotalInEuros = () => {
    const totalInLek = parseFloat(totalAmountIncVat.toString());
    if (exchangeRate) {
      return (totalInLek / exchangeRate).toFixed(2);
    }
    return "N/A";
  };
  const qrString = `Order No: ${orderNo}, Customer: Rasti`;
  useEffect(() => {
    // Fetch the businessConfigs from localStorage
    const businessConfigs = JSON.parse(
      localStorage.getItem("businessConfigs") || "{}"
    );
    const storedPrinterType = businessConfigs?.printerType;
    setPrinterType(storedPrinterType);
    console.log(storedPrinterType);
  }, []);
  const invoiceClass =
    printerType === 1 ? "invoice-print-a4" : "invoice-print-thermal";

  return (
    <div ref={ref} className={invoiceClass}>
      {printerType === 1 ? (
        <>
          {/* A4 Invoice Layout */}
          <div className="invoice-header-a4">
            <div className="company-details-a4">
              <h2>{companyData ? companyData.name : t("Company Name")}</h2>
              <p>{companyData ? companyData.tin : t("tin")}</p>
              <p>
                {companyData ? companyData.city : t("AdrressCity")},{" "}
                {companyData ? companyData.country : t("AdrressCountry")}
              </p>
              <p>{companyData ? companyData.email : t("Email")}</p>
              <p>{companyData ? companyData.phoneNumber : t("phoneNumber")}</p>
            </div>
            <div className="invoice-details-a4">
              <p>
                {t("INVOICENo")}: {orderNo}
              </p>
              <p>
                {t("Date")} : {new Date().toLocaleDateString()}
              </p>
            </div>
          </div>

          <div className="customer-details-a4">
            <p>{t("customer")}: Rasti</p>
            {tablename &&
              tablename !== "Unknown Table" && ( // Hide if tablename is null, undefined, or equals "Unknown Table"
                <p>
                  {t("Table")}: {tablename}
                </p>
              )}
            <p>
              {t("employeeName")}: {employee ? employee.name : t("loading")}
            </p>
          </div>

          <table className="invoice-items-a4">
            <thead>
              <tr>
                <th>{t("ITEM")}</th>
                <th>{t("UNITCOST")}</th>
                <th>{t("QUANTITY")}</th>
                <th>{t("TOTAL")}</th>
              </tr>
            </thead>
            <tbody>
              {orderProducts.map((product) => (
                <tr key={product.productId}>
                  <td>{truncateString(productsMap[product.productId] || 'Unknown Product', 15)}</td>
                  <td>
                    {product.unitPrice.toFixed(2)}
                    {baseCurrencySymbol}
                  </td>
                  <td>{product.quantity}</td>
                  <td>
                    {(product.unitPrice * product.quantity).toFixed(2)}
                    {baseCurrencySymbol}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <table className="invoice-totals-a4">
            <tbody>
              <tr>
                <th> {t("Subtotal")}</th>
                  <p> {totalAmountExVat.toFixed(2)}
                {baseCurrencySymbol}</p>
              </tr>
              <tr>
                <th>{t("VAT")}</th>{" "}
                <td>
                  {vatAmount.toFixed(2)} {baseCurrencySymbol}
                </td>
              </tr>
              <tr>
                <th> {t("TOTALlek")}</th>{" "}
                <td>{totalAmountIncVat.toFixed(2)} </td>
                {baseCurrencySymbol}
              </tr>
              {exchangeRate && (
                <tr>
                  <th> {t("TOTALeuro")}</th> <td>{calculateTotalInEuros()} </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="qr-code-section-a4">
            <QRCode value={qrString} size={128} />
          </div>

          <div className="invoice-footer-a4">
            <p>{t("Thank You")}</p>
            <p>{companyData ? companyData.name : t("Company Name")}</p>
          </div>
        </>
      ) : (
        <>
          <div className="invoice-header-thermal">
            <div className="company-details-thermal">
              <h2>{companyData ? companyData.name : t("Company Name")}</h2>
              <p>{companyData ? companyData.tin : t("tin")}</p>
              <p>
                {companyData ? companyData.city : t("AdrressCity")},
                {companyData ? companyData.country : t("AdrressCountry")}
              </p>
              <p>{companyData ? companyData.email : t("Email")}</p>
              <p>{companyData ? companyData.phoneNumber : t("phoneNumber")}</p>
            </div>
            <div className="invoice-details-thermal">
              <p>
                {t("INVOICENo")} :{orderNo}
              </p>
              <p>
                {t("Date")} : {new Date().toLocaleDateString()}
              </p>
              <h2>{t("Fature Permbledhese ")}</h2>
            </div>
          </div>
          <div className="customer-details-thermal">
            <p>{t("customer")}: Rasti</p>
            {tablename &&
              tablename !== "Unknown Table" && ( // Hide if tablename is null, undefined, or equals "Unknown Table"
                <p>
                  {t("Table")}: {tablename}
                </p>
              )}
            <p>
              {t("employeeName")}: {employee ? employee.name : t("loading")}
            </p>
          </div>

          <div className="invoice-items-thermal">
            <div className="invoice-item-thermal header">
              <span>{t("ITEMSales")}</span>
              <span>{t("QUANTITY")}</span>
              <span>{t("UNITCOST")}</span>
              <span>{t("TOTAL")}</span>
            </div>
            {orderProducts.map((product) => (
              <div key={product.id}>
                {/* Main product information */}
                <div className="invoice-item-thermal w-full">
                  <span className="w-1/4 pl-20 text-right">
                    {product.quantity}
                  </span>
                  <span className="w-1/4 text-right pl-5">
                    {product.unitPrice.toFixed(2)}
                    {baseCurrencySymbol}
                  </span>
                  <span className="w-1/4 text-right">
                    {(product.unitPrice * product.quantity).toFixed(2)}
                    {baseCurrencySymbol}
                  </span>
                </div>

                <div className="invoice-product-discount flex w-full">
                  <span className="w-1/2 product-name text-left">
                  <td>{truncateString(productsMap[product.productId] || 'Unknown Product', 15)}</td>
                  </span>
                </div>

                {/* Separator Line */}
                <div className="separator-line"></div>
              </div>
            ))}
          </div>
          <div className="invoice-totals-thermal">
            <div className="totals-row-thermal">
              <span>{t("Subtotal")}</span>
                            <p> {totalAmountExVat.toFixed(2)}
                {baseCurrencySymbol}</p>

              
            </div>
            <div className="totals-row-thermal">
              <span>{t("VAT")}</span>
              <span>
                {vatAmount.toFixed(2)}
                {baseCurrencySymbol}
              </span>
            </div>
            <div className="totals-row-thermal">
              <span>
                <strong>{t("TOTALlek")}{baseCurrencyName}</strong>
              </span>
              <span>
                <strong>
                  {totalAmountIncVat.toFixed(2)}
                  {baseCurrencySymbol}
                </strong>
              </span>
            </div>
            {exchangeRate && (
              <div className="totals-row-thermal">
                <span>
                  <strong>{t("TOTALeuro")}{SecondCurrencyName}</strong>
                </span>
                <span>
                  <strong>
                    {calculateTotalInEuros()}
{SecondCurrencySymbol}                    
                  </strong>
                </span>
              </div>
            )}
          </div>
          <div className="qr-code-section">
            <QRCode value={qrString} size={128} />
          </div>
          <div className="invoice-footer-thermal">


             
            <p>{t("Thank You")}</p>
            <p>{companyData ? companyData.name : t("Company Name")}</p>
          </div>
        </>
      )}
    </div>
  );
});
LocalPrint.displayName = "LocalPrint";

export default LocalPrint;
