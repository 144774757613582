import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Button } from '@/components/ui/button';
import { CheckCircleIcon, XCircleIcon } from 'lucide-react';

type ErrorDetail = {
    propertyName?: string;
    errorMessage: string;
    attemptedValue?: string;
    errorType?: 'Validation' | 'Server'; // 'Validation' for 400 errors, 'Server' for 500 errors
};

type CustomerResult = {
    id: string;
    name: string;
    status: 'Success' | 'Failed';
    errors?: ErrorDetail[];
};

const ImportProduct: React.FC = () => {
    const [products, setProducts] = useState<CustomerResult[]>([]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        // Fetch import results from local storage
        const results = JSON.parse(localStorage.getItem("importProduct") || "[]");
        setProducts(results);
    }, []);

    const handleClearAndNavigate = () => {
        // Clear import results from local storage
        localStorage.removeItem("importProduct");

        // Navigate to a different path
        navigate(t("/prodlistpath"));
    };

    return (
        <div style={{ paddingTop: '40px' }}>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead>{t("product")}</TableHead>
                        <TableHead>{t("Status")}</TableHead>
                        <TableHead>{t("Error Detail")}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {products.map((product, index) => (
                        <TableRow key={`${product.id}-${index}`}>
                            <TableCell>{product.name}</TableCell>
                            <TableCell>
                                {product.status === 'Success' ? (
                                    <CheckCircleIcon className="text-green-500 h-6 w-6" /> // Success icon
                                ) : (
                                    <XCircleIcon className="text-red-500 h-6 w-6" /> // Error icon
                                )}
                            </TableCell>
                            <TableCell>
                                {product.status === 'Failed' ? (
                                    <div>
                                        {product.errors?.map((error, idx) => (
                                            <div key={idx}>
                                                {error.errorType === 'Validation' && error.propertyName ? (
                                                    <>
                                                        <strong>
                                                            <span style={{ color: 'red' }}>{t(`errorProduct.${error.propertyName}`)}</span>
                                                        </strong>:
                                                        {t(`errorProduct.${error.errorMessage}`)}
                                                    </>
                                                ) : (
                                                    <>{t(`errorProduct.${error.errorMessage}`)}</>
                                                )}
                                                {error.attemptedValue && (
                                                    <div>
                                                        <em>{t("Attempted Value")}: {error.attemptedValue}</em>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    '-'
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
                <div className="fixed bottom-4 right-4 md:bottom-4 md:right-4">

                    <Button
                        type="button"
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                        onClick={handleClearAndNavigate}
                    >
                        OK
                    </Button>
                </div>
            </div>
    );
};

export default ImportProduct;
