import React, { useState, useRef, useEffect } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import NumericKeypad from "./numericKeyboard";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import InvoiceThermalPrint from "./InvocieThermalPrint";
import "@/css/dialogBoxCheckout.css";
import { Product } from "@/types/ProductType";
import DataFetcher from "@/provider/DataFetcher";
import API_URLS from "@/config";
import { ExchangeRate } from "@/types/ExchangeRateType";
interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}

interface CheckoutDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  totalAmount: number;
  onSubmit: (
    amountReceived: number,
    exchangeRate: number,
    selectedCurrencyDetails?: {
      id: number;
      name: string;
      symbol: string;
    },
    printInvoice?: () => void,
    calculatedChangeAmount?: number
  ) => void;

  orderProducts: {
    product: Product;
    quantity: number;
    discount?: number;
    discountType?: "value" | "percentage";
  }[];
  customers: { id: string; name: string }[];
  selectedCustomer: string;
  calculateSubtotalAndDiscount: () => {
    subtotal: string;
    totalOrderDiscount: string;
    totalProductDiscounts: string;
    totalDiscountSum: string;
  };
  calculateVAT: () => string;
  calculateTotal: () => string;
  getVATRateName: (vatRateId: number) => string;
  getVATRate: (vatRateId: number) => number;
  onTotalDiscountChange: (
    discount: number,
    discountType: "value" | "percentage"
  ) => void;
}
const CheckoutDialog: React.FC<CheckoutDialogProps> = ({
  open,
  onOpenChange,
  totalAmount,
  onSubmit,
  orderProducts,
  customers,
  selectedCustomer,
  calculateSubtotalAndDiscount,
  calculateVAT,
  calculateTotal,
  getVATRateName,
  getVATRate,
}) => {
  const { t } = useTranslation();
  const [amountReceived, setAmountReceived] = useState<string>("");
  const [currencyList, setCurrencyList] = useState<any[]>([]);
  const baseCurrencyId = (() => {
    const businessConfig = JSON.parse(
      localStorage.getItem("businessConfigs") || "{}"
    );
    return businessConfig?.baseCurrencyId;
  })();
  const [selectedCurrency, setSelectedCurrency] =
    useState<string>(baseCurrencyId);
  const [exchangeRate, setExchangeRate] = useState<number>(1);
  const [baseCurrency, setBaseCurrency] = useState<any>(null);
  const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L"); // Default currency symbol

  const [selectedCurrencyDetails, setSelectedCurrencyDetails] = useState<{
    id: number;
    name: string;
    symbol: string;
  }>({ id: 1, name: "Lekë", symbol: "L" });
  const invoiceRef = useRef<HTMLDivElement>(null);

  const ExchangeRate_STORAGE_KEY = "exchangerateData";

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await DataFetcher.getData<any[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`,
          "currencyData"
        );
        setCurrencyList(response ?? []);

        // Retrieve business configs from localStorage
        const businessConfigs: BusinessConfigs = JSON.parse(
          localStorage.getItem("businessConfigs") || "{}"
        );

        const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1

        const baseCurrency = response?.find(
          (currency) => currency.id === baseCurrencyId
        );

        setBaseCurrencySymbol(baseCurrency?.symbol || "L"); // Default to "L"

        // Find the currency matching baseCurrencyId if response is available
        if (response && response.length > 0 && baseCurrencyId) {
          const foundBaseCurrency = response.find(
            (currency) => currency.id === baseCurrencyId
          );

          if (foundBaseCurrency) {
            setBaseCurrency(foundBaseCurrency); // Set baseCurrency in state
          } else {
            console.warn(
              "Base currency not found in the fetched currency list"
            );
          }
        }
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    };

    fetchCurrencies();
  }, [baseCurrencyId]); // Ensure that baseCurrencyId is a dependency for this effect

  useEffect(() => {
    const initializeSelectedCurrency = () => {
      const storedCurrencyDetails = localStorage.getItem(
        "selectedCurrencyDetails"
      );

      if (storedCurrencyDetails) {
        setSelectedCurrencyDetails(JSON.parse(storedCurrencyDetails));
      } else if (baseCurrency) {
        const defaultCurrencyDetails = {
          id: baseCurrency.id,
          name: baseCurrency.name,
          symbol: baseCurrency.symbol,
        };
        setSelectedCurrencyDetails(defaultCurrencyDetails);
      }
    };

    initializeSelectedCurrency();
  }, [baseCurrency]);

  useEffect(() => {
    if (selectedCurrency && baseCurrency) {
      fetchExchangeRate(selectedCurrency, baseCurrency);
    }
  }, [selectedCurrency, baseCurrency]);

  const fetchExchangeRate = async (currencyId: string, baseCurrency: any) => {
    if (Number(currencyId) === Number(baseCurrencyId)) {
      setExchangeRate(1);
      const currencyDetails = {
        id: baseCurrency.id,
        name: baseCurrency.name,
        symbol: baseCurrency.symbol,
      };
      setSelectedCurrencyDetails(currencyDetails);
      localStorage.setItem(
        "selectedCurrencyDetails",
        JSON.stringify(currencyDetails)
      );
      return;
    }

    console.log("Selected Currency ID:", currencyId);
    console.log("Base Currency ID:", baseCurrencyId);
    console.log("Current Exchange Rate:", exchangeRate);

    try {
      const exchangeRates = await DataFetcher.getData<ExchangeRate[]>(
        `${API_URLS.BASE_URL}${API_URLS.ExchangeRates}`,
        ExchangeRate_STORAGE_KEY
      );
      const foundCurrency = currencyList.find(
        (currency) => String(currency.id) === currencyId
      );

      if (foundCurrency) {
        const currencyDetails = {
          id: foundCurrency.id,
          name: foundCurrency.name,
          symbol: foundCurrency.symbol,
        };
        setSelectedCurrencyDetails(currencyDetails);
        localStorage.setItem(
          "selectedCurrencyDetails",
          JSON.stringify(currencyDetails)
        );
      }

      if (exchangeRates && exchangeRates.length > 0) {
        const exchangeRate = exchangeRates.find(
          (rate) =>
            rate.currency2Id === Number(baseCurrencyId) &&
            rate.currency1Id === Number(currencyId)
        );

        if (exchangeRate) {
          setExchangeRate(exchangeRate.rate);
          localStorage.setItem(
            "exchangeRate",
            JSON.stringify(exchangeRate.rate)
          );
        }
      }
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
    }
  };

  const calculateTotalInSelectedCurrency = () => {
    if (!exchangeRate) return "N/A";
    return (totalAmount / exchangeRate).toFixed(2);
  };

  const amountReceivedNewCurr = (
    parseFloat(amountReceived) * exchangeRate
  ).toFixed(2);

  const changeAmount = (
    parseFloat(amountReceivedNewCurr) - totalAmount
  ).toFixed(2);

  const handlePay = () => {
    const amount = parseFloat(amountReceivedNewCurr);
    const amountReceived1 = parseFloat(amountReceived);
    const calculatedChangeAmount = parseFloat(changeAmount);

    if (!isNaN(amount)) {
      console.log("Exchange Rate before submit:", exchangeRate);
      console.log("Calculated Change Amount:", typeof calculatedChangeAmount);
      onSubmit(
        amountReceived1,
        exchangeRate,
        selectedCurrencyDetails,
        handlePrint,
        calculatedChangeAmount
      );
      setTimeout(() => {
        if (baseCurrency) {
          setSelectedCurrency(baseCurrency.id);
        }
      }, 5000);
      setAmountReceived("");
      onOpenChange(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });

  const handleCurrencyChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedValue = event.target.value;
    setSelectedCurrency(selectedValue);
    if (baseCurrency) {
      fetchExchangeRate(selectedValue, baseCurrency);
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    const key = event.key;

    if (!isNaN(Number(key)) || key === ".") {
      setAmountReceived((prev) => prev + key);
    } else if (key === "Backspace") {
      setAmountReceived((prev) => prev.slice(0, -1));
    } else if (key === "Enter") {
      handlePay();
    } else if (key === "Escape") {
      setAmountReceived("");
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("keydown", handleKeyPress);
    } else {
      document.removeEventListener("keydown", handleKeyPress);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [open]);
  console.log("Calculated Change Amount:", changeAmount);
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="custom-dialog-content">
        <div className="table">
          <h2>
            {t("TotalAmount")} : {totalAmount?.toFixed(2) || "0.00"}{" "}
            {baseCurrencySymbol}
          </h2>
          {selectedCurrency !== baseCurrencyId && (
            <h2>
              {t("Total in")} {selectedCurrencyDetails?.name || "Unknown"}:{" "}
              {calculateTotalInSelectedCurrency()}{" "}
              {selectedCurrencyDetails?.symbol || ""}
            </h2>
          )}
        </div>

        <div>
          <label>{t("SelectCurrency")}</label>
          <select value={selectedCurrency} onChange={handleCurrencyChange}>
            <option value="">{t("Select")}</option>
            {currencyList.map((currency) => (
              <option key={currency.id} value={currency.id}>
                {currency.name}
              </option>
            ))}
          </select>
          {exchangeRate !== 1 && (
            <p>
              {t("ExchangeRate")}: {exchangeRate}
            </p>
          )}
        </div>

        <div className="text-xl">
          <h3>
            {t("AmountReceived")}:{" "}
            {(Number(amountReceivedNewCurr) || 0.0).toFixed(2)}{" "}
            {baseCurrencySymbol}{" "}
          </h3>
          <h3 className="changeamount">
            {t("ChangeAmount")}:{}
            {(Number(changeAmount) || 0.0).toFixed(2)} {baseCurrencySymbol}
          </h3>
        </div>

        <NumericKeypad
          amountReceived={amountReceived}
          onInput={(value) => setAmountReceived((prev) => prev + value)}
          onClear={() => setAmountReceived("")}
          onBackspace={() => setAmountReceived((prev) => prev.slice(0, -1))}
          onEnter={handlePay}
        />
      </DialogContent>

      <div style={{ display: "none" }}>
        <InvoiceThermalPrint
          ref={invoiceRef}
          orderProducts={orderProducts}
          customers={customers}
          selectedCustomer={selectedCustomer}
          calculateSubtotalAndDiscount={calculateSubtotalAndDiscount}
          calculateVAT={calculateVAT}
          calculateTotal={calculateTotal}
          getVATRateName={getVATRateName}
          getVATRate={getVATRate}
          selectedCurrencyDetails={selectedCurrencyDetails}
          totalInSelectedCurrency={calculateTotalInSelectedCurrency()}
          exchangeRate={exchangeRate}
          employeeName={""}
          orderNo={undefined}
          tableId={undefined}
          tablename={undefined}
          printerType={0}
          date={""}
        />
      </div>
    </Dialog>
  );
};

export default CheckoutDialog;
