import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ArrowUpDown, ChevronDown, Filter, MoreHorizontal } from "lucide-react";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@/css/loading.css";
import API_URLS from "@/config";
import { Supplier } from "@/types/SupplierType";
import * as ExcelJS from "exceljs";
import CreateSupplierDto from "@/models/CreateSupplierDto";
import DataFetcher from "@/provider/DataFetcher";
import { EmployeeType } from "@/types/EmployeeType";

const SUPPLIER_STORAGE_KEY = "supplierData";
const EMPLOYEE_STORAGE_KEY = "employeeData";

const SupplierList: React.FC = () => {
  const location = useLocation();

  const [data, setData] = useState<Supplier[]>([]);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "id", desc: true },
  ]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    id: false,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  type RowSelection = Record<string, boolean>;

  const [rowSelection, setRowSelection] = useState<RowSelection>({});
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSuppliersFromServer = async (): Promise<Supplier[]> => {
      const supplierResponse = await DataFetcher.getData<Supplier[]>(
        `${API_URLS.BASE_URL}${API_URLS.SUPPLIER}`,
        SUPPLIER_STORAGE_KEY,
        true
      );
      const suppliers = supplierResponse;
      localStorage.setItem(SUPPLIER_STORAGE_KEY, JSON.stringify(suppliers));
      return suppliers || [];
    };
    const fetchEmployees = async (): Promise<EmployeeType[]> => {
      try {
        return (
          (await DataFetcher.getData<EmployeeType[]>(
            `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
            EMPLOYEE_STORAGE_KEY
          )) || []
        );
      } catch (error) {
        console.error("Error fetching employees:", error);
        return [];
      }
    };

    const updateStateWithSuppliers = (
      suppliers: Supplier[],
      employees: EmployeeType[]
    ) => {
      const employeeMap = Array.isArray(employees)
        ? employees.reduce<Record<number, string>>((acc, employee) => {
            acc[employee.id] = employee.name;
            return acc;
          }, {})
        : {};

      const updatedSuppliers = suppliers.map((supplier) => ({
        ...supplier,
        createdByName: supplier.createdBy
          ? employeeMap[supplier.createdBy] || ""
          : "",
        updatedByName: supplier.updatedBy
          ? employeeMap[supplier.updatedBy] || ""
          : "",
      }));

      setData(updatedSuppliers);
    };

    const fetchData = async () => {
      try {
        const [suppliers, employees] = await Promise.all([
          fetchSuppliersFromServer(),
          fetchEmployees(),
        ]);

        updateStateWithSuppliers(suppliers, employees);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (supplierId: string) => {
    navigate(t("/editsupplierpath"), { state: { id: supplierId } });
  };

  const handleDelete = async (supplierId: string) => {
    try {
      await axios.delete(
        `${API_URLS.BASE_URL}${API_URLS.SUPPLIER}/${supplierId}`
      );
      const updatedData = data.filter((supplier) => supplier.id !== supplierId);
      setData(updatedData);
      localStorage.setItem(SUPPLIER_STORAGE_KEY, JSON.stringify(updatedData));
      toast.success(t("Supplier deleted successfully"));
    } catch (error) {
      console.error("Error deleting supplier:", error);
      toast.error(t("Error deleting supplier"));
    }
  };
  const handleDeleteSelected = async () => {
    // Get selected row IDs from rowSelection
    const selectedRowIds = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    // Extract employee IDs to delete based on selected rows
    const supplierIdsToDelete = data
      .filter((_item, index) => selectedRowIds.includes(index.toString())) // Adjust this if rowSelection keys are different
      .map((item) => item.id); // Assuming `item.id` is the employee ID

    if (supplierIdsToDelete.length === 0) {
      toast.error(t("Please select records to delete."));
      return;
    }

    try {
      // Delete selected employees from the API
      await Promise.all(
        supplierIdsToDelete.map((id) =>
          axios.delete(`${API_URLS.BASE_URL}${API_URLS.SUPPLIER}/${id}`)
        )
      );

      // Remove deleted employees from the state
      const updatedData = data.filter(
        (item) => !supplierIdsToDelete.includes(item.id)
      );
      setData(updatedData);
      setRowSelection({});

      // Update local storage with the updated data
      localStorage.setItem(SUPPLIER_STORAGE_KEY, JSON.stringify(updatedData));

      toast.success(t("Selected records deleted successfully."));
    } catch (error) {
      console.error("Failed to delete records:", error);
      toast.error(t("Failed to delete selected records."));
    }
  };

  const handleADD = () => {
    navigate(t("/createsupplierpath"));
  };

  useEffect(() => {
    if (!loading && location.state?.showToast) {
      toast.success(location.state.message);
    }
  }, [loading, location.state]);

  const exportToExcel = async () => {
    // Get the selected rows or use the full data if no rows are selected
    const selectedRows = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    const rowsToExport = selectedRows.length > 0 ? selectedRows : data;

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Suppliers");

    // Define columns based on the data structure
    worksheet.columns = [
      // { header: 'ID', key: 'id', width: 20 },
      { header: "Name", key: "name", width: 30 },
      { header: "Phone Number", key: "phoneNumber", width: 30 },
      { header: "Email Address", key: "email", width: 30 },
      { header: "Contact", key: "contact", width: 30 },
      { header: "VAT", key: "vatNumber", width: 30 },

      { header: "Address", key: "address", width: 40 },
      { header: "City", key: "city", width: 20 },
      { header: "Country", key: "country", width: 20 },
    ];

    // Map rowsToExport to match the worksheet columns
    const worksheetData = rowsToExport.map((customer) => ({
      //  id: customer.id,
      name: customer.name,
      phoneNumber: customer.phoneNumber,
      email: customer.email,
      contact: customer.contact,
      // customerCategoryId: customer.customerCategoryId,
      vatName: customer.vatName,
      address: customer.address,
      city: customer.city,
      country: customer.country,
    }));

    // Add rows to worksheet
    worksheet.addRows(worksheetData);

    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(
      new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "suppliers.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(data);

          // Get the first worksheet
          const worksheet = workbook.worksheets[0];
          const suppliers: Supplier[] = [];

          // Extract data from worksheet
          worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            if (rowNumber > 1) {
              // Skip header row

              const name = row.getCell(1).text; // Name
              const id = row.getCell(11).text; // ID (Phone Number)
              const email = row.getCell(3).text; // Email
              const phoneNumber = row.getCell(2).text; // Phone Number
              const contact = row.getCell(4).text; // Contact
              // const vatName = row.getCell(5).text; // VAT Name
              const address = row.getCell(6).text; // Address
              const city = row.getCell(7).text; // City
              const country = row.getCell(8).text; // Country

              // Create supplier object with VAT Number as string
              const supplier: Supplier = {
                name,
                email,
                phoneNumber,
                contact,
                // vatName, // VAT Number as string
                address,
                city,
                country,
                id,
                phone: null,
                vatNumber: 0,
                createdBy: 0,
                updatedBy: 0,
                createdByName: "",
                updatedByName: "",
              };

              suppliers.push(supplier);
            }
          });

          // Call importSupplier with the parsed supplier data
          importSupplier(suppliers);
        };
        reader.readAsArrayBuffer(file);
      } catch (error) {
        console.error("Error reading Excel file:", error);
      }
    }
  };

  const importSupplier = async (suppliers: Supplier[]) => {
    try {
      // Fetch VAT rates from local storage
      const vatRates = JSON.parse(localStorage.getItem("vatData") || "[]").data;

      // Create lookup maps for VAT rates
      const vatRateMap = vatRates.reduce(
        (
          map: Record<string, number>,
          vatRate: { name: string; id: number }
        ) => {
          map[vatRate.name.trim().toLowerCase()] = vatRate.id;
          return map;
        },
        {}
      );

      // Format each supplier using CreateSupplierDto
      const formattedSuppliers = suppliers.map((supplier) => {
        // Find VAT number ID if available in VAT rate map
        const vatNumberId =
          vatRateMap[supplier.vatName?.trim().toLowerCase() || ""] || 0;

        // Create the DTO for the supplier
        return new CreateSupplierDto({
          ...supplier,
          vatNumber: vatNumberId.toString(), // Ensure VAT number is sent as a string
        });
      });

      // Initialize arrays to track successful and failed responses
      const successfulResponses = [];
      const failedResponses = [];

      // Upload suppliers to the API and retrieve the responses
      for (const supplier of formattedSuppliers) {
        try {
          const response = await axios.post(
            `${API_URLS.BASE_URL}${API_URLS.SUPPLIER}`,
            supplier
          );
          successfulResponses.push({
            id: response.data.id,
            name: response.data.name,
            status: "Success",
          });
        } catch (error) {
          let formattedErrors = [];
          if (axios.isAxiosError(error)) {
            if (error.response) {
              const statusCode = error.response.status;

              // Handle 400 errors (validation errors)
              if (statusCode === 400 && Array.isArray(error.response.data)) {
                formattedErrors = error.response.data.map((err: any) => ({
                  propertyName: err.propertyName,
                  errorMessage: err.errorMessage,
                  attemptedValue: err.attemptedValue,
                }));
              }
              // Handle 500 errors (Internal Server Error) and others
              else if (statusCode === 500 || statusCode >= 500) {
                formattedErrors.push({
                  errorMessage: t(
                    "Internal Server Error. Please try again later."
                  ),
                });
              } else {
                // Handle other potential errors
                formattedErrors.push({
                  errorMessage: `An error occurred: ${statusCode}. Please try again.`,
                });
              }
            } else {
              // Handle cases where there is no response, possibly due to network issues
              formattedErrors.push({
                errorMessage:
                  "Network error. Please check your connection and try again.",
              });
            }
          } else {
            // Handle any unexpected errors
            formattedErrors.push({
              errorMessage: "An unknown error occurred. Please try again.",
            });
          }

          failedResponses.push({
            name: supplier.name,
            status: "Failed",
            errors: formattedErrors,
          });
          console.error(`Error importing supplier '${supplier.name}':`, error);
        }
      }

      // Retrieve existing suppliers from local storage
      const existingSuppliers = JSON.parse(
        localStorage.getItem("supplierData") || "[]"
      );

      // Add the newly created suppliers from the successful API responses to the existing list
      const updatedSuppliers = [...existingSuppliers, ...successfulResponses];

      // Save the updated supplier list back to local storage
      localStorage.setItem("supplierData", JSON.stringify(updatedSuppliers));

      // Save only the last import result to local storage
      const lastResult = [...successfulResponses, ...failedResponses];
      localStorage.setItem("importSupplier", JSON.stringify(lastResult));

      // Navigate to the results page
      navigate(t("/importsupplier"));
    } catch (error) {
      // Handle errors that occur outside of the main loop, e.g., network or critical errors
      if (axios.isAxiosError(error) && error.response) {
        const { data } = error.response;
        if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorSupplier.${err.errorMessage}`) ||
              t("errorSupplier.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          const defaultMessage = t("An error occurred");
          toast.error(defaultMessage);
        }
      } else {
        const unexpectedMessage = t("An unexpected error occurred");
        toast.error(unexpectedMessage);
      }
      console.error("Error importing suppliers:", error);
    }
  };

  const columns: ColumnDef<Supplier>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("ID")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("id")}</div>,
    },
    {
      accessorKey: "name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("name")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("name")}</div>,
    },
    {
      accessorKey: "phoneNumber",
      header: t("telnumber"),
      cell: ({ row }) => <div>{row.getValue("phoneNumber")}</div>,
    },
    {
      accessorKey: "email",
      header: "Email",
      cell: ({ row }) => <div>{row.getValue("email")}</div>,
    },
    {
      accessorKey: "contact",
      header: t("contact"),
      cell: ({ row }) => <div>{row.getValue("contact")}</div>,
    },
    {
      accessorKey: "vatNumber",
      header: t("vatNumber"),
      cell: ({ row }) => <div>{row.getValue("vatNumber")}</div>,
    },
    {
      accessorKey: "address",
      header: t("adresss"),
      cell: ({ row }) => <div>{row.getValue("address")}</div>,
    },
    {
      accessorKey: "city",
      header: t("city"),
      cell: ({ row }) => <div>{row.getValue("city")}</div>,
    },
    {
      accessorKey: "country",
      header: t("country"),
      cell: ({ row }) => <div>{row.getValue("country")}</div>,
    },
    {
      accessorKey: "createdByName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("createdBy")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("createdByName")}</div>,
    },
    {
      accessorKey: "updatedByName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("updatedBy")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("updatedByName")}</div>,
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{t("action")}</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => handleEdit(row.original.id)}>
              {t("edit")}
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => handleDelete(row.original.id)}>
              {t("delete")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      globalFilter,
    },
    globalFilterFn: (row) => {
      return (
        row.original.name?.toLowerCase().includes(globalFilter) ||
        row.original.email?.toLowerCase().includes(globalFilter)
      );
    },
  });

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex flex-wrap items-center py-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="mr-10 flex items-center">
              <Square3Stack3DIcon className="h-5 w-5" />
              <span className="hidden sm:flex sm:items-center">
                {t("action")} <ChevronDown className="ml-2 h-4 w-4" />
              </span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => document.getElementById("file-input")?.click()}
            >
              {t("Import")}
            </DropdownMenuItem>{" "}
            <DropdownMenuItem onClick={exportToExcel}>
              {t("export")}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleDeleteSelected}>
              {t("delete")}
            </DropdownMenuItem>{" "}
          </DropdownMenuContent>
        </DropdownMenu>
        <Input
          placeholder={t("filtersupplier")}
          value={globalFilter}
          onChange={(event) => setGlobalFilter(event.target.value)}
          style={{
            width: window.innerWidth <= 999 ? "45%" : "100%",
                         fontSize: "16px"

          }}
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              className="ml-auto flex items-center justify-center"
            >
              <span className="hidden md:block ">{t("columns")}</span>
              <Filter className="md:ml-2 h-4 w-4 mx-auto" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => column.toggleVisibility(!!value)}
                >
                  {column.id}
                </DropdownMenuCheckboxItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onDoubleClick={() => handleEdit(row.original.id)}
                  style={{ cursor: "pointer" }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t("noresult")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {t("previous")}
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {t("next")}
          </Button>
        </div>
      </div>
      <div className="fixed bottom-4 right-4 md:bottom-4 md:right-4">
        <Button
          type="button"
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleADD}
        >
          +
        </Button>
      </div>
      <ToastContainer />
      <Input
        id="file-input"
        type="file"
        accept=".xlsx"
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
    </div>
  );
};

export default SupplierList;
