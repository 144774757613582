class ChangePasswordDto {
  userType: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;

  constructor(changepasword: {
    userType?: string;
    oldPassword?: string;
    newPassword?: string;
    confirmPassword?: string;
  }) {
    this.userType = changepasword.userType ?? "";
    this.oldPassword = changepasword.oldPassword ?? "";
    this.newPassword = changepasword.newPassword ?? "";
    this.confirmPassword = changepasword.confirmPassword ?? "";
  }
}

export default ChangePasswordDto;
