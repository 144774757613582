class CreatePaymentTermsDto {
  number: number;
  name: string;

  constructor(PaymentTerms: { number?: number; name?: string }) {
    this.number = PaymentTerms.number || 0;
    this.name = PaymentTerms.name || "";
  }
}

export default CreatePaymentTermsDto;
