import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/provider/authProvider";
import DataFetcher from "@/provider/DataFetcher";
import CreateAccountTransferDto from "@/models/CreateAccountTransferDto";

// Updated form schema for transferring an amount between accounts
const formSchema = z.object({
  fromAccountId: z.string().min(1, { message: "From account is required" }),
  toAccountId: z.string().min(1, { message: "To account is required" }),
  transferAmount: z.number().min(1, { message: "Transfer amount must be positive" }),
  note: z.string().optional(), // Optional field for notes
});

type FormValues = z.infer<typeof formSchema>;

interface CashAccount {
  id: number;
  balance: number;
  currencyId: string;
  name: string;
  iban?: string | null;
  swift?: string | null;
  owners: { id: number }[];
}

const CashAccountTransfer: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employeeId } = useAuth();

  const CashAccount_STORAGE_KEY = "cashaccountData";
  const [cashAccounts, setCashAccounts] = useState<CashAccount[]>([]);
  const [employeeCashAccounts, setEmployeeCashAccounts] = useState<CashAccount[]>([]); // State for employee-specific accounts
  const [parsedEmployeeId, setParsedEmployeeId] = useState<number | null>(null);

  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fromAccountId: "",
      toAccountId: "",
      transferAmount: 0,
      note: "", // Default value for the note field
    },
  });

  // Set parsed employee ID
  useEffect(() => {
    setParsedEmployeeId(employeeId ? parseInt(employeeId, 10) : null);
  }, [employeeId]);

  // Fetch all cash accounts for "From Account"
  useEffect(() => {
    const fetchAllCashAccounts = async () => {
      try {
        const response = await DataFetcher.getData<CashAccount[]>(
          `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
          CashAccount_STORAGE_KEY,
          true
        );
        if (response) {
          setCashAccounts(response); // Set all cash accounts for "From Account"
        }
      } catch (error) {
        console.error("Error fetching all cash accounts:", error);
      }
    };

    fetchAllCashAccounts();
  }, []);

  // Fetch employee-specific cash accounts for "To Account"
  useEffect(() => {
    if (parsedEmployeeId !== null) {
      const fetchEmployeeCashAccounts = async () => {
        try {
          const response = await DataFetcher.getData<CashAccount[]>(
            `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
            CashAccount_STORAGE_KEY,
            true
            
          );
          if (response) {
            const employeeAccounts = response.filter((account) =>
              account.owners.some((owner) => owner.id === parsedEmployeeId)
            );
            setEmployeeCashAccounts(employeeAccounts); // Set employee-specific accounts for "To Account"
          }
        } catch (error) {
          console.error("Error fetching employee-specific cash accounts:", error);
        }
      };

      fetchEmployeeCashAccounts();
    }
  }, [parsedEmployeeId]);

const onSubmit: SubmitHandler<FormValues> = async (data) => {
  const { fromAccountId, toAccountId, transferAmount, note } = data;

  // Prevent transferring to the same account
  if (fromAccountId === toAccountId) {
    toast.error(t("Cannot transfer to the same account"));
    return;
  }

  try {
    // Fetch account details for validation
    const fromAccount = cashAccounts.find(
      (account) => account.id.toString() === fromAccountId
    );
    const toAccount = employeeCashAccounts.find(
      (account) => account.id.toString() === toAccountId
    );

    // Check if both accounts are found
    if (!fromAccount || !toAccount) {
      toast.error(t("Accounts not found"));
      return;
    }

    // Check if currencies match between accounts
    if (fromAccount.currencyId !== toAccount.currencyId) {
      toast.error(t("Cannot transfer between accounts with different currencies"));
      return;
    }

    // Ensure the source account has enough balance
    if (fromAccount.balance < transferAmount) {
      toast.error(t("Insufficient balance in the source account"));
      return;
    }

    // Create transfer DTO with validated data
    const transferDto = new CreateAccountTransferDto({
      sourceCashAccountId: parseInt(fromAccountId, 10),
      destinationCashAccountId: parseInt(toAccountId, 10),
      amount: transferAmount,
      note: note || null, // Optional note field
    });

    // Proceed with the API call to perform the transfer
    await axios.post(`${API_URLS.BASE_URL}${API_URLS.ACCOUNTTRANSFER}`, transferDto);

    toast.success(t("Transfer completed successfully"));
    methods.reset();
  } catch (error) {
    toast.error(t("Error processing the transfer"));
  }
};

  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
        <div className="w-full max-w-lg p-6 rounded shadow-md">
          <div className="cash-transfer-editor">
            <ToastContainer />
            <h2>{t("Transfer Funds")}</h2>

            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {/* From Account */}
              <FormItem>
                <FormLabel>{t("From Account")} <span style={{ color: "red" }}>*</span></FormLabel>
                <FormControl>
                  <Controller
                    name="fromAccountId"
                    control={methods.control}
                    render={({ field }) => (
                      <Select
                        onValueChange={(value) => field.onChange(value)}
                        value={field.value}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder={t("Select to account")} />
                        </SelectTrigger>
                        <SelectContent>
                          {cashAccounts.map((account) => (
                            <SelectItem
                              key={account.id}
                              value={account.id.toString()}
                            >
                              {account.name} - {account.balance}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </FormControl>
                {methods.formState.errors.fromAccountId && (
                  <FormMessage>
                    {methods.formState.errors.fromAccountId.message}
                  </FormMessage>
                )}
              </FormItem>

              {/* To Account */}
              <FormItem>
                <FormLabel>{t("To Account")}<span style={{ color: "red" }}>*</span></FormLabel>
                <FormControl>
                  <Controller
                    name="toAccountId"
                    control={methods.control}
                    render={({ field }) => (
                      <Select
                        onValueChange={(value) => field.onChange(value)}
                        value={field.value}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder={t("Select to account")} />
                        </SelectTrigger>
                        <SelectContent>
                          {employeeCashAccounts.map((account) => (
                            <SelectItem
                              key={account.id}
                              value={account.id.toString()}
                            >
                              {account.name} - {account.balance}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}
                  />
                </FormControl>
                {methods.formState.errors.toAccountId && (
                  <FormMessage>
                    {methods.formState.errors.toAccountId.message}
                  </FormMessage>
                )}
              </FormItem>

              {/* Transfer Amount */}
              <FormItem>
                <FormLabel>{t("Transfer Amount")}<span style={{ color: "red" }}>*</span></FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("transferAmount", { valueAsNumber: true })}
                    type="number"
                                              pattern="\d*"

                    placeholder={t("Enter amount")}
                  />
                </FormControl>
                {methods.formState.errors.transferAmount && (
                  <FormMessage>
                    {methods.formState.errors.transferAmount.message}
                  </FormMessage>
                )}
              </FormItem>

              {/* Note */}
              <FormItem>
                <FormLabel>{t("Note")}</FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("note")}
                    type="search"
                    placeholder={t("Enter note")}
                  />
                </FormControl>
              </FormItem>

              {/* Submit button */}
                <div className="flex gap-4 mt-6">
                <Button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {t("Transfer")}
                </Button>
                <Button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  {t("canclelbtn")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default CashAccountTransfer;
