import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useTranslation } from "react-i18next";

type DiscountDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (discountType: "value" | "percentage", discount: number) => void;
};

const DiscountDialog: React.FC<DiscountDialogProps> = ({
  isOpen,
  onClose,
  onSave,
}) => {
  const [discountType, setDiscountType] = useState<"value" | "percentage">(
    "value"
  );
  const [discount, setDiscount] = useState<number>(0);
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      setDiscount(0);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [isOpen]);

  const handleSave = () => {
    onSave(discountType, discount);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogTitle>{t("Add Discount")}</DialogTitle>
        <DialogDescription>
          {t("Select discount type and value")}
        </DialogDescription>
        <div className="mb-4">
          <label className="mr-4">
            <input
              type="radio"
              value="value"
              checked={discountType === "value"}
              onChange={() => setDiscountType("value")}
            />
            {t("Value")}
          </label>
          <label>
            <input
              type="radio"
              value="percentage"
              checked={discountType === "percentage"}
              onChange={() => setDiscountType("percentage")}
            />
            {t("Percentage")}
          </label>
        </div>
        <Input
          ref={inputRef} // Attach the ref to the input
          type="number"
          placeholder={
            discountType === "value" ? t("Enter value") : t("Enter percentage")
          }
          value={discount}
          onChange={(e) => setDiscount(parseFloat(e.target.value))}
          className="mb-4"
        />
        <Button onClick={handleSave} className="mr-2">
          {t("Apply")}
        </Button>
        <Button variant="ghost" onClick={onClose}>
          {t("Cancel")}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default DiscountDialog;
