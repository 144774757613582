class CreateLotDto {
  lotNumber: string;
  expiryDateUtc: string;

  constructor(lot: { lotNumber?: string; expiryDateUtc?: string }) {
    this.lotNumber = lot.lotNumber ?? "";
    this.expiryDateUtc = lot.expiryDateUtc ?? "";
  }
}

export default CreateLotDto;
