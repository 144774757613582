class CreateProductDto {
  name: string;
  description: string;
  price: number;
  cost: number;
  vatRateId: number | null;
  uoMId: number | null;
  sku: string | null;
  barcode: string | null;
  categoryId?: number | null;
  brand: string;
  image?: string | null;
  ReorderLevel: number;
  supplierId: number | null;
  supplierCode: string;
  stockLevel: number;
  weight: number;
  volume: number;
  isComposite: boolean;
  isCombo: boolean;
  isRawMaterial: boolean;
  isService: boolean;
  trackInventory: boolean;

  constructor(product: {
    name?: string;
    description?: string;
    price?: number;
    cost?: number;
    vatRateId?: number | null;
    uoMId?: number | null;
    sku?: string | null;
    barcode?: string | null;
    categoryId?: number | null;
    brand?: string;
    image?: string | null;
    ReorderLevel?: number;
    supplierId?: number | null;
    supplierCode?: string;
    stockLevel?: number;
    weight?: number;
    volume?: number;
    isComposite?: boolean;
    isCombo?: boolean;
    isRawMaterial?: boolean;
    isService?: boolean;
    trackInventory?: boolean;
  }) {
    this.name = product.name || "";
    this.description = product.description || "";
    this.price = product.price || 0;
    this.cost = product.cost || 0;
    this.vatRateId = product.vatRateId ?? null;
    this.uoMId = product.uoMId ?? null;
    this.sku = product.sku ?? null;
    this.barcode = product.barcode  ?? null;
    this.categoryId = product.categoryId ?? null;
    this.brand = product.brand || "";
    this.image = product.image || null;
    this.ReorderLevel = product.ReorderLevel || 0;
    this.supplierId = product.supplierId ?? null;
    this.supplierCode = product.supplierCode || "";
    this.stockLevel = product.stockLevel || 0;
    this.weight = product.weight || 0;
    this.volume = product.volume || 0;
    this.isComposite = product.isComposite || false;
    this.isCombo = product.isCombo || false;
    this.isRawMaterial = product.isRawMaterial || false;
    this.isService = product.isService || false;
    this.trackInventory = product.trackInventory || false;
  }
}

export default CreateProductDto;
