import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import API_URLS from "@/config";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@/css/LoginPage.css";
import { useTranslation } from "react-i18next";

const formSchema = z.object({
  newPassword: z.string().min(1, { message: "New password is required" }),
  confirmPassword: z
    .string()
    .min(1, { message: "Confirm password is required" }),
});

type FormValues = z.infer<typeof formSchema>;

const ResetPasswordPage = () => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token, employeeId } = useParams<{
    token: string;
    employeeId: string;
  }>();

  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  const handleSubmit = async (data: FormValues) => {
    const { newPassword, confirmPassword } = data;

    if (newPassword !== confirmPassword) {
      toast.error(t("Passwords do not match."));
      return;
    }

    if (!token || !employeeId) {
      return;
    }

    const parsedEmployeeId = parseInt(employeeId, 10);
    if (isNaN(parsedEmployeeId)) {
      return;
    }

    try {
      await axios.post(`${API_URLS.BASE_URL}${API_URLS.resetPassword}`, {
        token,
        newPassword,
        employeeId: parsedEmployeeId,
      });

      toast.success(t("Password reset successful!"));
      setIsSuccess(true);

      setTimeout(() => navigate("/login"), 3000);
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message ||
        t("Failed to reset password. Please try again.");
      toast.error(errorMessage);
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="login-container">
        <div className="login-form">
          <div className="avatar">👤</div>
          <h2>{t("resetPassword")}</h2>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <FormItem>
              <FormLabel className="text-black dark:text-black">
                {t("newPassword")}
              </FormLabel>
              <FormControl>
                <Input
                  type="password"
                  {...methods.register("newPassword")}
                  placeholder={t("EnternewPassword")}
                />
              </FormControl>
              {methods.formState.errors.newPassword && (
                <FormMessage>
                  {methods.formState.errors.newPassword.message}
                </FormMessage>
              )}
            </FormItem>
            <FormItem>
              <FormLabel className="text-black dark:text-black">
                {t("ConfirmnewPassword")}
              </FormLabel>
              <FormControl>
                <Input
                  type="password"
                  {...methods.register("confirmPassword")}
                  placeholder={t("ConfirmnewPassword")}
                />
              </FormControl>
              {methods.formState.errors.confirmPassword && (
                <FormMessage>
                  {methods.formState.errors.confirmPassword.message}
                </FormMessage>
              )}
            </FormItem>
            <Button type="submit" className="button" disabled={isSuccess}>
              {t("resetPassword")}
            </Button>
            <div className="forgot-username-link">
              <Link to="/login">{t("backtologin")}</Link>
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>
    </FormProvider>
  );
};

export default ResetPasswordPage;
