import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import CreatePaymentTermsDto from "@/models/CreatePaymentTermsDTO";
// import { PaymentTerms } from "@/types/PaymentTermsType";
import { PaymentTermsFormProps } from "@/interfaces/PaymentTermsInterfaces";
import "@/css/LoadingCreate.css";

const PaymentTermsForm: React.FC<PaymentTermsFormProps> = ({
  mode,
  inDialog = false,
  onClose,
}) => {
  const { t } = useTranslation();

  const formSchema = z.object({
    number: z.number().min(1, { message: t("Number is required") }),
  });

  type FormValues = z.infer<typeof formSchema>;
  const [loading, setLoading] = useState(false); // Initialize loading state

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {
    id: localStorage.getItem("paymentTermsId"),
  };
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      number: 0,
    },
  });

  useEffect(() => {
    if (id) {
      localStorage.setItem("paymentTermsId", id);
    }

    if (mode === "edit" && id) {
      const fetchPaymentTerms = async () => {
        try {
          const response = await axios.get(
            `${API_URLS.BASE_URL}${API_URLS.PaymentTerms}/${id}`
          );
          methods.reset(response.data);
        } catch (error) {
          console.error("Error fetching payment terms:", error);
        }
      };

      fetchPaymentTerms();
    }
  }, [id, mode, methods, t]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true);

    const PaymentTermsData = new CreatePaymentTermsDto({
      number: data.number,
      name: `${t("Net")} ${data.number} ${t("days")}`,
    });

    try {
      let successMessage = "";

      if (mode === "create") {
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.PaymentTerms}`,
          PaymentTermsData
        );
        successMessage = t("Payment terms created successfully");

        if (onClose) {
          onClose({
            id: response.data.id,
            number: response.data.number,
            name: response.data.name,
          });
        }
      } else if (mode === "edit" && id) {
        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.PaymentTerms}/${id}`,
          PaymentTermsData
        );
        successMessage = t("Payment terms updated successfully");
        console.log("Payment terms updated successfully:", response.data);
      }

      if (onClose) {
        onClose();
      }
      methods.reset();
      localStorage.removeItem("paymentTermsId");

      if (!inDialog) {
        navigate(t("/paymentTermslistpath"));
      }

      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorPaymentTerms.${err.errorMessage}`) ||
              t("errorPaymentTerms.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting payment terms:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-3xl p-6 rounded shadow-md flex justify-center">
          <div style={{ padding: "20px" }}>
            <h1>
              {mode === "create"
                ? t("/createpaymentTerm")
                : t("/editpaymentTerm")}
            </h1>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <FormItem>
                  <FormLabel className="flex justify-center m-2">
                    {t("number")} <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <span>{t("Net")}</span>
                    <FormControl>
                      <Input
                        {...methods.register("number", {
                          valueAsNumber: true,
                        })}
                        type="number"
                                                pattern="\d*"
                        style={{ width: "70px" }}
                      />
                    </FormControl>
                    <span>{t("days")}</span>
                  </div>
                  {methods.formState.errors.number && (
                    <FormMessage>
                      {methods.formState.errors.number.message}
                    </FormMessage>
                  )}
                </FormItem>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "50px",
                }}
              >
                <Button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {mode === "create" ? t("savelbtn") : t("savelbtn")}
                </Button>
                <Button
                  type="button"
                  onClick={() =>
                    inDialog
                      ? onClose?.()
                      : navigate(t("/paymentTermslistpath"))
                  }
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  {t("canclelbtn")}
                </Button>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
    </FormProvider>
  );
};
export default PaymentTermsForm;
