class CreateRoleDto {
  name: string;
  description: string;
  permissionIds: number[] | undefined;

  constructor(role: {
    name?: string;
    description?: string;
    permissionIds: number[] | undefined;
  }) {
    this.name = role.name || "";
    this.description = role.description || "";
    this.permissionIds = role.permissionIds || [];
  }
}

export default CreateRoleDto;
