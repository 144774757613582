import * as React from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import "@/css/DatePicker.css";

interface DatePickerWithRangeProps {
  className?: string;
  onSelect: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
  selectedRange: DateRange | undefined; // New prop to receive the selected range from the parent
}

export function DatePickerWithRange({
  className,
  onSelect,
  selectedRange, // Accept the selected range from the parent
}: DatePickerWithRangeProps) {
  const currentDate = new Date();
  const firstDayOfCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  // Use the selected range from the parent component as initial state
  const [date, setDate] = React.useState<DateRange | undefined>({
    from: firstDayOfCurrentMonth,
    to: currentDate,
  });

  // Update local date state if selectedRange prop changes
  React.useEffect(() => {
    if (selectedRange) {
      setDate(selectedRange);
    }
  }, [selectedRange]);

  const handleSelect = (selectedDate: DateRange | undefined) => {
    if (selectedDate) {
      setDate(selectedDate); // Update local state
      onSelect(selectedDate); // Update parent component's state
    }
  };

  const calendarRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.focus();
    }
  }, [date]);

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[300px] justify-start text-left font-normal",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "MMMM dd, yyyy")} -{" "}
                  {format(date.to, "MMMM dd, yyyy")}
                </>
              ) : (
                format(date.from, "MMMM dd, yyyy")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <div ref={calendarRef}>
            <Calendar
              mode="range"
              defaultMonth={date?.from}
              selected={date}
              onSelect={handleSelect}
              numberOfMonths={2}
              modifiers={{
                selectedRange: { from: date?.from, to: date?.to },
              }}
              modifiersClassNames={{
                selectedRange: "selected-range",
              }}
            />
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
