import API_URLS from "@/config";
import DataFetcher from "@/provider/DataFetcher";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

interface Product {
  id: number;
  name: string;
}

interface SoldProduct {
  productId: number;
  totalQuantitySold: number;
}

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884d8",
  "#82ca9d",
  "#a4de6c",
  "#d0ed57",
];

const MostSoldProducts: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<{ name: string; value: number }[]>([]);
  const PRODUCT_STORAGE_KEY = "productDataForm";
  const DASHBOARD_STORAGE_KEY = "dashboardData";
  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.warn("No token found. Please log in.");
      return;
    }

    const fetchData = async () => {
      try {
        const dashboardResponse = await DataFetcher.getData<any>(
          `${API_URLS.BASE_URL}${API_URLS.Dashboard}`,
          DASHBOARD_STORAGE_KEY,
          true
        );
        const mostSoldProducts: SoldProduct[] =
          dashboardResponse?.fiveMostSoldProductIdsAllTime || [];

        const products = await fetchProductsFromServer();

        const chartData = mostSoldProducts.map((soldProduct) => {
          const product = products.find((p) => p.id === soldProduct.productId);
          return {
            name: product ? product.name : `Product ${soldProduct.productId}`,
            value: soldProduct.totalQuantitySold,
          };
        });

        setData(chartData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const fetchProductsFromServer = async (): Promise<Product[]> => {
    const productResponse = await DataFetcher.getData<Product[]>(
      `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`,
      PRODUCT_STORAGE_KEY
    );
    return productResponse || [];
  };

  return (
    <div style={{ width: "100%", height: 300 }}>
      <h2>{t("Most Sold Products")}</h2>
      <ResponsiveContainer>
        <PieChart>
          <Pie data={data} dataKey="value" outerRadius={100} label>
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MostSoldProducts;
