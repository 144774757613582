import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import "@/css/loading.css";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ArrowUpDown, ChevronDown, Filter, MoreHorizontal } from "lucide-react";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
// import CreateRoleDto from "@/models/CreateRoleDto";
import * as ExcelJS from "exceljs";
import { Role } from "@/types/RoleType";
import DataFetcher from "@/provider/DataFetcher";

const ROLE_STORAGE_KEY = "roleData";

const RoleList = () => {
  const [data, setData] = useState<Role[]>([]);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "id", desc: true },
  ]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    id: false,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  type RowSelection = Record<string, boolean>;

  const [rowSelection, setRowSelection] = useState<RowSelection>({});
  const { t } = useTranslation();
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoleData = async () => {
      try {
        const response = await DataFetcher.getData<Role[]>(
          `${API_URLS.BASE_URL}${API_URLS.ROLE}`,
          ROLE_STORAGE_KEY,
          true
        );
        const roles = response;
        localStorage.setItem(ROLE_STORAGE_KEY, JSON.stringify(roles));
        setData(response || []);
      } catch (error) {
        console.error("Error fetching roles:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchRoleData();
  }, []);

  const handleEdit = (roleId: string) => {
    navigate(t("/editrolepath"), { state: { id: roleId } });
  };

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`${API_URLS.BASE_URL}${API_URLS.ROLE}/${id}`);
      const updatedData = data.filter((role) => role.id !== id);
      setData(updatedData);
      localStorage.setItem(ROLE_STORAGE_KEY, JSON.stringify(updatedData));
      toast.success(t("Role deleted successfully"));
    } catch (error) {
      console.error("Error deleting role:", error);
      toast.error(t("Error deleting role"));
    }
  };

  const handleDeleteSelected = async (): Promise<void> => {
  // Get selected row IDs from rowSelection
  const selectedRowIds = Object.keys(rowSelection).filter(
    (key) => rowSelection[key]
  );

  // Extract role IDs to delete based on selected rows
  const roleIdsToDelete: string[] = data
    .filter((_item, index) => selectedRowIds.includes(index.toString()))
    .map((item) => item.id); // Assuming `item.id` is the role ID

  if (roleIdsToDelete.length === 0) {
    toast.error(t("Please select records to delete."));
    return;
  }

  // Attempt to delete roles
  const deleteResults = await Promise.all(
    roleIdsToDelete.map(async (id: string) => {
      try {
        await axios.delete(`${API_URLS.BASE_URL}${API_URLS.ROLE}/${id}`);
        return { id, success: true }; // Return success for this ID
      } catch (error) {
        console.error(`Failed to delete record with ID ${id}:`, error);
        return { id, success: false }; // Return failure for this ID
      }
    })
  );

  const successfullyDeletedIds = deleteResults
    .filter(result => result.success)
    .map(result => result.id);

  const failedToDeleteIds = deleteResults
    .filter(result => !result.success)
    .map(result => result.id);

  // Update state and local storage only for successfully deleted records
  const updatedData = data.filter(
    (item) => !successfullyDeletedIds.includes(item.id)
  );

  setData(updatedData);
  setRowSelection({}); // Clear row selection

  // Update local storage with the updated data
  localStorage.setItem(
    ROLE_STORAGE_KEY,
    JSON.stringify(updatedData)
  );

  // Show success messages for successfully deleted records
  if (successfullyDeletedIds.length > 0) {
    toast.success(t("Selected records deleted successfully."));
  }

  // Show error messages for failed deletions
  if (failedToDeleteIds.length > 0) {
  }
};


  const handleADD = () => {
    navigate(t("/createrolepath"));
  };

  useEffect(() => {
    if (!loading && location.state?.showToast) {
      toast.success(location.state.message);
    }
  }, [loading, location.state])

  const exportToExcel = async () => {
    // Get the selected rows or use the full data if no rows are selected
    const selectedRows = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    const rowsToExport = selectedRows.length > 0 ? selectedRows : data;

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Employees");

    // Define columns based on the data structure
    worksheet.columns = [
      // { header: 'ID', key: 'id', width: 10 },
      { header: "Name", key: "name", width: 30 },
      { header: "Description", key: "description", width: 30 },
    ];

    // Map rowsToExport to match the worksheet columns
    const worksheetData = rowsToExport.map((role) => ({
      name: role.name,
      description: role.description,
    }));

    // Add rows to worksheet
    worksheet.addRows(worksheetData);

    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(
      new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "roles.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const importRoles = async (roles: Role[]) => {
  //   try {
  //     // Format each role using CreateRoleDto
  //     const formattedRoles = roles.map((role) => {
  //       // Ensure name and description are treated as strings
  //       const name = String(role.name);
  //       const description = role.description ? String(role.description) : "";

  //       // Map role names to their IDs, handle undefined names

  //       return new CreateRoleDto({
  //         name,
  //         description,
  //       });
  //     });

  //     // Upload roles to the API and retrieve the responses
  //     const responses = await Promise.all(
  //       formattedRoles.map((role) =>
  //         axios.post(`${API_URLS.BASE_URL}${API_URLS.ROLE}`, role)
  //       )
  //     );
  //     const existingRoles = JSON.parse(
  //       localStorage.getItem("roleData") || "[]"
  //     );

  //     // Add the newly created roles from the API responses to the existing list
  //     const updatedRoles = [
  //       ...existingRoles,
  //       ...responses.map((response) => response.data), // Include new roles in the list
  //     ];

  //     // Save the updated roles list back to local storage
  //     localStorage.setItem("roleData", JSON.stringify(updatedRoles));

  //     // Show success message
  //     toast.success(t("Roles imported successfully"));

  //     // Optionally refresh the page to reflect the changes
  //     window.location.reload();
  //   } catch (error) {
  //     // Handle error and show error message
  //     if (axios.isAxiosError(error) && error.response) {
  //       const { data } = error.response;
  //       if (data && Array.isArray(data)) {
  //         data.forEach((err: { errorMessage: string }) => {
  //           const translatedMessage =
  //             t(`errorRole.${err.errorMessage}`) ||
  //             t("errorRole.An error occurred");
  //           toast.error(translatedMessage);
  //         });
  //       } else {
  //         const defaultMessage = t("An error occurred");
  //         toast.error(defaultMessage);
  //       }
  //     } else {
  //       const unexpectedMessage = t("An unexpected error occurred");
  //       toast.error(unexpectedMessage);
  //     }
  //     console.error("Error importing roles:", error);
  //   }
  // };

  // const handleFileUpload = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const file = event.target.files?.[0];
  //   if (file) {
  //     try {
  //       const reader = new FileReader();
  //       reader.onload = async (e) => {
  //         const data = new Uint8Array(e.target?.result as ArrayBuffer);
  //         const workbook = new ExcelJS.Workbook();
  //         await workbook.xlsx.load(data);

  //         // Get the first worksheet
  //         const worksheet = workbook.worksheets[0];
  //         const roles: Role[] = [];

  //         // Extract data from worksheet
  //         worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
  //           if (rowNumber > 1) {
  //             // Skip header row
  //             const id = row.getCell(11).text; // Assuming the twelfth column is id
  //             const name = row.getCell(1).text; // Assuming the first column is name
  //             const description = row.getCell(2).text; // Assuming the first column is name

  //             // Convert salary to number

  //             // Create employee object
  //             const role: Role = {
  //               id,
  //               name,
  //               description,
  //             };

  //             roles.push(role);
  //           }
  //         });

  //         // Call importEmployees with the parsed employee data
  //         importRoles(roles);
  //       };
  //       reader.readAsArrayBuffer(file);
  //     } catch (error) {
  //       console.error("Error reading Excel file:", error);
  //     }
  //   }
  // };

  const columns: ColumnDef<Role>[] = [
 {
    id: "select",
    header: ({ table }) => {
        const isAllSelected = table.getIsAllPageRowsSelected();
        const isSomeSelected = table.getIsSomePageRowsSelected();

        // Check if there are rows to select
        const totalRows = table.getRowModel().rows.length;
        const rowsSelectable = totalRows > 1; // Enable select all only if more than 3 rows exist

        return (
            <Checkbox
                checked={rowsSelectable && (isAllSelected || (isSomeSelected && "indeterminate"))}
                onCheckedChange={(value) => {
                    if (rowsSelectable) {
                        table.toggleAllPageRowsSelected(!!value);
                    }
                }}
                aria-label="Select all"
                disabled={!rowsSelectable} // Disable checkbox if there are 3 or fewer rows
            />
        );
    },
    cell: ({ row }) => {
        const rowIndex = row.index; // Get the current row index

        // Remove checkbox for the first three rows
        if (rowIndex < 1) {
            return <div />; // Render an empty div for the first three rows
        }

        return (
            <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
            />
        );
    },
    enableSorting: false,
    enableHiding: false,
},
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("ID")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("id")}</div>,
    },
    {
      accessorKey: "name",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("name")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("name")}</div>,
    },
    {
      accessorKey: "description",
      header: t("description"),
      cell: ({ row }) => <div>{row.getValue("description")}</div>,
    },
   {
  id: "actions",
  enableHiding: false,
  cell: ({ row }) => {
    const rowIndex = row.index; // Get the current row index

    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-4 w-4 icon" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>{t("action")}</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => handleEdit(row.original.id)}>
            {t("edit")}
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem 
            onClick={() => rowIndex === 0 ? null : handleDelete(row.original.id)} // Disable delete for the first row
            disabled={rowIndex === 0} // Disable the menu item visually
          >
            {t("delete")}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  },
}

  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex flex-wrap items-center py-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="mr-10 flex items-center">
  <Square3Stack3DIcon className="h-5 w-5" />
  <span className="hidden sm:flex sm:items-center">
    {t("action")} <ChevronDown className="ml-2 h-4 w-4" />
  </span>
</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {/* <DropdownMenuItem
              onClick={() => document.getElementById("file-input")?.click()}
            >
              {t("Import")}
            </DropdownMenuItem> */}
            <DropdownMenuItem onClick={exportToExcel}>
              {t("export")}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleDeleteSelected}>
              {t("delete")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <Input
          placeholder={t("filtername")}
          value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
          onChange={(event) =>
            table.getColumn("name")?.setFilterValue(event.target.value)
          }
          style={{
            width: window.innerWidth <= 999 ? "45%" : "100%",
            fontSize: "16px"
          }}
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto flex items-center justify-center">
  <span className="hidden md:block ">{t("columns")}</span>
  <Filter className="md:ml-2 h-4 w-4 mx-auto" />
</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => column.toggleVisibility(!!value)}
                >
                  {column.id}
                </DropdownMenuCheckboxItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onDoubleClick={() => handleEdit(row.original.id)}
                  style={{ cursor: "pointer" }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t("noresult")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {t("previous")}
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {t("next")}
          </Button>
        </div>
      </div>
      <div className="fixed bottom-4 right-4 md:bottom-4 md:right-4">
    <Button
      type="button"
      className="bg-blue-500 text-white px-4 py-2 rounded"
      onClick={handleADD}
    >
      +
    </Button>
  </div>
      <ToastContainer />
      {/* <Input
        id="file-input"
        type="file"
        accept=".xlsx"
        style={{ display: "none" }}
        onChange={handleFileUpload}
      /> */}
    </div>
  );
};

export default RoleList;
