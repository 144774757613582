class VerifyDto {
  
  email: string;
    subdomain: string;

  code: number;

  constructor(verify: {
    email: string;
        subdomain: string;

    code: number;
  }) {
    this.email = verify.email ?? "";
      this.subdomain = verify.subdomain ?? "";

    this.code = verify.code ?? null;
  }
}

export default VerifyDto;