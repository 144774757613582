export class CreateCompositeProductsDto {
  productId?: number | null;
  compositeId?: number | null;
  quantity: number;

  constructor(createcompositeprodcut: {
    productId?: number | null;
    compositeId?: number | null;
    quantity: number;
  }) {
    this.productId = createcompositeprodcut.productId ?? null;
    this.compositeId = createcompositeprodcut.compositeId ?? null;
    this.quantity = createcompositeprodcut.quantity;
  }
}

export default CreateCompositeProductsDto;
