import React, { useEffect, useState } from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@/css/LoginPage.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/provider/authProvider";
import API_URLS from "@/config";
import { jwtDecode } from "jwt-decode";
import "@/css/loading.css";
import { Link } from "react-router-dom";

const formSchema = z.object({
  username: z.string().min(1, { message: "Username is required" }),
  password: z.string().min(1, { message: "Password is required" }),
  businessId: z.number().min(1, { message: "BusinessID is required" }),
});

type FormValues = z.infer<typeof formSchema>;

interface DecodedToken {
  employeeId: string;
}

const LoginPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [businessId, setBusinessId] = useState<number | null>(null);

  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      password: "",
      businessId: 1,
    },
  });

  const { t } = useTranslation();
  const { setToken, setEmployeeId } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Extract the subdomain from the current URL
    const hostname = window.location.hostname.split(".")[0];

    // Fetch the list of businesses
    const fetchBusinesses = async () => {
      try {
        // Construct the API URL and pass the subdomain as a query parameter
        const response = await axios.get(
          `${API_URLS.BASE_URL}${API_URLS.idBySubdomain}`,
          {
            params: {
              subdomain: hostname, // Pass the subdomain (hostname)
            },
          }
        );

        const businessId = response.data; // Assuming the API returns the ID directly

        if (businessId) {
          setBusinessId(businessId); // Set the business ID in the state
          methods.setValue("businessId", businessId); // Pre-fill the form field with the business ID
        } else {
          // Uncomment this if you want to show an error message when no match is found
          // toast.error(t("Business not found"));
        }
      } catch (error) {
        console.error("Error fetching business by subdomain:", error);
        // Handle errors, e.g., by showing a notification
      } finally {
        setLoading(false); // Hide the loading state
      }
    };

    fetchBusinesses(); // Trigger the business fetch logic
  }, [methods, t]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    console.log("Submitting form with data:", data);

    const requestData = {
      ...data,
      userType: "employee",
    };

    try {
      const response = await axios.post(
        `${API_URLS.BASE_URL}${API_URLS.LOGIN}`,
        requestData
      );
      console.log("Response from server:", response);

      if (response.status === 200) {
        const token = response.data.token;
        setToken(token);

        const decodedToken: DecodedToken = jwtDecode<DecodedToken>(token);
        setEmployeeId(decodedToken.employeeId);

        const businessConfigs = response.data.businessConfigs;
        localStorage.setItem(
          "businessConfigs",
          JSON.stringify(businessConfigs)
        );

        // Extract and save certificate with expiryDate in localStorage
        const certificate = response.data.certificate;
        if (certificate) {
          localStorage.setItem("certificate", JSON.stringify(certificate));
        }

        toast.success(t("Login successful"));
        navigate("/", { replace: true });
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { data } = error.response;
        if (Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage = t(`errorLogin.${err.errorMessage}`);
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("Invalid username or password"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
    }
  };

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }
  return (
    <FormProvider {...methods}>
      <div className="login-container">
        <div className="login-form">
          <div className="avatar">👤</div>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormItem>
              <FormLabel className="text-black dark:text-black">
                {t("username")}
              </FormLabel>
              <FormControl>
                <Input
                  {...methods.register("username")}
                  type="text"
                  className="input"
                  placeholder={t("enterUsername")}
                />
              </FormControl>
              {methods.formState.errors.username && (
                <FormMessage>
                  {methods.formState.errors.username.message}
                </FormMessage>
              )}
            </FormItem>
            <div className="forgot-username-link">
              <Link to="/RecoveryUsername">{t("forgotUsername")}</Link>
            </div>
            <FormItem>
              <FormLabel className="text-black dark:text-black">
                {t("password")}
              </FormLabel>
              <FormControl>
                <Input
                  {...methods.register("password")}
                  type="password"
                  className="input"
                  placeholder={t("enterPassword")}
                />
              </FormControl>
              {methods.formState.errors.password && (
                <FormMessage>
                  {methods.formState.errors.password.message}
                </FormMessage>
              )}
            </FormItem>
            <div className="forgot-username-link">
              <Link to="/RecoveryPassword">{t("forgotPasswordLogin")}</Link>
            </div>
            <FormItem>
              <FormLabel className="text-black dark:text-black">
                {t("businessId")}
              </FormLabel>
              <FormControl>
                <Input
                  {...methods.register("businessId", { valueAsNumber: true })}
                  type="number"
                  className="input"
                  placeholder="Business ID"
                  disabled={!!businessId} // Disable input if businessId is set dynamically
                />
              </FormControl>
              {methods.formState.errors.businessId && (
                <FormMessage>
                  {methods.formState.errors.businessId.message}
                </FormMessage>
              )}
            </FormItem>

            <Button type="submit" className="button">
              {t("login")}
            </Button>
          </form>
          <ToastContainer />
        </div>
      </div>
    </FormProvider>
  );
};

export default LoginPage;
