import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import "@/css/loading.css";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ArrowUpDown, ChevronDown, Filter, MoreHorizontal } from "lucide-react";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { Lot } from "@/types/LotType";
import * as ExcelJS from "exceljs";
import CreateLotDto from "@/models/CreateLotDto";
import DataFetcher from "@/provider/DataFetcher";
import { EmployeeType } from "@/types/EmployeeType";

const Lot_STORAGE_KEY = "lotData";
const EMPLOYEE_STORAGE_KEY = "employeeData";

const LotList = () => {
  const location = useLocation();

  const [data, setData] = useState<Lot[]>([]);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "id", desc: true },
  ]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    id: false,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  type RowSelection = Record<string, boolean>;

  const [rowSelection, setRowSelection] = useState<RowSelection>({});
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLotData = async () => {
      try {
        const lotResponse = await DataFetcher.getData<Lot[]>(
          `${API_URLS.BASE_URL}${API_URLS.LOT}`,
          Lot_STORAGE_KEY,
          true
        );
        const lots = lotResponse || [];

        const fetchEmployees = async (): Promise<EmployeeType[]> => {
          try {
            return (
              (await DataFetcher.getData<EmployeeType[]>(
                `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
                EMPLOYEE_STORAGE_KEY
              )) || []
            );
          } catch (error) {
            console.error("Error fetching employees:", error);
            return [];
          }
        };

        // Fetch employees for mapping createdBy and updatedBy
        const employees = await fetchEmployees();
        const employeeMap = employees.reduce<Record<number, string>>(
          (acc, employee) => {
            acc[employee.id] = employee.name;
            return acc;
          },
          {}
        );

        // Map createdBy and updatedBy to employee names
        const updatedLots = lots.map((lot) => ({
          ...lot,
          createdByName: lot.createdBy ? employeeMap[lot.createdBy] || "" : "",
          updatedByName: lot.updatedBy ? employeeMap[lot.updatedBy] || "" : "",
        }));

        setData(updatedLots);
        localStorage.setItem(Lot_STORAGE_KEY, JSON.stringify(updatedLots));
      } catch (error) {
        console.error("Error fetching lot:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLotData();
  }, []);

  const handleEdit = (lotId: string) => {
    navigate(t("/editlotpath"), { state: { id: lotId } });
  };

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`${API_URLS.BASE_URL}${API_URLS.LOT}/${id}`);
      const updatedData = data.filter((lot) => lot.id !== id);
      setData(updatedData);
      localStorage.setItem(Lot_STORAGE_KEY, JSON.stringify(updatedData));
      toast.success(t("Lot deleted successfully"));
    } catch (error) {
      console.error("Error deleting lot:", error);
      toast.error(t("Error deleting lot"));
    }
  };
  const handleDeleteSelected = async () => {
    // Get selected row IDs from rowSelection
    const selectedRowIds = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    // Extract employee IDs to delete based on selected rows
    const lotIdsToDelete = data
      .filter((_item, index) => selectedRowIds.includes(index.toString())) // Adjust this if rowSelection keys are different
      .map((item) => item.id); // Assuming `item.id` is the employee ID

    if (lotIdsToDelete.length === 0) {
      toast.error(t("Please select records to delete."));
      return;
    }

    try {
      // Delete selected employees from the API
      await Promise.all(
        lotIdsToDelete.map((id) =>
          axios.delete(`${API_URLS.BASE_URL}${API_URLS.LOT}/${id}`)
        )
      );

      // Remove deleted employees from the state
      const updatedData = data.filter(
        (item) => !lotIdsToDelete.includes(item.id)
      );
      setData(updatedData);

      // Clear the row selection to prevent automatic selection of nearby rows
      setRowSelection({});

      // Update local storage with the updated data
      localStorage.setItem(Lot_STORAGE_KEY, JSON.stringify(updatedData));

      toast.success(t("Selected records deleted successfully."));
    } catch (error) {
      console.error("Failed to delete records:", error);
      toast.error(t("Failed to delete selected records."));
    }
  };

  const handleADD = () => {
    navigate(t("/createlotpath"));
  };

  useEffect(() => {
    if (!loading && location.state?.showToast) {
      toast.success(location.state.message);
    }
  }, [loading, location.state]);

  const exportToExcel = async () => {
    // Get the selected rows or use the full data if no rows are selected
    const selectedRows = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    const rowsToExport = selectedRows.length > 0 ? selectedRows : data;

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Lot");

    // Define columns based on the data structure
    worksheet.columns = [
      { header: "Lot Number", key: "lotNumber", width: 20 },
      { header: "Expire Date", key: "expiryDateUtc", width: 30 },
    ];

    // Map rowsToExport to match the worksheet columns
    const worksheetData = rowsToExport.map((lot) => ({
      //  id: customer.id,
      lotNumber: lot.lotNumber,
      expiryDateUtc: lot.expiryDateUtc,
    }));

    // Add rows to worksheet
    worksheet.addRows(worksheetData);

    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(
      new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "lot.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const workbook = new ExcelJS.Workbook();
          await workbook.xlsx.load(data);

          // Get the first worksheet
          const worksheet = workbook.worksheets[0];
          const lots: Lot[] = [];

          // Extract data from worksheet
          worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
            if (rowNumber > 1) {
              // Skip header row

              const lotNumber = row.getCell(1).text; // Name
              const id = row.getCell(11).text; // Phone Number
              const expiryDateUtc = row.getCell(2).text; // Date of Birth (string)

              // Convert idType to a number, use 0 or another default value if empty

              // Create customer object
              const lot: Lot = {
                lotNumber,
                expiryDateUtc,
                id,
                createdBy: 0,
                updatedBy: 0,
                createdByName: "",
                updatedByName: ""
              };

              lots.push(lot);
            }
          });

          // Call importCustomers with the parsed customer data
          importLots(lots);
        };
        reader.readAsArrayBuffer(file);
      } catch (error) {
        console.error("Error reading Excel file:", error);
      }
    }
  };
  const importLots = async (lots: Lot[]) => {
    try {
      // Format each lot using CreateLotDto
      const formattedLots = lots.map((lot) => {
        // Ensure phone numbers and other fields are treated as strings
        lot.lotNumber = lot.lotNumber ? String(lot.lotNumber) : "";
        lot.expiryDateUtc = lot.expiryDateUtc ? String(lot.expiryDateUtc) : "";

        // Create the DTO for the lot
        return new CreateLotDto({
          ...lot,
          expiryDateUtc: lot.expiryDateUtc
            ? new Date(lot.expiryDateUtc).toISOString()
            : new Date().toISOString(),
          lotNumber: lot.lotNumber ? String(lot.lotNumber) : "",
        });
      });

      // Initialize arrays to track successful and failed responses
      const successfulResponses = [];
      const failedResponses = [];

      // Upload lots to the API and retrieve the responses
      for (const lot of formattedLots) {
        try {
          const response = await axios.post(
            `${API_URLS.BASE_URL}${API_URLS.LOT}`,
            lot
          );
          successfulResponses.push({
            id: response.data.id,
            lotNumber: response.data.lotNumber,
            status: "Success",
          });
        } catch (error) {
          let formattedErrors = [];

          if (axios.isAxiosError(error)) {
            if (error.response) {
              const statusCode = error.response.status;

              // Handle 400 errors (validation errors)
              if (statusCode === 400 && Array.isArray(error.response.data)) {
                formattedErrors = error.response.data.map((err: any) => ({
                  propertyName: err.propertyName,
                  errorMessage: err.errorMessage,
                  attemptedValue: err.attemptedValue,
                }));
              }
              // Handle 500 errors (Internal Server Error) and others
              else if (statusCode === 500 || statusCode >= 500) {
                formattedErrors.push({
                  errorMessage: t(
                    "Internal Server Error. Please try again later."
                  ),
                });
              } else {
                // Handle other potential errors
                formattedErrors.push({
                  errorMessage: `An error occurred: ${statusCode}. Please try again.`,
                });
              }
            } else {
              // Handle cases where there is no response, possibly due to network issues
              formattedErrors.push({
                errorMessage:
                  "Network error. Please check your connection and try again.",
              });
            }
          } else {
            // Handle any unexpected errors
            formattedErrors.push({
              errorMessage: "An unknown error occurred. Please try again.",
            });
          }

          failedResponses.push({
            lotNumber: lot.lotNumber,
            status: "Failed",
            errors: formattedErrors,
          });

          console.error(`Error importing lot '${lot.lotNumber}':`, error);
        }
      }

      // Log successful responses to ensure the API calls are successful

      // Retrieve existing lots from local storage
      const existingLots = JSON.parse(localStorage.getItem("lotData") || "[]");

      // Add the newly created lots from the API responses to the existing list
      const updatedLots = [...existingLots, ...successfulResponses];

      // Save the updated lot list back to local storage
      localStorage.setItem("lotData", JSON.stringify(updatedLots));

      const lastResult = [...successfulResponses, ...failedResponses];
      localStorage.setItem("importLot", JSON.stringify(lastResult));

      navigate(t("/importlot"));
    } catch (error) {
      // Handle errors that occur outside of the main loop, e.g., network or critical errors
      if (axios.isAxiosError(error) && error.response) {
        const { data } = error.response;
        if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorLot.${err.errorMessage}`) ||
              t("errorLot.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          const defaultMessage = t("An error occurred");
          toast.error(defaultMessage);
        }
      } else {
        const unexpectedMessage = t("An unexpected error occurred");
        toast.error(unexpectedMessage);
      }
      console.error("Error importing lots:", error);
    }
  };

  const columns: ColumnDef<Lot>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("ID")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("id")}</div>,
    },
    {
      accessorKey: "lotNumber",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("lotNumber")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("lotNumber")}</div>,
    },
    {
      accessorKey: "expiryDateUtc",
      header: t("expdate"),
      cell: ({ row }) => <div>{row.getValue("expiryDateUtc")}</div>,
    },
    {
      accessorKey: "createdByName",
      header: t("createdBy"),
      cell: ({ row }) => <div>{row.getValue("createdByName")}</div>,
    },
    {
      accessorKey: "updatedByName",
      header: t("updatedBy"),
      cell: ({ row }) => <div>{row.getValue("updatedByName")}</div>,
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4 icon" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{t("action")}</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => handleEdit(row.original.id)}>
              {t("edit")}
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => handleDelete(row.original.id)}>
              {t("delete")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex flex-wrap items-center py-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
              <Button variant="outline" className="mr-10 flex items-center">
  <Square3Stack3DIcon className="h-5 w-5" />
  <span className="hidden sm:flex sm:items-center">
    {t("action")} <ChevronDown className="ml-2 h-4 w-4" />
  </span>
</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => document.getElementById("file-input")?.click()}
            >
              {t("Import")}
            </DropdownMenuItem>{" "}
            <DropdownMenuItem onClick={exportToExcel}>
              {t("export")}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleDeleteSelected}>
              {t("delete")}
            </DropdownMenuItem>{" "}
          </DropdownMenuContent>
        </DropdownMenu>
        <Input
          placeholder={t("filtername")}
          value={
            (table.getColumn("lotNumber")?.getFilterValue() as string) ?? ""
          }
          onChange={(event) =>
            table.getColumn("lotNumber")?.setFilterValue(event.target.value)
          }
          style={{
            width: window.innerWidth <= 999 ? "45%" : "100%",
          }}
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
             <Button variant="outline" className="ml-auto flex items-center justify-center">
  <span className="hidden md:block ">{t("columns")}</span>
  <Filter className="md:ml-2 h-4 w-4 mx-auto" />
</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => column.toggleVisibility(!!value)}
                >
                  {column.id}
                </DropdownMenuCheckboxItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onDoubleClick={() => handleEdit(row.original.id)}
                  style={{ cursor: "pointer" }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t("noresult")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {t("previous")}
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {t("next")}
          </Button>
        </div>
      </div>
      <div className="fixed bottom-4 right-4 md:bottom-4 md:right-4">
        <Button
          type="button"
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleADD}
        >
          +
        </Button>
      </div>
      <ToastContainer />
      <Input
        id="file-input"
        type="file"
        accept=".xlsx"
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
    </div>
  );
};

export default LotList;
