class CreateAccountTransferDto {
  sourceCashAccountId: number;
  destinationCashAccountId: number;
  amount: number;
  note?: string | null;

  constructor(transferData: {
    sourceCashAccountId: number;
    destinationCashAccountId: number;
    amount: number;
    note?: string | null;
  }) {
    this.sourceCashAccountId = transferData.sourceCashAccountId;
    this.destinationCashAccountId = transferData.destinationCashAccountId;
    this.amount = transferData.amount;
    this.note = transferData.note ?? null;
  }
}

export default CreateAccountTransferDto;
