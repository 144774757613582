import React from "react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@/css/LoginPage.css";
import API_URLS from "@/config";
import { Link } from "react-router-dom";

// Validation schema
const emailSchema = z.object({
  username: z.string().min(1, { message: "Username is required" }),
});

type EmailFormValues = z.infer<typeof emailSchema>;

const ForgetPasswordPage: React.FC = () => {
  const methods = useForm<EmailFormValues>({
    resolver: zodResolver(emailSchema),
  });
  const { t } = useTranslation();
  const onSubmit: SubmitHandler<EmailFormValues> = async (data) => {
    try {
      const subdomain = window.location.hostname.split(".")[0];
      const username = data.username;

      await axios.post(
        `${API_URLS.BASE_URL}${API_URLS.forgetPassword}?username=${username}&subdomain=${subdomain}`
      );
      toast.success(t("Username retrieval email sent"));
    } catch (error) {
      toast.error(t("Unable to send email, please try again"));
      console.log(error);
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="login-container">
        <div className="login-form">
          <div className="avatar">👤</div>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormItem>
              <FormLabel className="text-black dark:text-black">
                {t("username")}
              </FormLabel>
              <FormControl>
                <Input
                  {...methods.register("username")}
                  type="text"
                  className="input"
                  placeholder={t("enterUsername")}
                />
              </FormControl>
              {methods.formState.errors.username && (
                <FormMessage>
                  {methods.formState.errors.username.message}
                </FormMessage>
              )}
            </FormItem>
            <Button type="submit" className="button">
              {t("sendEmail")}
            </Button>
            <div className="forgot-username-link">
              <Link to="/login">{t("backtologin")}</Link>
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>
    </FormProvider>
  );
};

export default ForgetPasswordPage;
