import BusinessRegisterForm from "./BusinessRegisterForm";

const App = () => {
    return (
       <div>
    {/* Your content goes here */}
    <BusinessRegisterForm mode="create" />
</div>

    );
};

export default App;
