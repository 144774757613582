class CreateUomDto {
  name: string;
  description: string;
  code: string;

  constructor(uom: { name?: string; description?: string; code?: string }) {
    this.name = uom.name || "";
    this.description = uom.description || "";
    this.code = uom.code || "";
  }
}

export default CreateUomDto;
