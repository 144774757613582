import React from "react";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const ProfitChart: React.FC = () => {
  const { t } = useTranslation();

  const data = [
    { name: "Jan", profit: 2400 },
    { name: "Feb", profit: 1398 },
    { name: "Mar", profit: 9800 },
    { name: "Apr", profit: 3908 },
    { name: "May", profit: 4800 },
    { name: "Jun", profit: 3800 },
    { name: "Jul", profit: 4300 },
    { name: "Aug", profit: 2400 },
    { name: "Sep", profit: 1398 },
    { name: "Oct", profit: 9800 },
    { name: "Nov", profit: 3908 },
    { name: "Dec", profit: 4800 },
  ];

  return (
    <div style={{ width: "100%", height: 300 }}>
      <h2>{t("Monthly Profit")}</h2>
      <ResponsiveContainer>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="profit" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProfitChart;
