import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
  useWatch,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import CreateProductDto from "@/models/CreateProductDto";
import { useNavigate, useLocation } from "react-router-dom";
import "@/css/MobileScreen.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { Label } from "@/components/ui/label";
import SupplierForm from "../supplier/SupplierForm";
import ProductCategoryForm from "../productCategory/ProductCategoryForm";
import UomForm from "../UOM/UomForm";
import { Product } from "@/types/ProductType";
import VatForm from "../VAT/VatForm";
import { Switch } from "@/components/ui/switch";
import { ProductCategory } from "@/interfaces/ProductCategoryInterfaces";
import { Supplier } from "@/interfaces/SupplierInterfaces";
import { UoM } from "@/interfaces/UomInterfaces";
import { VaT } from "@/interfaces/VatInterfaces";
import { ProductFormProps } from "@/interfaces/ProductInterfaces";
import DataFetcher from "@/provider/DataFetcher";
import "@/css/LoadingCreate.css";

const SUPPLIER_STORAGE_KEY = "supplierData";
const UOM_STORAGE_KEY = "uomData";
const VAT_STORAGE_KEY = "vatData";
const PRODUCTCAT_STORAGE_KEY = "categoryData";
const PRODUCT_STORAGE_KEY = "productDataForm";

const ProductForm: React.FC<ProductFormProps> = ({ mode }) => {
  const { t } = useTranslation();

  const formSchema = z.object({
    type: z.enum(["goods", "service"]),
    name: z
      .string()
      .min(1, { message: t("Name is required") })
      .max(100, { message: t("Maximum length of characters is 100") }),
    categoryId: z.number().optional().nullable(),
    sku: z.string().optional(),
    uoMId: z.number().optional().nullable(),
    barcode: z.string().optional(),
    image: z.string().optional(),
    price: z.number().optional(),
    description: z.string().optional(),
    combo: z.boolean(),
    brand: z.string().optional(),
    isComposite: z.boolean(),
    cost: z.number().optional(),
    weight: z.number().optional(),
    volume: z.number().optional(),
    stockLevel: z.number().optional(),
    vatRateId: z
      .number({ required_error: t("VAT is required") })
      .min(1, { message: t("VAT is required") }),
    supplierId: z.number().optional().nullable(),
    supplierCode: z.string().optional().default(""),
    trackInventory: z.boolean(),
    isService: z.boolean(),
    isRawMaterial: z.boolean(),
  });

  type FormValues = z.infer<typeof formSchema>;
  const [loading, setLoading] = useState(false); // Initialize loading state

  const location = useLocation();
  const { id } = location.state || { id: localStorage.getItem("productId") };
  const navigate = useNavigate();
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [uoms, setUoMs] = useState<UoM[]>([]);
  const [vats, setVaTs] = useState<VaT[]>([]);
  const [isSupplierDialogOpen, setIsSupplierDialogOpen] = useState(false);
  const [isProductCategoryDialogOpen, setIsProductCategoryDialogOpen] =
    useState(false);
  const [isUoMDialogOpen, setisUoMDialogOpen] = useState(false);
  const [isVaTDialogOpen, setisVaTDialogOpen] = useState(false);
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      type: "goods",
      name: "",
      categoryId: undefined,
      sku: "",
      uoMId: undefined,
      barcode: "",
      image: "",
      price: 0,
      description: "",
      combo: false,
      brand: "",
      isComposite: false,
      cost: 0,
      weight: 0,
      volume: 0,
      stockLevel: 0,
      vatRateId: undefined,
      supplierId: undefined,
      supplierCode: "",
      trackInventory: false,
      isService: false,
      isRawMaterial: false,
    },
  });

  const { setValue, getValues } = methods;
  const setDefaultValues = () => {
    if (uoms.length > 0 && !getValues("uoMId")) {
      setValue("uoMId", uoms[0].id);
    }
    if (vats.length > 0 && !getValues("vatRateId")) {
      setValue("vatRateId", vats[0].id);
    }
    if (categories.length > 0 && !getValues("categoryId")) {
      setValue("categoryId", null);
    }
    if (suppliers.length > 0 && !getValues("supplierId")) {
      setValue("supplierId", null);
    }
  };

  // Effect to set default values when dependencies change
  useEffect(() => {
    setDefaultValues();
  }, [uoms, vats, categories, suppliers, setValue, getValues]);

  const handleCloseDialogUom = (newUom?: { id: number; name: string }) => {
    setisUoMDialogOpen(false);
    if (newUom) {
      setUoMs((prevUoms) => {
        const updatedUoms = [...prevUoms, newUom];
        methods.setValue("uoMId", newUom.id);
        return updatedUoms;
      });
    }
  };

  const handleCloseDialogVat = (newVat?: { id: number; name: string }) => {
    setisVaTDialogOpen(false);
    if (newVat) {
      setVaTs((prevVats) => {
        const updatedVats = [...prevVats, newVat];
        methods.setValue("vatRateId", newVat.id);
        return updatedVats;
      });
    }
  };

  const handleCloseDialogCat = (newCategory?: { id: number; name: string }) => {
    setIsProductCategoryDialogOpen(false);
    if (newCategory) {
      setCategories((prevCategories) => {
        const updatedCategories = [...prevCategories, newCategory];
        methods.setValue("categoryId", newCategory.id);
        return updatedCategories;
      });
    }
  };

  const handleCloseDialogSupplier = (newSupplier?: {
    id: number;
    name: string;
  }) => {
    setIsSupplierDialogOpen(false);
    if (newSupplier) {
      setSuppliers((prevSuppliers) => {
        const updatedSuppliers = [...prevSuppliers, newSupplier];
        methods.setValue("supplierId", newSupplier.id);
        return updatedSuppliers;
      });
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await DataFetcher.getData<ProductCategory[]>(
        `${API_URLS.BASE_URL}${API_URLS.PRODUCT_CATEGORY}`,
        PRODUCTCAT_STORAGE_KEY
      );
      if (response) {
        setCategories(response);
      }
    };

    const fetchSuppliers = async () => {
      try {
        console.log("Fetching suppliers...");
        const response = await DataFetcher.getData<Supplier[]>(
          `${API_URLS.BASE_URL}${API_URLS.SUPPLIER}`,
          SUPPLIER_STORAGE_KEY
        );
        if (response) {
          console.log("Suppliers fetched:", response);
          setSuppliers(response);
          console.log(
            "Cached suppliers data:",
            localStorage.getItem(SUPPLIER_STORAGE_KEY)
          );
        } else {
          console.log("No response received");
        }
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };

    const fetchUoM = async () => {
      try {
        console.log("Fetching uoms...");
        const response = await DataFetcher.getData<UoM[]>(
          `${API_URLS.BASE_URL}${API_URLS.UOM}`,
          UOM_STORAGE_KEY
        );
        if (response) {
          console.log("Uoms fetched:", response);
          setUoMs(response);
          console.log(
            "Cached suppliers data:",
            localStorage.getItem(UOM_STORAGE_KEY)
          );
        } else {
          console.log("No response received");
        }
      } catch (error) {
        console.error("Error fetching uoms:", error);
      }
    };

    const fetchVaT = async () => {
      const response = await DataFetcher.getData<VaT[]>(
        `${API_URLS.BASE_URL}${API_URLS.VAT}`,
        VAT_STORAGE_KEY
      );
      if (response) {
        setVaTs(response);
      }
    };

    fetchCategories();
    fetchSuppliers();
    fetchUoM();
    fetchVaT();

    if (id) {
      localStorage.setItem("productId", id);
    }

    if (mode === "edit" && id) {
      const fetchProduct = async () => {
        try {
          const response = await DataFetcher.getData<Product | null>(
            `${API_URLS.BASE_URL}${API_URLS.PRODUCT}/${id}`,
            PRODUCT_STORAGE_KEY
          );

          if (response) {
            const productData = response;

            methods.reset({
              type: productData.isService ? "service" : "goods",
              name: productData.name || "",
              categoryId: productData.categoryId || undefined,
              sku: productData.sku || "",
              uoMId: productData.uoMId || undefined,
              barcode: productData.barcode || "",
              image: productData.image || "",
              price: productData.price || 0,
              description: productData.description || "",
              combo: productData.isCombo || false,
              brand: productData.brand || "",
              isComposite: productData.isComposite || false,
              cost: productData.cost || 0,
              weight: productData.weight || 0,
              volume: productData.volume || 0,
              stockLevel: productData.stockLevel || 0,
              vatRateId: productData.vatRateId || undefined,
              supplierId: productData.supplierId || undefined,
              supplierCode: productData.supplierCode || "",
              trackInventory: productData.trackInventory || false,
              isService: productData.isService || false,
              isRawMaterial: productData.isRawMaterial || false,
            });
          } else {
            console.error("Product data is null.");
            toast.error("Failed to fetch product details");
          }
        } catch (error) {
          console.error("Error fetching product details:", error);
          toast.error("Failed to fetch product details");
        }
      };

      fetchProduct();
    }
  }, [id, mode, methods]);

  // useEffect(() => {
  //   const setDefaultValues = () => {
  //     if (uoms.length > 0 && !methods.getValues("uoMId")) {
  //       methods.setValue("uoMId", uoms[0].id);
  //     }
  //     if (vats.length > 0 && !methods.getValues("vatRateId")) {
  //       methods.setValue("vatRateId", vats[0].id);
  //     }
  //   };

  //   setDefaultValues();
  // }, [uoms, vats, methods]);

  const updateLocalStorage = (updatedProduct: Product) => {
    // Ensure that existingProducts is always an array
    const existingProducts: Product[] = JSON.parse(
      localStorage.getItem("productDataForm") || "[]"
    );

    // Map over the array and replace the existing product if necessary
    const updatedProducts = Array.isArray(existingProducts)
      ? existingProducts.map((product) =>
          product.id === updatedProduct.id ? updatedProduct : product
        )
      : [updatedProduct];

    // Save the updated array back to localStorage
    localStorage.setItem("productData", JSON.stringify(updatedProducts));
  };

  const type = useWatch({
    control: methods.control,
    name: "type",
  });

  useEffect(() => {
    if (type === "goods") {
      methods.setValue("trackInventory", true);
      methods.setValue("isService", false);
    } else if (type === "service") {
      methods.setValue("trackInventory", false);
      methods.setValue("isService", true);
    }
  }, [type, methods]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    data.isComposite = data.isComposite ?? false;

    const dto = new CreateProductDto({
      name: data.name,
      description: data.description,
      price: data.price,
      cost: data.cost,
      vatRateId: data.vatRateId,
      uoMId: data.uoMId,
      sku: data.sku || null,
      barcode: data.barcode || null,
      categoryId: data.categoryId,
      brand: data.brand,
      image: data.image || null,
      supplierId: data.supplierId,
      supplierCode: data.supplierCode,
      stockLevel: data.stockLevel,
      weight: data.weight,
      volume: data.volume,
      isComposite: data.isComposite,
      isCombo: data.combo,
      isRawMaterial: data.isRawMaterial,
      isService: data.type === "service",
      trackInventory: data.trackInventory,
    });

    try {
      console.log("Submitting data:", dto);
      let successMessage = "";

      if (mode === "create") {
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`,
          dto
        );
        console.log("Product created successfully:", response.data);
        successMessage = t("Product created successfully");

        const existingProducts = JSON.parse(
          localStorage.getItem("productDataForm") || "[]"
        );
        existingProducts.push(response.data);
        localStorage.setItem(
          "productDataForm",
          JSON.stringify(existingProducts)
        );
      } else if (mode === "edit" && id) {
        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.PRODUCT}/${id}`,
          dto
        );
        console.log("Product updated successfully:", response.data);
        successMessage = t("Product updated successfully");
        updateLocalStorage(response.data);
      }

      methods.reset();
      localStorage.removeItem("productId");
      navigate(t("/prodlistpath"));

      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorProduct.${err.errorMessage}`) ||
              t("errorProduct.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting product:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center ">
        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-7xl p-6 rounded shadow-md">
          <h1 className="text-xl font-bold mb-6">
            {mode === "create" ? t("createitem") : t("edititem")}
          </h1>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-3 gap-6">
              <div>
                <fieldset className="border p-4 rounded mb-4">
                  <legend className="text-lg font-semibold">
                    {t("infoitem")}
                  </legend>
                  <FormItem>
                    <FormLabel>{t("type")}</FormLabel>
                    <FormControl>
                      <Controller
                        name="type"
                        control={methods.control}
                        render={({ field }) => (
                          <RadioGroup
                            {...field}
                            onValueChange={field.onChange}
                            value={field.value}
                            className="flex gap-4"
                          >
                            <RadioGroupItem value="goods" id="r1" />
                            <Label htmlFor="r1">{t("goods")}</Label>
                            <RadioGroupItem value="service" id="r2" />
                            <Label htmlFor="r2">{t("service")}</Label>
                          </RadioGroup>
                        )}
                      />
                    </FormControl>
                    <div
                      className="hidden"
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <FormLabel style={{ marginRight: "10px" }}>
                        {t("isRawMaterial")}
                      </FormLabel>
                      <FormControl>
                        <Controller
                          name="isRawMaterial"
                          control={methods.control}
                          render={({ field }) => (
                            <Checkbox
                              checked={field.value}
                              onCheckedChange={(checked) =>
                                field.onChange(checked)
                              }
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  </FormItem>
                  <FormItem>
                    <FormLabel>
                      {t("name")}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...methods.register("name")}
                        type="search"
                        placeholder={t("putname")}
                        className="w-full"
                      />
                    </FormControl>
                    {methods.formState.errors.name && (
                      <FormMessage>
                        {methods.formState.errors.name.message}
                      </FormMessage>
                    )}
                  </FormItem>
                  <div className="flex items-center gap-2">
                    <FormItem className="flex-1">
                      <FormLabel>{t("category")}</FormLabel>
                      <FormControl>
                        <Controller
                          name="categoryId"
                          control={methods.control}
                          render={({ field }) => (
                            <Select
                              onValueChange={(value) =>
                                field.onChange(parseInt(value))
                              }
                              value={
                                field.value ? field.value.toString() : undefined
                              }
                            >
                              <SelectTrigger>
                                <SelectValue placeholder={t("putcategory")} />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  <SelectLabel>{t("category")}</SelectLabel>
                                  {categories.map((category) => (
                                    <SelectItem
                                      key={category.id}
                                      value={category.id.toString()}
                                    >
                                      {category.name}
                                    </SelectItem>
                                  ))}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          )}
                        />
                      </FormControl>
                      {methods.formState.errors.categoryId && (
                        <FormMessage>
                          {methods.formState.errors.categoryId.message}
                        </FormMessage>
                      )}
                    </FormItem>
                    <Dialog
                      open={isProductCategoryDialogOpen}
                      onOpenChange={setIsProductCategoryDialogOpen}
                    >
                      <DialogTrigger asChild>
                        <Button
                          type="button"
                          className="bg-blue-500 text-white  rounded"
                          style={{
                            marginTop: "31px",
                            height: "35px",
                            width: "35px",
                          }} // Adds space above the button
                        >
                          +
                        </Button>
                      </DialogTrigger>
                      <DialogContent
                        style={
                          window.innerWidth <= 768
                            ? {
                                maxWidth: "300px", // Style for screens 768px or less
                                maxHeight: "80vh",
                                overflowY: "auto",
                              }
                            : window.innerWidth <= 999
                            ? {
                                maxWidth: "600px", // Style for screens between 769px and 999px
                                maxHeight: "80vh",
                                overflowY: "auto",
                              }
                            : {}
                        }
                      >
                        {" "}
                        <ProductCategoryForm
                          mode="create"
                          inDialog={true}
                          onClose={handleCloseDialogCat}
                        />
                      </DialogContent>
                    </Dialog>
                  </div>
                  <div className="flex items-center gap-2">
                    <FormItem className="flex-1">
                      <FormLabel>{t("uom")}</FormLabel>
                      <FormControl>
                        <Controller
                          name="uoMId"
                          control={methods.control}
                          render={({ field }) => (
                            <Select
                              onValueChange={(value) =>
                                field.onChange(parseInt(value))
                              }
                              value={
                                field.value ? field.value.toString() : undefined
                              }
                            >
                              <SelectTrigger>
                                <SelectValue placeholder={t("putuom")} />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  <SelectLabel>{t("uom")}</SelectLabel>
                                  {uoms.map((uom) => (
                                    <SelectItem
                                      key={uom.id}
                                      value={uom.id.toString()}
                                    >
                                      {uom.name}
                                    </SelectItem>
                                  ))}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          )}
                        />
                      </FormControl>
                      {methods.formState.errors.uoMId && (
                        <FormMessage>
                          {methods.formState.errors.uoMId.message}
                        </FormMessage>
                      )}
                    </FormItem>
                    <Dialog
                      open={isUoMDialogOpen}
                      onOpenChange={setisUoMDialogOpen}
                    >
                      <DialogTrigger asChild>
                        <Button
                          type="button"
                          className="bg-blue-500 text-white  rounded"
                          style={{
                            marginTop: "31px",
                            height: "35px",
                            width: "35px",
                          }} // Adds space above the button
                        >
                          +
                        </Button>
                      </DialogTrigger>
                      <DialogContent
                        style={
                          window.innerWidth <= 768
                            ? {
                                maxWidth: "300px", // Style for screens 768px or less
                                maxHeight: "80vh",
                                overflowY: "auto",
                              }
                            : window.innerWidth <= 999
                            ? {
                                maxWidth: "600px", // Style for screens between 769px and 999px
                                maxHeight: "80vh",
                                overflowY: "auto",
                              }
                            : {}
                        }
                      >
                        {" "}
                        <UomForm
                          mode="create"
                          inDialog={true}
                          onClose={handleCloseDialogUom}
                        />
                      </DialogContent>
                    </Dialog>
                  </div>
                  <div className="flex items-center gap-2">
                    <FormItem className="flex-1">
                      <FormLabel>{t("vat")}</FormLabel>
                      <FormControl>
                        <Controller
                          name="vatRateId"
                          control={methods.control}
                          render={({ field }) => (
                            <Select
                              onValueChange={(value) =>
                                field.onChange(parseInt(value))
                              }
                              value={
                                field.value ? field.value.toString() : undefined
                              }
                            >
                              <SelectTrigger>
                                <SelectValue placeholder={t("putvatnumber")} />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  <SelectLabel>{t("vat")}</SelectLabel>
                                  {vats.map((vat) => (
                                    <SelectItem
                                      key={vat.id}
                                      value={vat.id.toString()}
                                    >
                                      {vat.name}
                                    </SelectItem>
                                  ))}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          )}
                        />
                      </FormControl>
                      {methods.formState.errors.vatRateId && (
                        <FormMessage>
                          {methods.formState.errors.vatRateId.message}
                        </FormMessage>
                      )}
                    </FormItem>
                    <Dialog
                      open={isVaTDialogOpen}
                      onOpenChange={setisVaTDialogOpen}
                    >
                      <DialogTrigger asChild>
                        <Button
                          type="button"
                          className="bg-blue-500 text-white  rounded"
                          style={{
                            marginTop: "31px",
                            height: "35px",
                            width: "35px",
                          }} // Adds space above the button
                        >
                          +
                        </Button>
                      </DialogTrigger>
                      <DialogContent
                        style={
                          window.innerWidth <= 768
                            ? {
                                maxWidth: "300px", // Style for screens 768px or less
                                maxHeight: "80vh",
                                overflowY: "auto",
                              }
                            : window.innerWidth <= 999
                            ? {
                                maxWidth: "600px", // Style for screens between 769px and 999px
                                maxHeight: "80vh",
                                overflowY: "auto",
                              }
                            : {}
                        }
                      >
                        {" "}
                        <VatForm
                          mode="create"
                          inDialog={true}
                          onClose={handleCloseDialogVat}
                        />
                      </DialogContent>
                    </Dialog>
                  </div>
                  <FormItem>
                    <FormLabel>SKU</FormLabel>
                    <FormControl>
                      <Input
                        {...methods.register("sku")}
                        type="search"
                        placeholder={t("putsku")}
                        className="w-full"
                      />
                    </FormControl>
                    {methods.formState.errors.sku && (
                      <FormMessage>
                        {methods.formState.errors.sku.message}
                      </FormMessage>
                    )}
                  </FormItem>
                  <FormItem>
                    <FormLabel>{t("barcode")}</FormLabel>
                    <FormControl>
                      <Input
                        {...methods.register("barcode")}
                        type="search"
                        placeholder={t("putbarcode")}
                        className="w-full"
                      />
                    </FormControl>
                    {methods.formState.errors.barcode && (
                      <FormMessage>
                        {methods.formState.errors.barcode.message}
                      </FormMessage>
                    )}
                  </FormItem>
                  <FormItem className="hidden">
                    <FormLabel>{t("image")}</FormLabel>
                    <FormControl>
                      <Input
                        {...methods.register("image")}
                        type="file"
                        id="picture"
                        placeholder="Upload image"
                        className="w-full"
                      />
                    </FormControl>
                  </FormItem>
                  <FormItem>
                    <FormLabel>{t("track")}</FormLabel>
                    <FormControl>
                      <Checkbox
                        {...methods.register("trackInventory")}
                        checked={type === "goods"}
                        disabled={type !== "goods"}
                      />
                    </FormControl>
                  </FormItem>
                </fieldset>
              </div>
              <div>
                <fieldset className="border p-4 rounded">
                  <legend className="text-lg font-semibold">
                    {t("salesinfo")}
                  </legend>
                  <FormItem>
                    <FormLabel> {t("price")}</FormLabel>
                    <FormControl>
                      <Input
                        {...methods.register("price", {
                          valueAsNumber: true,
                        })}
                        type="number"
                        placeholder={t("putprice")}
                          pattern="\d*"

                        className="w-full"
                      />
                    </FormControl>
                    {methods.formState.errors.price && (
                      <FormMessage>
                        {methods.formState.errors.price.message}
                      </FormMessage>
                    )}
                  </FormItem>
                  <FormItem>
                    <FormLabel>{t("description")}</FormLabel>
                    <FormControl>
                      <Input
                        {...methods.register("description")}
                        type="search"
                        placeholder={t("putdescription")}
                        className="w-full"
                      />
                    </FormControl>
                  </FormItem>
                  <FormItem className="hidden">
                    <FormLabel>{t("combo")}</FormLabel>
                    <FormControl>
                      <Checkbox {...methods.register("combo")} />
                    </FormControl>
                  </FormItem>
                  <FormItem>
                    <FormLabel>{t("brand")}</FormLabel>
                    <FormControl>
                      <Input
                        {...methods.register("brand")}
                        type="search"
                        placeholder={t("putbrand")}
                        className="w-full"
                      />
                    </FormControl>
                  </FormItem>
                  <FormItem className="hidden">
                    <FormLabel>{t("composite")}</FormLabel>
                    <FormControl>
                      <Controller
                        name="isComposite"
                        control={methods.control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value}
                            onCheckedChange={(value) => field.onChange(value)}
                          />
                        )}
                      />
                    </FormControl>
                  </FormItem>
                </fieldset>
              </div>
              <div>
                <fieldset className="border p-4 rounded">
                  <legend className="text-lg font-semibold">
                    {t("purchaseinfo")}
                  </legend>
                  <FormItem>
                    <FormLabel> {t("cost")}</FormLabel>
                    <FormControl>
                      <Input
                        {...methods.register("cost", {
                          valueAsNumber: true,
                        })}
                        type="number"
                        placeholder={t("putcost")}
                        className="w-full"
                                                  pattern="\d*"

                      />
                    </FormControl>
                    {methods.formState.errors.cost && (
                      <FormMessage>
                        {methods.formState.errors.cost.message}
                      </FormMessage>
                    )}
                  </FormItem>
                  <div className="flex items-center gap-2">
                    <FormItem className="flex-1">
                      <FormLabel>{t("suppname")}</FormLabel>
                      <FormControl>
                        <Controller
                          name="supplierId"
                          control={methods.control}
                          render={({ field }) => (
                            <Select
                              onValueChange={(value) =>
                                field.onChange(parseInt(value))
                              }
                              value={
                                field.value ? field.value.toString() : undefined
                              }
                            >
                              <SelectTrigger>
                                <SelectValue placeholder={t("putsuppname")} />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  <SelectLabel>{t("suppliers")}</SelectLabel>
                                  {suppliers.map((supplier) => (
                                    <SelectItem
                                      key={supplier.id}
                                      value={supplier.id.toString()}
                                    >
                                      {supplier.name}
                                    </SelectItem>
                                  ))}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          )}
                        />
                      </FormControl>
                      {methods.formState.errors.supplierId && (
                        <FormMessage>
                          {methods.formState.errors.supplierId.message}
                        </FormMessage>
                      )}
                    </FormItem>
                    <Dialog
                      open={isSupplierDialogOpen}
                      onOpenChange={setIsSupplierDialogOpen}
                    >
                      <DialogTrigger asChild>
                        <Button
                          type="button"
                          className="bg-blue-500 text-white  rounded"
                          style={{
                            marginTop: "31px",
                            height: "35px",
                            width: "35px",
                          }} // Adds space above the button
                        >
                          +
                        </Button>
                      </DialogTrigger>
                      <DialogContent
                        style={
                          window.innerWidth <= 768
                            ? {
                                maxWidth: "300px", // Style for screens 768px or less
                                maxHeight: "80vh",
                                overflowY: "auto",
                              }
                            : window.innerWidth <= 999
                            ? {
                                maxWidth: "600px", // Style for screens between 769px and 999px
                                maxHeight: "80vh",
                                overflowY: "auto",
                              }
                            : {}
                        }
                      >
                        {" "}
                        <SupplierForm
                          mode="create"
                          inDialog={true}
                          onClose={handleCloseDialogSupplier}
                        />
                      </DialogContent>
                    </Dialog>
                  </div>
                  <FormItem>
                    <FormLabel>{t("codesuppname")}</FormLabel>
                    <FormControl>
                      <Input
                        {...methods.register("supplierCode")}
                        type="search"
                        placeholder={t("putcodesuppname")}
                        className="w-full"
                      />
                    </FormControl>
                  </FormItem>
                  <FormItem>
                    <FormLabel>{t("weight")}</FormLabel>
                    <FormControl>
                      <Input
                        {...methods.register("weight", {
                          valueAsNumber: true,
                        })}
                        type="number"
                        placeholder={t("putweight")}
                        className="w-full"
                                                  pattern="\d*"

                      />
                    </FormControl>
                  </FormItem>
                  <FormItem>
                    <FormLabel>{t("volume")}</FormLabel>
                    <FormControl>
                      <Input
                        {...methods.register("volume", {
                          valueAsNumber: true,
                        })}
                        type="number"
                        placeholder={t("putvolume")}
                        className="w-full"
                                                  pattern="\d*"

                      />
                    </FormControl>
                  </FormItem>
                  <FormItem>
                    <FormLabel>{t("stocklevel")}</FormLabel>
                    <FormControl>
                      <Input
                        {...methods.register("stockLevel", {
                          valueAsNumber: true,
                        })}
                        type="number"
                        placeholder={t("putstocklevel")}
                        className="w-full"
                                                  pattern="\d*"

                      />
                    </FormControl>
                  </FormItem>
                </fieldset>
              </div>
            </div>
            <div className="flex justify-center gap-4 mt-6">
              <Button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                {t("savelbtn")}
              </Button>
              <Button
                type="button"
                onClick={() => navigate(t("/prodlistpath"))}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                {t("canclelbtn")}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </FormProvider>
  );
};

export default ProductForm;
