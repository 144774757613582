import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import CreateSupplierDto from "@/models/CreateSupplierDto";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import "@/css/dialog.css";
import { Supplier } from "@/types/SupplierType";
import { SupplierFormProps } from "@/interfaces/SupplierInterfaces";
import DataFetcher from "@/provider/DataFetcher";
import "@/css/LoadingCreate.css";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";

interface Country {
  name: string;
  code: string;
}

const SupplierForm: React.FC<SupplierFormProps> = ({
  mode,
  inDialog = false,
  onClose,
}) => {
  const { t } = useTranslation();
  const [countriesList, setCountriesList] = useState<Country[]>([]);

  const formSchema = z.object({
    name: z
      .string()
      .min(1, { message: t("Name is required") })
      .max(50, {
        message: t("Supplier Name length must be between 1 and 50 characters"),
      }),
    phoneNumber: z.string().nullable().optional(),
 email: z
    .string()
    .email({ message: "Invalid email format" })
    .nullable()
    .optional(),
        contact: z.string().nullable().optional(),
    idType: z.string().optional().nullable(),
    vatNumber: z.string().nullable().optional(),
    address: z.string().nullable().optional(),
    city: z.string().nullable().optional(),
    country: z.string().nullable().optional(),
  });

  type FormValues = z.infer<typeof formSchema>;

  const [loading, setLoading] = useState(false); // Initialize loading state
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || { id: localStorage.getItem("supplierId") };

  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      phoneNumber: null,
      email: null,
      contact: null,
      vatNumber: null,
      idType: "",
      address: null,
      city: null,
      country: "ALB",
    },
  });

  const SUPPLIER_STORAGE_KEY = "supplierData";
useEffect(() => {
  if (id) {
    localStorage.setItem("supplierId", id);
  }

  if (mode === "edit" && id) {
    const fetchSupplier = async () => {
      try {
        const response = await DataFetcher.getData<Supplier>(
          `${API_URLS.BASE_URL}${API_URLS.SUPPLIER}/${id}`,
          SUPPLIER_STORAGE_KEY,
          true
        );
        if (response) {
          // Transform the response to match form's type expectations
          const transformedResponse = {
            ...response,
            vatNumber: response.vatNumber ? response.vatNumber.toString() : "", // Convert to string
          };

          // Reset the form with transformed data
          methods.reset(transformedResponse);
        }
      } catch (error) {
        console.error("Error fetching supplier:", error);
      }
    };
    fetchSupplier();
  }
}, [id, mode, methods]);



  const updateLocalStorage = (updatedSupplier: Supplier) => {
    // Ensure that existingSuppliers is an array, or use an empty array if not
    const existingSuppliers = JSON.parse(
      localStorage.getItem("supplierData") || "[]"
    ).data;

    // Check if the parsed result is an array, if not, default to an empty array
    const suppliersArray = Array.isArray(existingSuppliers)
      ? existingSuppliers
      : [];

    const updatedSuppliers = suppliersArray.map((supplier: Supplier) =>
      supplier.id === updatedSupplier.id ? updatedSupplier : supplier
    );

    localStorage.setItem("supplierData", JSON.stringify(updatedSuppliers));
  };

  const idTypes = [
    { value: "NUIS", label: t("NUIS") },
    { value: "ID", label: t("Karte Identiteti") },
    { value: "PASS", label: t("Pasaporte") },
    { value: "VAT", label: t("VAT Number") },
    { value: "TAX", label: t("TAX Number") },
    { value: "SOC", label: t("Social Security Number") },
  ];
  const handleValidation = () => {
    const country = methods.getValues("country") || "";
    const idType = methods.getValues("idType") || "";

    const albanianIdTypes = ["NUIS", "ID", "PASS"];
    const foreignIdTypes = ["VAT", "TAX", "SOC"];

    if (country === "ALB" && foreignIdTypes.includes(idType)) {
      toast.error(t("error.albanianIdTypeRequiredSupp"));
      return false;
    }

    if (country !== "ALB" && albanianIdTypes.includes(idType)) {
      toast.error(t("error.foreignIdTypeRequiredSupp"));
      return false;
    }

    return true;
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (!handleValidation()) return;
    setLoading(true); // Show loading spinner

    const dto = new CreateSupplierDto({
      name: data.name,
      phoneNumber: data.phoneNumber,
      email: data.email,
      contact: data.contact,
      idType: data.idType,
      vatNumber: data.vatNumber,
      address: data.address,
      city: data.city,
      country: data.country,
    });

    try {
      let successMessage = "";

      if (mode === "create") {
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.SUPPLIER}`,
          dto
        );
        console.log("Supplier created successfully:", response.data);
        successMessage = t("Supplier created successfully");

        // Ensure the existing data in localStorage is always an array
        const existingSuppliers = JSON.parse(
          localStorage.getItem("supplierData") || "[]"
        );
        const suppliersArray = Array.isArray(existingSuppliers)
          ? existingSuppliers
          : [];

        // Add the new supplier to the array
        suppliersArray.push(response.data);
        localStorage.setItem("supplierData", JSON.stringify(suppliersArray));

        if (inDialog && onClose) {
          onClose({ id: response.data.id, name: response.data.name });
        }
      } else if (mode === "edit" && id) {
        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.SUPPLIER}/${id}`,
          dto
        );
        console.log("Supplier updated successfully:", response.data);
        successMessage = t("Supplier updated successfully");

        // Update local storage with the updated supplier
        updateLocalStorage(response.data);
      }

      methods.reset();
      localStorage.removeItem("supplierId");

      // Navigate first, then show toast message after a short delay
      if (inDialog && onClose) {
        onClose();
      } else {
        navigate(t("/supplierlistpath"));
      }

      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorSupplier.${err.errorMessage}`) ||
              t("errorSupplier.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting supplier:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  const handleDialogSubmit = () => {
    methods.handleSubmit(onSubmit)();
  };
  const { setValue } = methods;

  useEffect(() => {
    // Register English locale for countries
    countries.registerLocale(enLocale);
    const countryData = Object.entries(
      countries.getNames("en", { select: "official" })
    ).map(([code, name]) => ({
      name,
      code: countries.alpha2ToAlpha3(code) || code,
    }));

    setCountriesList(countryData);
  }, []);

  return (
    <FormProvider {...methods}>
      <div
        className={`flex justify-center items-center ${
          inDialog ? "dialog-grid" : ""
        }`}
      >
        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-3xl p-6 rounded shadow-md">
          <h1 className="text-xl font-bold mb-6">
            {mode === "create" ? t("createsupplier") : t("editsupplier")}
          </h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleDialogSubmit();
            }}
          >
            <div className={inDialog ? "dialog-fieldsets" : ""}>
              <fieldset className="border p-4 rounded mb-4">
                <legend className="text-lg font-semibold">
                  {t("infosupplier")}
                </legend>
                <FormItem>
                  <FormLabel>
                    {t("name")}
                    <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("name")}
                      type="search"
                      placeholder={t("putname")}
                      className="w-full"
                    />
                  </FormControl>
                  {methods.formState.errors.name && (
                    <FormMessage>
                      {methods.formState.errors.name.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("telnumber")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("phoneNumber")}
                      type="number"
                                              pattern="\d*"

                      placeholder={t("puttelnumber")}
                      className="w-full"
                    />
                  </FormControl>
                  {methods.formState.errors.phoneNumber && (
                    <FormMessage>
                      {methods.formState.errors.phoneNumber.message}
                    </FormMessage>
                  )}
                </FormItem>
              <FormItem>
  <FormLabel>Email</FormLabel>
  <FormControl>
    <Input
      {...methods.register("email", {
        required: t("Email is required"),
        pattern: {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Regular expression for email format
          message: t("Invalid email format"),
        },
      })}
      type="email"
      placeholder={t("putemail")}
      className="w-full"
    />
  </FormControl>
  {methods.formState.errors.email && (
    <FormMessage>
      {methods.formState.errors.email.message}
    </FormMessage>
  )}
</FormItem>

                <FormItem>
                  <FormLabel>{t("contact")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("contact")}
                      type="search"
                      placeholder={t("putcontact")}
                      className="w-full"
                    />
                  </FormControl>
                  {methods.formState.errors.contact && (
                    <FormMessage>
                      {methods.formState.errors.contact.message}
                    </FormMessage>
                  )}
                </FormItem>
              </fieldset>
              <fieldset className="border p-4 rounded mb-4">
                <FormItem>
                  <FormLabel>{t("ID Type")}</FormLabel>
                  <div className="flex ">
                    <FormControl>
                      <Controller
                        name="idType"
                        control={methods.control}
                        render={({ field }) => (
                          <Select
                            onValueChange={field.onChange}
                            value={field.value ?? ""}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder={t("Select ID Type")} />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                {idTypes.map((idType) => (
                                  <SelectItem
                                    key={idType.value}
                                    value={idType.value}
                                  >
                                    {idType.label}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        )}
                      />
                    </FormControl>
                    <a className="tooltip">
                      <InformationCircleIcon className="h-9 w-9 pl-1" />
                      <span className="tooltiptext">
                        {t("tooltip.idTypeInfoSupp")}
                      </span>
                    </a>
                  </div>
                  {methods.formState.errors.idType && (
                    <FormMessage>
                      {methods.formState.errors.idType.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("id")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("vatNumber")}
                      type="search"
                      placeholder={t("putid")}
                      className="w-full"
                    />
                  </FormControl>
                </FormItem>
                <FormItem>
                  <FormLabel>{t("adresss")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("address")}
                      type="search"
                      placeholder={t("putadresss")}
                      className="w-full"
                    />
                  </FormControl>
                  {methods.formState.errors.address && (
                    <FormMessage>
                      {methods.formState.errors.address.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("city")}</FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("city")}
                      type="search"
                      placeholder={t("putcity")}
                      className="w-full"
                    />
                  </FormControl>
                  {methods.formState.errors.city && (
                    <FormMessage>
                      {methods.formState.errors.city.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>{t("Country")}</FormLabel>
                  <FormControl>
                    <Controller
                      name="country"
                      control={methods.control}
                      render={({ field }) => (
                        <Select
                          onValueChange={(value) => {
                            field.onChange(value);
                            setValue("idType", "");
                          }}
                          value={field.value ?? ""}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder={t("Select Country")} />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {countriesList.map((country) => (
                                <SelectItem
                                  key={country.code}
                                  value={country.code}
                                >
                                  {country.name} ({country.code})
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      )}
                    />
                  </FormControl>
                </FormItem>
              </fieldset>
            </div>
            <div className="flex justify-center gap-4 mt-6">
              <Button
                type="button"
                onClick={handleDialogSubmit}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                {t("savelbtn")}
              </Button>
              <Button
                type="button"
                onClick={() =>
                  inDialog ? onClose?.() : navigate(t("/supplierlistpath"))
                }
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                {t("canclelbtn")}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </FormProvider>
  );
};

export default SupplierForm;
