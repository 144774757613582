import React from "react";
import { useTheme } from "./ThemeContext";

type QuantityInputProps = {
  quantity: number;
  onIncrement: () => void;
  onDecrement: () => void;
  onChange: (value: number) => void;
};

const QuantityInput: React.FC<QuantityInputProps> = ({
  quantity,
  onIncrement,
  onDecrement,
  onChange,
}) => {
  const containerStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    padding: "5px",
  };

  const { theme } = useTheme();

  const buttonStyle: React.CSSProperties = {
    backgroundColor:
      theme === "dark" ? "text-white bg-black" : "text-black bg-gray-100",
    border: "1px solid #ccc",
    padding: "5px 10px",
    cursor: "pointer",
    borderRadius: "4px",
  };

  const inputStyle: React.CSSProperties = {
    backgroundColor:
      theme === "dark" ? "text-white bg-black" : "text-black bg-gray-100",
    width: "70px",
    textAlign: "center" as const,
    border: "1px solid #ccc",
    padding: "5px",
    color: "black",
    borderRadius: "4px",
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!isNaN(Number(value))) {
      onChange(parseFloat(value));
    }
  };

  return (
    <div style={containerStyle}>
      <button type="button" style={buttonStyle} onClick={onDecrement}>
        -
      </button>
      <input
        type="number"
        step="0.0001"
        value={quantity}
        onChange={handleChange}
        style={inputStyle}
      />
      <button type="button" style={buttonStyle} onClick={onIncrement}>
        +
      </button>
    </div>
  );
};

export default QuantityInput;
