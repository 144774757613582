import React from "react";
import SalesChart from "./SalesChart";
import Statistics from "./Statistics";
import ProfitChart from "./ProfitChart";
import MostSoldProducts from "./MostSoldProducts";
import { Grid, Box } from "@mui/material";

const Dashboard: React.FC = () => {
  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <SalesChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <Statistics />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProfitChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <MostSoldProducts />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
