class CreatePaymentmethodsDto {
  name: string;
  code: string;
  isCash: boolean;

  constructor(paymentmethods: {
    name?: string;
    code?: string;
    isCash?: boolean;
  }) {
    this.name = paymentmethods.name || "";
    this.code = paymentmethods.code || "";
    this.isCash =
      paymentmethods.isCash !== undefined ? paymentmethods.isCash : false;
  }
}

export default CreatePaymentmethodsDto;
