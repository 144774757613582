import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Language detector plugin
import enTranslation from './locale/en.json'; // English translations
import albTranslation from './locale/alb.json'; // Albanian translations

i18n
  .use(LanguageDetector) // Use the language detector plugin
  .use(initReactI18next) // Initialize react-i18next
  .init({
    fallbackLng: ['alb', 'en'], // Fallback languages: Albanian first, then English
    debug: true,
   saveMissing: true,  // Optional: Save missing keys

    resources: {
      en: {
        translation: enTranslation,
      },
      alb: {
        translation: albTranslation,
      },
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    detection: {
      // Options for language detection
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
  });

export default i18n;
