import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { Currency } from "@/types/CurrencyType";
import CreateCurrencyDto from "@/models/CreateCurrencyDto";
import { CurrencyFormProps } from "@/interfaces/CurrencyInterface";
import "@/css/LoadingCreate.css";

const CurrencyForm: React.FC<CurrencyFormProps> = ({
  mode,
  inDialog = false,
  onClose,
}) => {
  const { t } = useTranslation();

  const formSchema = z.object({
    name: z
      .string()
      .min(1, { message: t("Name is required") })
      .max(10, {
        message: t("Currency name must be between 1 and 10 characters"),
      }),
    symbol: z.string().min(1, { message: t("Symbol is required") }),
    code:z.string().min(1, { message: t("Codes is required") }),
  });

  type FormValues = z.infer<typeof formSchema>;
  const [loading, setLoading] = useState(false); // Initialize loading state
const [customCode, setCustomCode] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || { id: localStorage.getItem("currencyId") };
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      symbol: "",
      code:""
    },
  });

  useEffect(() => {
    if (id) {
      localStorage.setItem("currencyId", id);
    }

    if (mode === "edit" && id) {
      const fetchCurrency = async () => {
        try {
          const response = await axios.get(
            `${API_URLS.BASE_URL}${API_URLS.Currency}/${id}`
          );
          methods.reset(response.data);
        } catch (error) {
          console.error("Error fetching currency:", error);
        }
      };

      fetchCurrency();
    }
  }, [id, mode, methods]);
const [selectedCode, setSelectedCode] = useState(methods.getValues("code"));


  const updateLocalStorage = (updatedCurrency: Currency) => {
    const existingCurrency = JSON.parse(
      localStorage.getItem("currencyData") || "[]"
    );
    const updatedCurrencies = existingCurrency.map((currency: Currency) =>
      currency.id === updatedCurrency.id ? updatedCurrency : currency
    );
    localStorage.setItem("currencyData", JSON.stringify(updatedCurrencies));
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    const currencyData = new CreateCurrencyDto({
      name: data.name,
      symbol: data.symbol,
      code:data.code
    });

    try {
      let successMessage = "";

      if (mode === "create") {
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`,
          currencyData
        );
        console.log("Currency created successfully", response.data);
        successMessage = t("Currency created successfully");

        const existingCurrency = JSON.parse(
          localStorage.getItem("currencyData") || "[]"
        );
        existingCurrency.push(response.data);
        localStorage.setItem("currencyData", JSON.stringify(existingCurrency));
      } else if (mode === "edit" && id) {
        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.Currency}/${id}`,
          currencyData
        );
        console.log("Currency updated successfully:", response.data);
        successMessage = t("Currency updated successfully");

        updateLocalStorage(response.data);
      }

      methods.reset();
      localStorage.removeItem("currencyId");
      navigate(t("/currencylistpath"));

      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorCurrency.${err.errorMessage}`) ||
              t("errorCurrency.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting currency:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };
  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-3xl p-6 rounded shadow-md">
          <div style={{ padding: "20px" }}>
            <h1>
              {mode === "create" ? t("/createcurrency") : t("/editcurrency")}
            </h1>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "20px",
                }}
              >
                <FormItem>
                  <FormLabel>
                    {t("name")}
                    <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("name")}
                      type="search"
                      placeholder={t("putname")}
                    />
                  </FormControl>
                  {methods.formState.errors.name && (
                    <FormMessage>
                      {methods.formState.errors.name.message}
                    </FormMessage>
                  )}
                </FormItem>
                <FormItem>
                  <FormLabel>
                    {t("symbol")}
                    <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...methods.register("symbol")}
                      type="search"
                      placeholder={t("putsumbol")}
                    />
                  </FormControl>
                  {methods.formState.errors.symbol && (
                    <FormMessage>
                      {methods.formState.errors.symbol.message}
                    </FormMessage>
                  )}
                </FormItem>
  <FormItem style={{ width: "100%" }}>
  <FormLabel>
    {t("Code")}
    <span style={{ color: "red" }}>*</span>
  </FormLabel>
  <FormControl>
    <div style={{ marginTop: "8px", width: "100%" }}>
      {!customCode ? (
        <select
          {...methods.register("code")}
          value={selectedCode} // Controlled by the component state
          style={{ width: "100%" }}
          onChange={(e) => {
            const value = e.target.value;
            setSelectedCode(value); // Update the state with the selected value
            if (value === "custom") {
              setCustomCode(true); // Show the input field when "custom" is selected
              setSelectedCode(""); // Clear selectedCode to allow new input
            }
          }}
        >
          <option value="" disabled>
            {t("Select Code")}
          </option>
          {/* Predefined list of codes */}
          <option value="ALL">ALL</option>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
          <option value="GBP">GBP</option>
          <option value="CHF">CHF</option>
          <option value="JPY">JPY</option>
          <option value="AUD">AUD</option>
          <option value="CAD">CAD</option>
          <option value="SEK">SEK</option>
          <option value="NOK">NOK</option>
          <option value="DKK">DKK</option>
          <option value="SDR">SDR</option>
          <option value="XAU">XAU</option>
          <option value="XAG">XAG</option>
          <option value="CNY">CNY</option>
          <option value="CNH">CNH</option>
          <option value="TRY">TRY</option>
          <option value="BGN">BGN</option>
          <option value="HUF">HUF</option>
          <option value="HRK">HRK</option>
          <option value="CZK">CZK</option>
          <option value="MKD">MKD</option>
          {/* Conditionally add the server-fetched code as an option */}
          {![ 
            "ALL", "USD", "EUR", "GBP", "CHF", "JPY", "AUD", "CAD", "SEK",
            "NOK", "DKK", "SDR", "XAU", "XAG", "CNY", "CNH", "TRY", "BGN",
            "HUF", "HRK", "CZK", "MKD"
          ].includes(methods.getValues("code")) && (
            <option value={methods.getValues("code")}>
              {methods.getValues("code")}
            </option>
          )}
          <option value="custom">{t("Other")}</option>
        </select>
      ) : (
        <div style={{ marginTop: "8px", width: "100%" }}>
          <Input
            type="search"
            {...methods.register("code")}
            value={selectedCode} // Controlled input value
            onChange={(e) => setSelectedCode(e.target.value)} // Update state on input change
            placeholder={t("Enter Custom Code")}
            style={{ width: "100%" }}
          />
        </div>
      )}
    </div>
  </FormControl>
  {methods.formState.errors.code && (
    <FormMessage>
      {methods.formState.errors.code.message}
    </FormMessage>
  )}
</FormItem>







              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "50px",
                }}
              >
                <Button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {mode === "create" ? t("savelbtn") : t("savelbtn")}
                </Button>
                <Button
                  type="button"
                  onClick={() =>
                    inDialog ? onClose?.() : navigate(t("/currencylistpath"))
                  }
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  {t("canclelbtn")}
                </Button>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
    </FormProvider>
  );
};

export default CurrencyForm;
