import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import "@/css/MobileScreen.css";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { Lot } from "@/types/LotType";
import CreateLotDto from "@/models/CreateLotDto";
import { LotFormProps } from "@/interfaces/LotInterfaces";
import "@/css/LoadingCreate.css";

const LotForm: React.FC<LotFormProps> = ({
  mode,
  inDialog = false,
  onClose,
}) => {
  const { t } = useTranslation();

  const formSchema = z.object({
    lotNumber: z
      .string()
      .min(1, { message: t("Name is required") })
      .max(50, {
        message: t("length of lot number should be between from 1 to 50"),
      }),
    expiryDate: z.string().min(1, { message: t("Expiry date is required") }),
  });

  type FormValues = z.infer<typeof formSchema>;

  const [loading, setLoading] = useState(false); // Initialize loading state

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || { id: localStorage.getItem("lotId") };
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      lotNumber: "",
      expiryDate: "",
    },
  });

  useEffect(() => {
    if (id) {
      localStorage.setItem("lotId", id);
    }

    if (mode === "edit" && id) {
      const fetchLot = async () => {
        try {
          const response = await axios.get(
            `${API_URLS.BASE_URL}${API_URLS.LOT}/${id}`
          );
          const lotData = response.data;
          lotData.expiryDate = lotData.expiryDateUtc.split("T")[0]; // Convert to yyyy-MM-dd
          methods.reset(lotData);
        } catch (error) {
          console.error("Error fetching lot:", error);
        }
      };

      fetchLot();
    }
  }, [id, mode, methods]);

  const updateLocalStorage = (updatedLot: Lot) => {
    const existingLots = JSON.parse(localStorage.getItem("lotData") || "[]");
    const updatedLots = existingLots.map((lot: Lot) =>
      lot.id === updatedLot.id ? updatedLot : lot
    );
    localStorage.setItem("lotData", JSON.stringify(updatedLots));
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    const expiryDateUtc = `${data.expiryDate}T00:00:00.000Z`; // Default to midnight if no time provided

    const lotData = new CreateLotDto({
      lotNumber: data.lotNumber,
      expiryDateUtc,
    });

    try {
      let successMessage = "";

      if (mode === "create") {
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.LOT}`,
          lotData
        );
        const existingLots = JSON.parse(
          localStorage.getItem("lotData") || "[]"
        );
        existingLots.push(response.data);
        localStorage.setItem("lotData", JSON.stringify(existingLots));
        successMessage = t("Lot created successfully");
      } else if (mode === "edit" && id) {
        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.LOT}/${id}`,
          lotData
        );
        updateLocalStorage(response.data);
        successMessage = t("Lot updated successfully");
      }

      methods.reset();
      localStorage.removeItem("lotId");
      navigate(t("/lotlistpath"));

      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;

        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            toast.error(
              t(`errorLot.${err.errorMessage}`) ||
                t("errorLot.An error occurred")
            );
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting lot:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="relative">
        {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="flex justify-center items-center">
          <div className="w-full max-w-3xl p-6 rounded shadow-md">
            <div style={{ padding: "20px" }}>
              <h1>{mode === "create" ? t("createlot") : t("editlot")}</h1>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "20px",
                  }}
                >
                  <FormItem>
                    <FormLabel>
                      {t("lotNumber")}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...methods.register("lotNumber")}
                        type="text"
                        placeholder={t("putname")}
                      />
                    </FormControl>
                    {methods.formState.errors.lotNumber && (
                      <FormMessage>
                        {methods.formState.errors.lotNumber.message}
                      </FormMessage>
                    )}
                  </FormItem>
                  <FormItem>
                    <FormLabel>
                      {t("expdate")}
                      <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...methods.register("expiryDate")}
                        type="date"
                        placeholder={t("putdate")}
                        style={{ backgroundColor: "white", color: "black" }} // Adjust as needed for your dark mode
                      />
                    </FormControl>
                    {methods.formState.errors.expiryDate && (
                      <FormMessage>
                        {methods.formState.errors.expiryDate.message}
                      </FormMessage>
                    )}
                  </FormItem>
                </div>
                <div
                  style={{ display: "flex", gap: "10px", marginTop: "50px" }}
                >
                  <Button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    {t("savelbtn")}
                  </Button>
                  <Button
                    type="button"
                    onClick={() =>
                      inDialog ? onClose?.() : navigate(t("/lotlistpath"))
                    }
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                  >
                    {t("canclelbtn")}
                  </Button>
                </div>
              </form>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default LotForm;
