class CloseTabelDto {
  tableId: number | null;
  cashAccountId: number;
  currencyId: number;
  exchangeRate: number;
  amountDue: number;
  warehouseId: number | undefined;
  paymentMethodId: number;

  constructor(order: {
  tableId: number | null;
    cashAccountId: number;
    currencyId: number;
    exchangeRate: number;
    amountDue: number;
  warehouseId: number | undefined;
    paymentMethodId: number;
  }) {
    this.tableId = order.tableId ?? null;
    this.cashAccountId = order.cashAccountId;
    this.currencyId = order.currencyId;
    this.exchangeRate = order.exchangeRate;
    this.amountDue = order.amountDue;
    this.warehouseId = order.warehouseId;
    this.paymentMethodId = order.paymentMethodId;
  }
}

export default CloseTabelDto;
