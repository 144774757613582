import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DataFetcher from "@/provider/DataFetcher";
import API_URLS from "@/config";

interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}

const Statistics: React.FC = () => {
  const { t } = useTranslation();

  // State to store API data
  const [statsData, setStatsData] = useState({
    yearlyRevenue: 0,
    monthlyRevenue: 0,
    weeklyRevenue: 0,
    todayRevenue: 0,
    yearlyExpenseOnProductPurchases: 0,
    monthlyExpenseOnProductPurchases: 0,
    weeklyExpenseOnProductPurchases: 0,
    monthlyCustomerCount: 0,
    outOfStockCount: 0,
  });

  const [businessConfig, setBusinessConfig] = useState<BusinessConfigs | null>(
    null
  );
  const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L");
  const DASHBOARD_STORAGE_KEY = "dashboardData";

  useEffect(() => {
    // Fetch businessConfig from localStorage
    const config = JSON.parse(localStorage.getItem("businessConfigs") || "{}");
    setBusinessConfig(config);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!businessConfig) return; // Wait until businessConfig is set
      try {
        const response = await DataFetcher.getData<any>(
          `${API_URLS.BASE_URL}${API_URLS.Dashboard}`,
          DASHBOARD_STORAGE_KEY,
          true
        );

        const data = response || {};
        setStatsData({
          yearlyRevenue: data.yearlyRevenue || 0,
          monthlyRevenue: data.monthlyRevenue || 0,
          weeklyRevenue: data.weeklyRevenue || 0,
          todayRevenue: data.todayRevenue || 0,
          yearlyExpenseOnProductPurchases:
            data.yearlyExpenseOnProductPurchases || 0,
          monthlyExpenseOnProductPurchases:
            data.monthlyExpenseOnProductPurchases || 0,
          weeklyExpenseOnProductPurchases:
            data.weeklyExpenseOnProductPurchases || 0,
          monthlyCustomerCount: data.monthlyCostumerIds?.length || 0,
          outOfStockCount:
            data.outOfStockProductIdsBasedOnWarehouse?.length || 0,
        });
      } catch (error) {
        console.error("Error fetching statistics data:", error);
      }
    };

    fetchData();
  }, [businessConfig]);

  useEffect(() => {
    const fetchCurrencies = async () => {
      if (!businessConfig?.baseCurrencyId) return; // Ensure baseCurrencyId is available
      try {
        const response = await DataFetcher.getData<any[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`,
          "currencyData"
        );

        const baseCurrency = response?.find(
          (currency) => currency.id === businessConfig.baseCurrencyId
        );

        setBaseCurrencySymbol(baseCurrency?.symbol || "L");
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    };

    fetchCurrencies();
  }, [businessConfig]);

  const stats = [
    {
      label: t("Out of Stock"),
      value: statsData.outOfStockCount,
      color: "#9c27b0",
    },
    {
      label: t("Customers"),
      value: statsData.monthlyCustomerCount,
      color: "#3f51b5",
    },
    {
      label: t("Today Sales"),
      value: `${statsData.todayRevenue.toFixed(2)} ${baseCurrencySymbol}`,
      color: "#4caf50",
    },
    {
      label: t("This Week Sales"),
      value: `${statsData.weeklyRevenue.toFixed(2)} ${baseCurrencySymbol}`,
      color: "#4caf50",
    },
    {
      label: t("This Month Sales"),
      value: `${statsData.monthlyRevenue.toFixed(2)} ${baseCurrencySymbol}`,
      color: "#4caf50",
    },
    {
      label: t("This Year Sales"),
      value: `${statsData.yearlyRevenue.toFixed(2)} ${baseCurrencySymbol}`,
      color: "#4caf50",
    },
    {
      label: t("This Month Purchases"),
      value: `${statsData.monthlyExpenseOnProductPurchases.toFixed(
        2
      )} ${baseCurrencySymbol}`,
      color: "#f44336",
    },
    {
      label: t("This Year Purchases"),
      value: `${statsData.yearlyExpenseOnProductPurchases.toFixed(
        2
      )} ${baseCurrencySymbol}`,
      color: "#f44336",
    },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Paper
              style={{
                padding: "16px",
                backgroundColor: stat.color,
                color: "#fff",
              }}
            >
              <Typography variant="h6">{stat.label}</Typography>
              <Typography variant="h4">{stat.value}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Statistics;
