class CreateTransactionDto {
  transactionType: number;  // Assuming this could be a string representing the type of transaction (e.g., "withdrawal", "deposit").
  amount: number;           // The amount of the transaction.
  cashAccountId: number;     // The ID of the cash account associated with the transaction.
  note?: string | null;      // An optional note, which can be null or undefined.

  constructor(transactionData: {
    transactionType: number;
    amount: number;
    cashAccountId: number;
    note?: string | null;
  }) {
    this.transactionType = transactionData.transactionType;
    this.amount = transactionData.amount;
    this.cashAccountId = transactionData.cashAccountId;
    this.note = transactionData.note ?? null; // Defaulting to null if not provided.
  }
}

export default CreateTransactionDto;
