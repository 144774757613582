import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import "@/css/loading.css";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ArrowUpDown, ChevronDown, Filter, MoreHorizontal } from "lucide-react";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { Inventory } from "@/types/InventoryType";
import { Warehouse } from "@/types/WarehouseType";
import { ProductType } from "@/types/ProductType";
import * as ExcelJS from "exceljs";
import DataFetcher from "@/provider/DataFetcher";
import { EmployeeType } from "@/types/EmployeeType";
import { Lot } from "@/types/LotType"; // Assuming you have a type for Lot

const INVENTORY_STORAGE_KEY = "inventoryData";
const WAREHOUSE_STORAGE_KEY = "warehouseData";
const PRODUCT_STORAGE_KEY = "productData";
const EMPLOYEE_STORAGE_KEY = "employeeData";
const LOT_STORAGE_KEY = "lotData";

const InventoryList = () => {
  const [data, setData] = useState<Inventory[]>([]);
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "id", desc: true },
  ]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    id: false,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  type RowSelection = Record<string, boolean>;
  const location = useLocation();

  const [rowSelection, setRowSelection] = useState<RowSelection>({});
  const { t } = useTranslation();

  const navigate = useNavigate();

  // Fetch lot data
  const fetchLotsFromDataFetcher = async (): Promise<Lot[]> => {
    const lots = await DataFetcher.getData<Lot[]>(
      `${API_URLS.BASE_URL}${API_URLS.LOT}`,
      LOT_STORAGE_KEY
    );
    return lots || [];
  };

  useEffect(() => {
    const fetchInventoriesFromServer = async (): Promise<Inventory[]> => {
      const inventoryResponse = await DataFetcher.getData<Inventory[]>(
        `${API_URLS.BASE_URL}${API_URLS.INVENTORY}`,
        INVENTORY_STORAGE_KEY,
        true
      );
      const inventories = inventoryResponse;
      localStorage.setItem(INVENTORY_STORAGE_KEY, JSON.stringify(inventories));
      return inventories || [];
    };

    const fetchWarehousesFromDataFetcher = async (): Promise<Warehouse[]> => {
      const warehouses = await DataFetcher.getData<Warehouse[]>(
        `${API_URLS.BASE_URL}${API_URLS.WAREHOUSE}`,
        WAREHOUSE_STORAGE_KEY
      );
      return warehouses || [];
    };

    const fetchProductsFromDataFetcher = async (): Promise<ProductType[]> => {
      const products = await DataFetcher.getData<ProductType[]>(
        `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`,
        PRODUCT_STORAGE_KEY
      );
      return products || [];
    };

    const fetchEmployees = async (): Promise<EmployeeType[]> => {
      try {
        return (
          (await DataFetcher.getData<EmployeeType[]>(
            `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
            EMPLOYEE_STORAGE_KEY
          )) || []
        );
      } catch (error) {
        console.error("Error fetching employees:", error);
        return [];
      }
    };

    const updateStateWithInventories = (
      inventories: Inventory[],
      warehouses: Warehouse[],
      products: ProductType[],
      employees: EmployeeType[],
      lots: Lot[]
    ) => {
      const warehouseMap = warehouses.reduce<Record<string, string>>(
        (acc, warehouse) => {
          acc[warehouse.id] = warehouse.name;
          return acc;
        },
        {}
      );

      const productMap = products.reduce<Record<number, string>>(
        (acc, product) => {
          acc[product.id] = product.name;
          return acc;
        },
        {}
      );

      const employeeMap = employees.reduce<Record<number, string>>(
        (acc, employee) => {
          acc[employee.id] = employee.name;
          return acc;
        },
        {}
      );

      const lotMap = lots.reduce<Record<string, string>>((acc, lot) => {
        acc[lot.id] = lot.lotNumber;
        acc[lot.lotNumber] = lot.expiryDateUtc;
        return acc;
      }, {});

      const updatedInventories = inventories.map((inventory) => {
        const lotExpiryDate = inventory.lotId
          ? lotMap[inventory.lotId]
          : undefined;
        const isExpired = lotExpiryDate
          ? new Date(lotExpiryDate) < new Date()
          : undefined;

        if (isExpired) {
          toast.error(
            `${t("warning Product:")}${productMap[inventory.productId]}, ${t(
              "Lot:"
            )} ${lotMap[inventory.lotId]},${t("Hasexpired")}`
          );
        }

        return {
          ...inventory,
          warehouseName: inventory.warehouseId
            ? warehouseMap[inventory.warehouseId]
            : "",
          productName: productMap[inventory.productId],
          createdByName: inventory.createdBy
            ? employeeMap[inventory.createdBy] || ""
            : "",
          updatedByName: inventory.updatedBy
            ? employeeMap[inventory.updatedBy] || ""
            : "",
          isExpired: isExpired || undefined,
          lotName: lotMap[inventory.lotId] || "",
        };
      });
      // Log the updated inventories for debugging
      console.log("Updated Inventories: ", updatedInventories);

      setData(updatedInventories);
    };

    const fetchData = async () => {
      try {
        const [inventories, warehouses, products, employees, lots] =
          await Promise.all([
            fetchInventoriesFromServer(),
            fetchWarehousesFromDataFetcher(),
            fetchProductsFromDataFetcher(),
            fetchEmployees(),
            fetchLotsFromDataFetcher(),
          ]);

        if (warehouses && products) {
          updateStateWithInventories(
            inventories,
            warehouses,
            products,
            employees,
            lots
          );
        } else {
          console.error("Failed to fetch data from DataFetcher.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (inventoryId: string) => {
    navigate(t("/editinventorypath"), { state: { id: inventoryId } });
  };

  useEffect(() => {
    if (!loading && location.state?.showToast) {
      toast.success(location.state.message);
    }
  }, [loading, location.state]);

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`${API_URLS.BASE_URL}${API_URLS.INVENTORY}/${id}`);
      const updatedData = data.filter((inventory) => inventory.id !== id);
      setData(updatedData);
      localStorage.setItem(INVENTORY_STORAGE_KEY, JSON.stringify(updatedData));
      toast.success(t("Inventory deleted successfully"));
    } catch (error) {
      console.error("Error deleting inventory:", error);
      toast.error(t("Error deleting inventory"));
    }
  };


  const handleDeleteSelected = async () => {
    // Get selected row IDs from rowSelection
    const selectedRowIds = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    // Extract employee IDs to delete based on selected rows
    const iventoryIdsToDelete = data
      .filter((_item, index) => selectedRowIds.includes(index.toString())) // Adjust this if rowSelection keys are different
      .map((item) => item.id); // Assuming `item.id` is the employee ID

    if (iventoryIdsToDelete.length === 0) {
      toast.error(t("Please select records to delete."));
      return;
    }

    try {
      // Delete selected employees from the API
      await Promise.all(
        iventoryIdsToDelete.map((id) =>
          axios.delete(`${API_URLS.BASE_URL}${API_URLS.INVENTORY}/${id}`)
        )
      );

      // Remove deleted employees from the state
      const updatedData = data.filter(
        (item) => !iventoryIdsToDelete.includes(item.id)
      );
      setData(updatedData);
      setRowSelection({});

      // Update local storage with the updated data
      localStorage.setItem(INVENTORY_STORAGE_KEY, JSON.stringify(updatedData));

      toast.success(t("Selected records deleted successfully."));
    } catch (error) {
      console.error("Failed to delete records:", error);
      toast.error(t("Failed to delete selected records."));
    }
  };

  const handleAdd = () => {
    navigate(t("/createinventorypath"));
  };
  const exportToExcel = async () => {
    // Get the selected rows or use the full data if no rows are selected
    const selectedRows = table
      .getFilteredSelectedRowModel()
      .rows.map((row) => row.original);
    const rowsToExport = selectedRows.length > 0 ? selectedRows : data;

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Inventory");

    // Define columns based on the data structure
    worksheet.columns = [
      // { header: 'ID', key: 'id', width: 10 },
      { header: "Warehouse", key: "warehouseName", width: 30 },
      { header: "Product", key: "productName", width: 30 },
      { header: "Lot", key: "lotName", width: 30 },
      { header: "Quantity", key: "quantity", width: 30 },
    ];

    // Map rowsToExport to match the worksheet columns
    const worksheetData = rowsToExport.map((inventory) => ({
      warehouseName: inventory.warehouseName,
      productName: inventory.productName,
      lotId: inventory.lotName,
      quantity: inventory.quantity,
    }));

    // Add rows to worksheet
    worksheet.addRows(worksheetData);

    // Generate Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(
      new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "inventory.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns: ColumnDef<Inventory>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "id",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("ID")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("id")}</div>,
    },

    {
      accessorKey: "warehouseName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("warehouseName")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("warehouseName")}</div>,
    },
    {
      accessorKey: "productName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("productName")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("productName")}</div>,
    },
    // {
    //   accessorKey: "lotName",
    //   header: t("lotId"),
    //   cell: ({ row }) => <div>{row.getValue("lotName")}</div>,
    // },
    {
      accessorKey: "quantity",
      header: t("quantity"),
      cell: ({ row }) => <div>{row.getValue("quantity")}</div>,
    },
    {
      accessorKey: "createdByName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("createdBy")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("createdByName")}</div>,
    },
    {
      accessorKey: "updatedByName",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("updatedBy")}
          <ArrowUpDown className="ml-2 h-4 w-4 icon" />
        </Button>
      ),
      cell: ({ row }) => <div>{row.getValue("updatedByName")}</div>,
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4 icon" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>{t("action")}</DropdownMenuLabel>
            <DropdownMenuItem onClick={() => handleEdit(row.original.id)}>
              {t("edit")}
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={() => handleDelete(row.original.id)}>
              {t("delete")}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex flex-wrap items-center py-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="mr-10 flex items-center">
              <Square3Stack3DIcon className="h-5 w-5" />
              <span className="hidden sm:flex sm:items-center">
                {t("action")} <ChevronDown className="ml-2 h-4 w-4" />
              </span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {/* <DropdownMenuItem>Import</DropdownMenuItem> */}
            <DropdownMenuItem onClick={exportToExcel}>
              {t("export")}
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleDeleteSelected}>
              {t("delete")}
            </DropdownMenuItem>{" "}
          </DropdownMenuContent>
        </DropdownMenu>
        <Input
          placeholder={t("filtername")}
          value={
            (table.getColumn("productName")?.getFilterValue() as string) ?? ""
          }
          onChange={(event) =>
            table.getColumn("productName")?.setFilterValue(event.target.value)
          }
          
          style={{
            width: window.innerWidth <= 999 ? "45%" : "100%",
                         fontSize: "16px"

          }}
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              className="ml-auto flex items-center justify-center"
            >
              <span className="hidden md:block ">{t("columns")}</span>
              <Filter className="md:ml-2 h-4 w-4 mx-auto" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => (
                <DropdownMenuCheckboxItem
                  key={column.id}
                  className="capitalize"
                  checked={column.getIsVisible()}
                  onCheckedChange={(value) => column.toggleVisibility(!!value)}
                >
                  {column.id}
                </DropdownMenuCheckboxItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onDoubleClick={() => handleEdit(row.original.id)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: row.original.isExpired
                      ? "lightcoral"
                      : "transparent",
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t("noresult")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {t("previous")}
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {t("next")}
          </Button>
        </div>
      </div>
      <div className="relative">
        <div className="fixed bottom-4 right-4 md:bottom-4 md:right-4">
          <Button
            type="button"
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleAdd}
          >
            +
          </Button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default InventoryList;
