class CreateShopDto {
  name: string;
  address: string;

  constructor(Shop: { name?: string; address?: string }) {
    this.name = Shop.name ?? "";
    this.address = Shop.address ?? "";
  }
}

export default CreateShopDto;
