import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Button } from '@/components/ui/button';
import { CheckCircleIcon, XCircleIcon } from 'lucide-react';

type ErrorDetail = {
    propertyName: string;
    errorMessage: string;
    attemptedValue?: string;
    errorType?: 'Validation' | 'Server';  // Added to match the ImportCustomer component
};

type LotResult = {
    id: string;
    lotNumber: string;
    status: 'Success' | 'Failed';
    errors?: ErrorDetail[];
};

const ImportLot: React.FC = () => {
    const [lot, setLot] = useState<LotResult[]>([]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        // Fetch import results from local storage
        const results = JSON.parse(localStorage.getItem("importLot") || "[]");
        setLot(results);
    }, []);

    const handleClearAndNavigate = () => {
        // Clear import results from local storage
        localStorage.removeItem("importLot");

        // Navigate to a different path
        navigate(t("/lotlistpath"));
    };

    return (
        <div style={{ paddingTop: '40px' }}>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead>{t("lot")}</TableHead>
                        <TableHead>{t("Status")}</TableHead>
                        <TableHead>{t("Error Detail")}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {lot.map((lot, index) => (
                        <TableRow key={`${lot.id}-${index}`}>
                            <TableCell>{lot.lotNumber}</TableCell>
                               <TableCell>
                                {lot.status === 'Success' ? (
                                    <CheckCircleIcon className="text-green-500 h-6 w-6" /> // Success icon
                                ) : (
                                    <XCircleIcon className="text-red-500 h-6 w-6" /> // Error icon
                                )}
                            </TableCell>
                            <TableCell>
                                {lot.status === 'Failed' ? (
                                    <div>
                                        {lot.errors?.map((error, idx) => (
                                            <div key={idx}>
                                                {error.errorType === 'Validation' && error.propertyName ? (
                                                    <>
                                                        <strong>{t(`errorLot.${error.propertyName}`)}:</strong> 
                                                        {t(`errorLot.${error.errorMessage}`)}
                                                    </>
                                                ) : (
                                                    <>{t(`errorLot.${error.errorMessage}`)}</>
                                                )}
                                                {error.attemptedValue && (
                                                    <div>
                                                        <em>{t("Attempted Value")}: {error.attemptedValue}</em>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    '-'
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
                                      <div className="fixed bottom-4 right-4 md:bottom-4 md:right-4">

                    <Button
                        type="button"
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                        onClick={handleClearAndNavigate}
                    >
                        OK
                    </Button>
                </div>
            </div>
    );
};

export default ImportLot;
