import React, { useState, useEffect, useRef } from "react";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { useAuth } from "../provider/authProvider";
import { useNavigate } from "react-router-dom";
import API_URLS from "@/config";
import { useTranslation } from "react-i18next";
import "@/css/loading.css";
import DataFetcher from "@/provider/DataFetcher";
import axios from "axios"; // Add this if not already imported
import { toast, ToastContainer } from "react-toastify";
import { Button } from "@/components/ui/button";
import { PencilSquareIcon } from "@heroicons/react/24/outline";

interface CompanyData {
  id: number;
  name: string;
  tin: string;
  city: string;
  country: string;
  subdomain: string;
  address: string;
  phoneNumber: string;
  email: string;
  fieldOfActivity: string;
  fiscalizationCertificate: string;
  isSeasonal: boolean;
  subscriptionStartDateUtc: string;
  subscriptionEndDateUtc: string;
  createDateUtc: string;
  superUser: {
    name: string;
    username: string;
    email: string;
    phoneNumber: string;

    // Add other fields as needed
  };
}
const formatExpiryDate = (isoDate: string): string => {
  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const calculateRemainingDays = (expiryDate: string): number => {
  const today = new Date();
  const expiry = new Date(expiryDate);
  const timeDiff = expiry.getTime() - today.getTime();
  const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return daysRemaining >= 0 ? daysRemaining : 0;
};

const COMPANY_DATA_STORAGE_KEY = "businessData";

const decodeJWT = (token: string) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join("")
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Failed to decode token", error);
    return null;
  }
};

const CompanyInfo: React.FC = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [businessData, setBusinessData] = useState<CompanyData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false); // New state for edit mode
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [avatarSrc, setAvatarSrc] = useState<string | undefined>(undefined);

  const { isSuperUser } = useAuth();

  const storedCertificate = JSON.parse(
    localStorage.getItem("certificate") || "{}"
  );
  const expiryDate = storedCertificate?.expiryDate;
  const remainingDays = expiryDate ? calculateRemainingDays(expiryDate) : 0;

  const formattedExpiryDate = expiryDate ? formatExpiryDate(expiryDate) : "";

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await DataFetcher.getData<CompanyData[]>(
          `${API_URLS.BASE_URL}${API_URLS.BUSINESSREGISTER}`,
          COMPANY_DATA_STORAGE_KEY,
          true
        );

        if (Array.isArray(response)) {
          if (token) {
            const decodedToken = decodeJWT(token);
            if (decodedToken && decodedToken.tenantId) {
              const tenantId = Number(decodedToken.tenantId);
              const matchedCompany = response.find(
                (company) => company.id === tenantId
              );

              if (matchedCompany) {
                setBusinessData(matchedCompany);
              } else {
                setError("No matching company data found");
              }
            } else {
              setError("Invalid token data");
            }
          } else {
            setError("Token is null or undefined");
          }
        } else {
          setError("Unexpected data format received");
        }
      } catch (error) {
        console.error("Error during fetchCompanyData", error);
        setError("Error fetching company data");
      } finally {
        setLoading(false);
      }
      const savedAvatar = getAvatarFromLocalStorage();
      if (savedAvatar) {
        setAvatarSrc(savedAvatar);
      }
    };

    fetchCompanyData();
  }, [token, navigate]);

  const getAvatarFromLocalStorage = (): string | null => {
    return localStorage.getItem("avatarSrc");
  };
  const handleEdit = () => {
    if (isEditing && businessData) {
      // Prepare the payload for updating the business data
      const updatedBusiness = {
        name: businessData.name,
        tin: businessData.tin,
        subdomain: businessData.subdomain,
        city: businessData.city,
        country: businessData.country,
        address: businessData.address,
        phoneNumber: businessData.phoneNumber,
        fieldOfActivity: businessData.fieldOfActivity,
        isSeasonal: businessData.isSeasonal,
        user_Name: businessData.superUser.name, // Required field
        user_Username: businessData.superUser.username, // Required field
        user_Email: businessData.superUser.email, // Existing email or the updated email
        user_PhoneNumber: businessData.superUser.phoneNumber || "", // Optional, empty string if null
      };

      // Send PUT request to update the business data on the server
      axios
        .put(
          `${API_URLS.BASE_URL}${API_URLS.BUSINESSREGISTER}/${businessData.id}`,
          updatedBusiness
        )
        .then((response) => {
          console.log("Data saved successfully");

          // Save the updated business data to localStorage
          localStorage.setItem(
            "businessData",
            JSON.stringify({ data: response.data })
          );

          // Show success message to the user
          const createBusiness = t("Company updated successfully");
          toast.success(createBusiness);

          // Switch to view mode
          setIsEditing(false);
        })
        .catch((error) => {
          console.error("Error saving data:", error);
          toast.error(t("An error occurred while saving the business data"));
        });
    } else {
      // Switch to edit mode if not currently in editing state
      setIsEditing(true);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (businessData) {
      setBusinessData({
        ...businessData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const [isDemo, setIsDemo] = useState(false);

  useEffect(() => {
    // Fetch and parse the certificate data from local storage
    const certificateData = localStorage.getItem("certificate");
    if (certificateData) {
      try {
        const parsedCertificate = JSON.parse(certificateData);
        if (parsedCertificate.isDemo === true) {
          setIsDemo(true);
        }
      } catch (error) {
        console.error(
          "Error parsing certificate data from localStorage:",
          error
        );
      }
    }
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result) {
          const avatarDataUrl = reader.result as string;
          setAvatarSrc(avatarDataUrl);
          localStorage.setItem("avatarSrc", avatarDataUrl); // Save to localStorage
        }
      };

      reader.readAsDataURL(file);
    }
  };
  const Upload = () => {
    navigate(t("/companycertificate"));
  };

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!businessData) {
    return <div className="no-data">{t("No company data available")}</div>;
  }

  return (
    <div className="p-4 overflow-x-auto">
      <div className="flex flex-col items-center space-y-4">
        <div
          className="relative w-28 h-28 border-4 border-gray-300 flex items-center justify-center"
          style={{ cursor: "pointer" }}
          onClick={handleAvatarClick}
        >
          <Avatar className="w-24 h-24 rounded-full overflow-hidden">
            <AvatarImage
              src={avatarSrc || "https://github.com/shadcn.png"}
              alt="Company Logo"
            />
          </Avatar>
          <PencilSquareIcon
            className="absolute bottom-1 right-1 w-7 h-7 text-gray-600 bg-white p-1 rounded-full border border-gray-300 shadow-lg"
            style={{
              cursor: "pointer",
            }}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>
        <h1 className="text-xl font-bold mb-6">{t("companyinfo")}</h1>
        <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-2">
          <div className="col-span-1">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("Company Name")}
              </label>
              {isEditing ? (
                <input
                  type="text"
                  name="name"
                  value={businessData?.name || ""}
                  onChange={handleChange}
                  className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
                    isEditing ? "edit-mode" : ""
                  }`}
                />
              ) : (
                <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                  {businessData?.name}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("tin")}
              </label>
              <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                {businessData.tin}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("city")}
              </label>
              {isEditing ? (
                <input
                  type="text"
                  name="city"
                  value={businessData?.city || ""}
                  onChange={handleChange}
                  className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
                    isEditing ? "edit-mode" : ""
                  }`}
                />
              ) : (
                <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                  {businessData?.city}
                </p>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("country")}
              </label>
              <input
                type="text"
                name="country"
                value={businessData?.country || ""}
                onChange={handleChange} // Ensure you have this handler
                readOnly
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("address")}
              </label>
              {isEditing ? (
                <input
                  type="text"
                  name="address"
                  value={businessData?.address || ""}
                  onChange={handleChange}
                  className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
                    isEditing ? "edit-mode" : ""
                  }`}
                />
              ) : (
                <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                  {businessData?.address}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("phoneNumber")}
              </label>
              {isEditing ? (
                <input
                  type="text"
                  name="phoneNumber"
                  value={businessData?.phoneNumber || ""}
                  onChange={handleChange}
                  className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
                    isEditing ? "edit-mode" : ""
                  }`}
                />
              ) : (
                <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                  {businessData?.phoneNumber}
                </p>
              )}
            </div>
            {businessData?.fieldOfActivity && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  {t("email")}
                </label>
                <input
                  type="email"
                  name="email"
                  value={businessData?.email || ""}
                  onChange={handleChange} // Ensure you have this handler
                  readOnly
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                />
              </div>
            )}
          </div>
          <div className="col-span-1">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("fieldOfActivity")}
              </label>
              {isEditing ? (
                <input
                  type="text"
                  name="fieldOfActivity"
                  value={businessData?.fieldOfActivity || ""}
                  onChange={handleChange}
                  className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm ${
                    isEditing ? "edit-mode" : ""
                  }`}
                />
              ) : (
                <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                  {businessData?.fieldOfActivity}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("Subdomain")}
              </label>
              <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                {businessData.subdomain}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("fiscalizationCertificate")}
              </label>
              <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                {businessData.fiscalizationCertificate}
              </p>
            </div>
            {!isDemo && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  {t("ExpiryDate Certificate")}
                </label>
                <p
                  className={`mt-1 block w-full px-3 py-2 rounded-md shadow-sm ${
                    remainingDays <= 30 && remainingDays >= 0
                      ? "border-red-500 text-red-500"
                      : "border-gray-300"
                  } border`}
                >
                  {formattedExpiryDate} &nbsp;{" "}
                  {/* Space between date and remaining days */}
                  {remainingDays >= 0
                    ? `${remainingDays} days remaining`
                    : "Expired"}
                </p>
              </div>
            )}

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("isSeasonal")}
              </label>
              <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                {businessData.isSeasonal ? "Yes" : "No"}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("subscriptionStartDateUtc")}
              </label>
              <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                {new Date(
                  businessData.subscriptionStartDateUtc
                ).toLocaleString()}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("subscriptionEndDateUtc")}
              </label>
              <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                {new Date(businessData.subscriptionEndDateUtc).toLocaleString()}
              </p>
            </div>
            {!businessData?.fieldOfActivity && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  {t("email")}
                </label>
                <input
                  type="email"
                  name="email"
                  value={businessData?.email || ""}
                  onChange={handleChange} // Ensure you have this handler
                  readOnly
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                />
              </div>
            )}
          </div>
          <div className="col-span-1 md:col-span-2">
            <h1 className="text-xl font-bold mb-6">{t("Admin Info")}</h1>
          </div>
          <div className="col-span-1">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("Admin Name")}
              </label>
              <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                {businessData?.superUser?.name || t("No data available")}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("Admin Username")}
              </label>
              <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                {businessData?.superUser?.username || t("No data available")}
              </p>
            </div>
          </div>
          <div className="col-span-1">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("Admin Email")}
              </label>
              <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                {businessData?.superUser?.email || t("No data available")}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("Admin Number")}
              </label>
              <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                {businessData?.superUser?.phoneNumber || t("No data available")}
              </p>
            </div>
          </div>
        </div>

        {isSuperUser && (
          <div className="flex justify-center mt-4 space-x-4">
            <Button
              onClick={handleEdit}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              {isEditing ? t("savelbtn") : t("edit")}
            </Button>

            <Button
              onClick={Upload}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              {t("Upload Certificate")}
            </Button>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default CompanyInfo;
