import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setIsOpen(false); // Close the dropdown after selecting a language
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const languages = [
    { code: "en", label: "English", flag: "US" },
    { code: "alb", label: "Shqip", flag: "AL" },
  ];

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div className="cursor-pointer" onClick={toggleDropdown}>
        <img
          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${
            i18n.language === "alb" ? "AL" : "US"
          }.svg`}
          alt={i18n.language === "alb" ? "Albanian Flag" : "American Flag"}
          className="w-6 h-6"
        />
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {languages.map((language) => (
              <button
                key={language.code}
                onClick={() => changeLanguage(language.code)}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                <img
                  src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${language.flag}.svg`}
                  alt={`${language.label} Flag`}
                  className="inline-block w-4 h-4 mr-2"
                />
                {language.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
