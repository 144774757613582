import React from "react";
import { useTranslation } from "react-i18next";
import {
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@/components/ui/accordion";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

interface SubItem {
  label: string;
  subItems?: SubItem[];
  route?: string;
}

interface AccordionMenuItemProps {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>> | null;
  label: string;
  subItems: SubItem[];
  onPlusClick?: (subItem: string, parentLabel: string) => void;
  onSubItemClick?: (route: string) => void; // New prop for handling sub-item click
}

const AccordionMenuItem: React.FC<AccordionMenuItemProps> = ({
  icon: Icon,
  label,
  subItems,
  onPlusClick,
  onSubItemClick, // New prop for handling sub-item click
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigation = (route: string | undefined) => {
    if (route) {
      navigate(route);
      if (onSubItemClick) {
        onSubItemClick(route); // Trigger the onSubItemClick callback
      }
    }
  };

  return (
    <AccordionItem value={label.toLowerCase()}>
      <AccordionTrigger
        className="flex justify-between items-center pr-4 py-2 hover:bg-gray-700 cursor-pointer"
        onClick={() =>
          handleNavigation(subItems.length ? undefined : "/ProductsList")
        }
      >
        <div className="flex items-center">
          {Icon && <Icon className="h-5 w-5 mr-2" />}
          <span>{label}</span>
        </div>
      </AccordionTrigger>
      <AccordionContent className="ml-4 mt-2">
        {subItems.map((subItem, index) => (
          <div key={index}>
            {subItem.subItems ? (
              <AccordionMenuItem
                icon={null}
                label={subItem.label}
                subItems={subItem.subItems}
                onPlusClick={onPlusClick}
                onSubItemClick={onSubItemClick} // Pass the callback down recursively
              />
            ) : (
              <div
                className="relative group py-1 hover:bg-gray-600 cursor-pointer"
                onClick={() => handleNavigation(subItem.route)}
              >
                {subItem.label}

                {/* Display Plus button conditionally */}
                {onPlusClick &&
                  ((label === t("employee") &&
                    subItem.label === t("employee")) ||
                    (label === t("employee") && subItem.label === t("role")) ||
                    (label === t("customermanagement") &&
                      subItem.label === t("customers")) ||
                    (label === t("products") &&
                      subItem.label === t("productlist")) ||
                    (label === t("products") &&
                      subItem.label === t("categorymanagement")) ||
                    (label === t("products") &&
                      subItem.label === t("productcomposite")) ||
                    (label === t("products") && subItem.label === t("uom")) ||
                    (label === t("settings") && subItem.label === t("vat")) ||
                    (label === t("customermanagement") &&
                      subItem.label === t("customerscategory")) ||
                    (label === t("suppliers") && subItem.label === t("lot")) ||
                    (label === t("purchaseorder") &&
                      subItem.label === t("inventory")) ||
                    (label === t("settings") && subItem.label === t("shop")) ||
                    (label === t("settings") &&
                      subItem.label === t("warehouse")) ||
                    (label === t("finacialmanagment") &&
                      subItem.label === t("payment methods")) ||
                    (label === t("finacialmanagment") &&
                      subItem.label === t("payment terms")) ||
                    (label === t("finacialmanagment") &&
                      subItem.label === t("Currency")) ||
                    (label === t("finacialmanagment") &&
                      subItem.label === t("/cashaccountlist")) ||
                    (label === t("finacialmanagment") &&
                      subItem.label === t("/cashaccountownerlist")) ||
                    (label === t("finacialmanagment") &&
                      subItem.label === t("exchangerate")) ||
                    (label === t("suppliers") &&
                      subItem.label === t("suppliers"))) && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onPlusClick(subItem.label, label);
                      }}
                      className="absolute right-0 top-0 mt-1 mr-4 h-5 w-5 text-gray-300 opacity-0 group-hover:opacity-100 transition-opacity focus:outline-none hidden md:hidden lg:block"
                    >
                      <PlusCircleIcon className="h-5 w-5" />
                    </button>
                  )}
              </div>
            )}
          </div>
        ))}
      </AccordionContent>
    </AccordionItem>
  );
};

export default AccordionMenuItem;
