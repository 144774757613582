import React, { useEffect, useState } from "react";
import "@/css/thermalPrint.css";
import API_URLS from "@/config";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/provider/authProvider";
import { useNavigate } from "react-router-dom";
import DataFetcher from "@/provider/DataFetcher";
import QRCode from "react-qr-code";

interface CompanyData {
  name: string;
  tin: string;
  id: number;
  employees: number;
  additionalInfo: string;
  city: string;
  country: string;
  phoneNumber: string;
  email: string;
  fieldOfActivity: string;
  fiscalizationCertificate: string;
  isSeasonal: boolean;
  subscriptionStartDateUtc: string;
  subscriptionEndDateUtc: string;
}

interface Employee {
  id: string;
  name: string;
}

// interface ExchangeRate {
//   currency1Id: number;
//   currency2Id: number;
//   rate: number;
// }
interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}
interface Currency {
  id: number;
  name: string;
  symbol: string;
}

const decodeJWT = (token: string) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join("")
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Failed to decode token", error);
    return null;
  }
};

interface Props {
  orderProducts: {
    product: {
      id: string;
      name: string;
      price: number;
      uoMId: number;
      barcode: string;
    };
    quantity: number;
    discount?: number;
    discountType?: "value" | "percentage";
  }[];
  customers: { id: string | number; name: string }[];
  selectedCustomer: string;
  calculateSubtotalAndDiscount: () => {
    subtotal: string;
    totalOrderDiscount: string;
    totalProductDiscounts: string;
    totalDiscountSum: string;
  };
  calculateVAT: () => string;
  calculateTotal: () => string;
  getVATRateName: (vatRateId: number) => string;
  getVATRate: (vatRateId: number) => number;
  selectedCurrencyDetails: { id: number; name: string; symbol: string } | null;
  totalInSelectedCurrency: string;
  employeeName: string;
  orderNo: string | undefined;
  date: string | number | boolean;
  tableId: string | undefined;
  tablename: string | undefined;
  exchangeRate: number | null;
  printerType: number;
}

const InvoiceThermalPrint = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      orderProducts,
      customers,
      selectedCustomer,
      calculateSubtotalAndDiscount,
      calculateVAT,
      calculateTotal,
      selectedCurrencyDetails,
      orderNo,
      date,
      tablename,
      exchangeRate,
      printerType,
    } = props;

    const EMPLOYEE_STORAGE_KEY = "employeeSalesData";
    const Currency_STORAGE_KEY = "currencyData";

    const { subtotal, totalOrderDiscount, totalProductDiscounts } =
      calculateSubtotalAndDiscount();
    const [companyData, setCompanyData] = useState<CompanyData | null>(null);
    // const [exchangeRate, setExchangeRate] = useState<number | null>(null);
    const [employee, setEmployee] = useState<Employee | null>(null);
    const [finalPrinterType, setFinalPrinterType] = useState<number>(() => {
      const storedPrinterType = JSON.parse(
        localStorage.getItem("businessConfigs") || "{}"
      )?.printerType;
      return printerType || storedPrinterType || 1;
    });
    const { setToken, token, employeeId } = useAuth();
    const [baseCurrencyName, setBaseCurrencyName] = useState("Lekë"); // Default currency
    const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L"); // Default currency symbol
    const [, setCurrencies] = useState<Currency[]>([]); // Holds list of currencies
    const navigate = useNavigate();
    const { t } = useTranslation();
    const customerName = customers.find(
      (c) => c.id.toString() === selectedCustomer
    )?.name;
    useEffect(() => {
      if (printerType !== undefined) {
        setFinalPrinterType(printerType);
      }
    }, [printerType]);

    const currencyDetails =
      selectedCurrencyDetails ||
      JSON.parse(localStorage.getItem("selectedCurrencyDetails") || "{}");
    useEffect(() => {
      const fetchCompanyData = async () => {
        try {
          // Fetch the list of companies from the API
          const response = await DataFetcher.getData<CompanyData[]>(
            `${API_URLS.BASE_URL}${API_URLS.BUSINESSREGISTER}`,
            "businessData"
          );

          if (response && response.length > 0) {
            // Decode the token to get the tenantId
            if (token) {
              const decodedToken = decodeJWT(token);
              if (decodedToken && decodedToken.tenantId) {
                const tenantId = Number(decodedToken.tenantId); // Ensure tenantId is a number

                // Find the company data that matches the tenantId
                const matchedCompany = response.find(
                  (company) => company.id === tenantId
                );

                if (matchedCompany) {
                  setCompanyData(matchedCompany);
                } else {
                  console.error(
                    "No matching company data found for tenantId:",
                    tenantId
                  );
                  // Handle the case where no matching company is found
                  setCompanyData(null); // Or any other default value or error handling
                }
              } else {
                console.error("Invalid token data");
                // Handle invalid token data
                setCompanyData(null); // Or any other default value or error handling
              }
            } else {
              console.error("Token is null or undefined");
              // Handle the case where the token is not available
              setCompanyData(null); // Or any other default value or error handling
            }
          } else {
            console.error("No company data found");
            // Handle the case where no company data is returned
            setCompanyData(null); // Or any other default value or error handling
          }
        } catch (error) {
          console.error("Error fetching company data:", error);
          // Handle any errors during the fetch
          setCompanyData(null); // Or any other default value or error handling
        }
      };
      const fetchEmployeeData = async () => {
        const employeeData = await DataFetcher.getData<Employee>(
          `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}/${employeeId}`,
          EMPLOYEE_STORAGE_KEY
        );

        if (employeeData) {
          setEmployee(employeeData);
        } else {
          throw new Error("Failed to fetch employee data");
        }
      };

      fetchCompanyData();
      fetchEmployeeData();
    }, [token, setToken, navigate, employeeId]);

    const calculateTotalInSelectedCurrency = () => {
      const totalInLek = parseFloat(calculateTotal());
      if (exchangeRate) {
        return (totalInLek / exchangeRate).toFixed(2);
      }
      return "N/A";
    };

    useEffect(() => {
      // Fetch available currencies using DataFetcher with caching
      const fetchCurrencyData = async () => {
        try {
          const response = await DataFetcher.getData<Currency[]>(
            `${API_URLS.BASE_URL}${API_URLS.Currency}`, // API endpoint
            Currency_STORAGE_KEY // LocalStorage key
          );
          setCurrencies(response || []); // Set fetched currencies, default to an empty array if none

          // Find and set base currency name and symbol
          const businessConfigs: BusinessConfigs = JSON.parse(
            localStorage.getItem("businessConfigs") || "{}"
          );
          const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1 if not set
          const baseCurrency = response?.find(
            (currency) => currency.id === baseCurrencyId
          );

          setBaseCurrencyName(baseCurrency?.name || "Leke"); // Set base currency name, default to "Leke"
          setBaseCurrencySymbol(baseCurrency?.symbol || "L"); // Set base currency symbol, default to "L"
        } catch (error) {
          console.error("Error fetching currencies:", error);
        }
      };

      fetchCurrencyData();
    }, [t]);

    const qrString = `Order No: ${orderNo}, Customer: ${customerName}, Total: ${calculateTotal()} L`;
    const invoiceClass =
      finalPrinterType === 1 ? "invoice-print-a4" : "invoice-print-thermal";
    return (
      <div ref={ref} className={invoiceClass}>
        {finalPrinterType === 1 ? (
          <>
            {/* A4 Invoice Layout */}
            <div className="invoice-header-a4">
              <div className="company-details-a4">
                <h2>{companyData ? companyData.name : t("companyName")}</h2>
                <p>{companyData ? companyData.tin : t("tin")}</p>
                <p>
                  {companyData ? companyData.city : t("AdrressCity")},{" "}
                  {companyData ? companyData.country : t("AdrressCountry")}
                </p>
                <p>{companyData ? companyData.email : t("email")}</p>
                <p>
                  {companyData ? companyData.phoneNumber : t("phoneNumber")}
                </p>
              </div>
              <div className="invoice-details-a4">
                <p>
                  {t("INVOICENo")} {orderNo}
                </p>
                <p>{new Date().toLocaleDateString() ? date : t("Date")}</p>
              </div>
            </div>

            <div className="supplier-details-a4">
              <p>
                {t("employeeName")}: {employee ? employee.name : t("loading")}
              </p>
            </div>

            <table className="invoice-items-a4">
              <thead>
                <tr>
                  <th>{t("ITEM")}</th>
                  <th>{t("UNITCOST")}</th>
                  <th>{t("QUANTITY")}</th>
                  <th>{t("DISCOUNT")}</th>
                  <th>{t("TOTAL")}</th>
                  {/* <th>{t("TAX")}</th> */}
                </tr>
              </thead>
              <tbody>
                {orderProducts.map(
                  ({ product, quantity, discount, discountType }) => (
                    <tr key={product.id}>
                      <td className="invoice-td">{product.name}</td>
                      <td className="invoice-td">
                        {product.price.toFixed(2)}
                        {baseCurrencySymbol}
                      </td>
                      <td className="invoice-td">{quantity}</td>
                      <td className="invoice-td">
                        {discount !== undefined
                          ? `${discount} ${
                              discountType === "percentage"
                                ? "%"
                                : baseCurrencySymbol
                            }`
                          : "0 " + baseCurrencySymbol}
                      </td>
                      <td className="invoice-td">
                        {discountType === "percentage"
                          ? (
                              product.price *
                              quantity *
                              (1 - (discount || 0) / 100)
                            ).toFixed(2)
                          : (
                              product.price * quantity -
                              (discount || 0)
                            ).toFixed(2)}
                        {baseCurrencySymbol}
                      </td>
                      {/* <td className="invoice-td">
                        {(product.price * quantity).toFixed(2)}
                        {currencySymbol}
                      </td> */}
                    </tr>
                  )
                )}
              </tbody>
            </table>

            <table className="invoice-totals-a4">
              <tbody>
                {parseFloat(totalProductDiscounts) > 0 && (
                  <tr>
                    <th>{t("INLINE DISCOUNT")}</th>
                    <td>
                      {totalProductDiscounts}
                      {baseCurrencySymbol}
                    </td>
                  </tr>
                )}

                {parseFloat(totalOrderDiscount) > 0 && (
                  <tr>
                    <th>{t("DISCOUNT FROM TOTAL PRICE")}</th>
                    <td>
                      {totalOrderDiscount}
                      {baseCurrencySymbol}
                    </td>
                  </tr>
                )}

                {parseFloat(calculateSubtotalAndDiscount().totalDiscountSum) >
                  0 && (
                  <tr>
                    <th>{t("TOTAL DISCOUNT")}</th>
                    <td>
                      {calculateSubtotalAndDiscount().totalDiscountSum}
                      {baseCurrencySymbol}
                    </td>
                  </tr>
                )}
                <tr>
                  <th>{t("Subtotal")}:</th>
                  <td>
                    {subtotal} {baseCurrencySymbol}
                  </td>
                </tr>
                <tr>
                  <th>{t("VAT")}:</th>
                  <td>
                    {calculateVAT()} {baseCurrencySymbol}
                  </td>
                </tr>
                <tr>
                  <th>
                    {t("TOTALlek")} {baseCurrencyName}:
                  </th>
                  <td>
                    {calculateTotal()} {baseCurrencySymbol}
                  </td>
                </tr>
                {currencyDetails.name !== "Lekë" && (
                  <tr>
                    <th>{t("ExchangeRate")}</th>
                    <td>{exchangeRate}</td>
                  </tr>
                )}
                {currencyDetails.name !== "Lekë" && (
                  <tr>
                    <th>
                      {t("TOTAL")} in {currencyDetails.name}
                    </th>
                    <td>
                      {calculateTotalInSelectedCurrency()}{" "}
                      {currencyDetails.symbol}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="qr-code-section-a4">
              <QRCode value={qrString} size={128} />
            </div>

            <div className="invoice-footer-a4">
              <p>{t("Thank You")}</p>
              <p>{companyData ? companyData.name : t("Company Name")}</p>
            </div>
          </>
        ) : (
          <>
            <div className="invoice-header-thermal">
              <div className="company-details-thermal">
                <h2>{companyData ? companyData.name : t("companyName")}</h2>
                <p>{companyData ? companyData.tin : t("tin")}</p>
                <p>
                  {companyData ? companyData.city : t("AdrressCity")},
                  {companyData ? companyData.country : t("AdrressCountry")}
                </p>
                <p>{companyData ? companyData.email : t("email")}</p>
                <p>
                  {companyData ? companyData.phoneNumber : t("phoneNumber")}
                </p>
              </div>
              <div className="invoice-details-thermal">
                <p>
                  {t("INVOICENo")}: {orderNo}
                </p>
                <p>{new Date().toLocaleDateString() ? date : t("Date")}</p>
              </div>
            </div>
            <div className="customer-details-thermal">
              <p>
                {t("customer")}: {customerName}
              </p>
              {tablename &&
                tablename !== "Unknown Table" && ( // Hide if tablename is null, undefined, or equals "Unknown Table"
                  <p>
                    {t("Table")}: {tablename}
                  </p>
                )}
              <p></p>
              <p>
                {t("employeeName")}: {employee ? employee.name : t("loading")}
              </p>
            </div>
            <div className="invoice-items-thermal">
              <div className="invoice-item-thermal header">
                <span>{t("ITEMSales")}</span>
                <span>{t("QUANTITY")}</span>
                <span className="pr-2">{t("UNITCOST")}</span>
                <span>{t("TOTAL")}</span>
              </div>
              {orderProducts.map(
                ({ product, quantity, discount, discountType }) => (
                  <React.Fragment key={product.id}>
                    <div className="invoice-item-thermal w-full">
                      <span className="w-1/4 pl-20 text-right">{quantity}</span>
                      <span className="w-1/4 text-right pl-5">
                        {product.price.toFixed(2)}
                        {baseCurrencySymbol}
                      </span>
                      <span className="w-1/4 text-right">
                        {(product.price * quantity).toFixed(2)}
                        {baseCurrencySymbol}
                      </span>
                    </div>
                    <div className="invoice-product-discount flex w-full">
                      <span className="w-1/2 product-name text-left">
                        {product.name}
                      </span>
                      {discount && discount > 0 && (
                        <div className="invoice-item-thermal  w-1/2">
                          <span>
                            -
                            {discount !== undefined
                              ? `${discount} ${
                                  discountType === "percentage"
                                    ? "%"
                                    : baseCurrencySymbol
                                }`
                              : "0"}
                          </span>
                          <span className="pl-12">
                            {discountType === "percentage"
                              ? (
                                  product.price *
                                  quantity *
                                  (1 - (discount || 0) / 100)
                                ).toFixed(2)
                              : (
                                  product.price * quantity -
                                  (discount || 0)
                                ).toFixed(2)}
                            {baseCurrencySymbol}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="separator-line"></div>
                  </React.Fragment>
                )
              )}
            </div>

            <div className="invoice-totals-thermal">
              <div className="totals-row-thermal">
                <span>{t("Subtotal")}</span>
                <span>
                  {subtotal}
                  {baseCurrencySymbol}
                </span>
              </div>
              {parseFloat(totalProductDiscounts) > 0 && (
                <div className="totals-row-thermal">
                  <span>{t("INLINE DISCOUNT")}</span>
                  <span>
                    {totalProductDiscounts}
                    {baseCurrencySymbol}
                  </span>
                </div>
              )}

              {parseFloat(totalOrderDiscount) > 0 && (
                <div className="totals-row-thermal">
                  <span>{t("DISCOUNT FROM TOTAL PRICE")}</span>
                  <span>
                    {totalOrderDiscount}
                    {baseCurrencySymbol}
                  </span>
                </div>
              )}

              {parseFloat(calculateSubtotalAndDiscount().totalDiscountSum) >
                0 && (
                <div className="totals-row-thermal">
                  <span>{t("TOTAL DISCOUNT")}</span>
                  <span>
                    {calculateSubtotalAndDiscount().totalDiscountSum}
                    {baseCurrencySymbol}
                  </span>
                </div>
              )}

              <div className="totals-row-thermal">
                <span>{t("VAT")}</span>
                <span>
                  {calculateVAT()}
                  {baseCurrencySymbol}
                </span>
              </div>
              <div className="totals-row-thermal">
                <span>
                  <strong>
                    {t("TOTALlek")} {baseCurrencyName}
                  </strong>
                </span>
                <span>
                  <strong>
                    {calculateTotal()}
                    {baseCurrencySymbol}
                  </strong>
                </span>
              </div>
              {currencyDetails.name !== "Lekë" && (
                <div className="totals-row-thermal">
                  <span>{t("ExchangeRate")}</span>
                  <span>{exchangeRate}</span>
                </div>
              )}
              {currencyDetails.name !== "Lekë" && (
                <div className="totals-row-thermal">
                  <span>
                    <strong>
                      {t("TOTAL")} in {currencyDetails.name}
                    </strong>
                  </span>
                  <span>
                    <strong>
                      {calculateTotalInSelectedCurrency()}{" "}
                      {currencyDetails.symbol}
                    </strong>
                  </span>
                </div>
              )}
            </div>
            <div className="qr-code-section">
              <QRCode value={qrString} size={128} />
            </div>
            <div className="invoice-footer-thermal">
              <p>{t("Thank You")}</p>
              <p>{companyData ? companyData.name : t("Company Name")}</p>
            </div>
          </>
        )}
      </div>
    );
  }
);

InvoiceThermalPrint.displayName = "InvoiceThermalPrint";

export default InvoiceThermalPrint;
