const API_URLS = {
  // BASE_URL: "http://sad1.ivaelektronik.com:8081",
  // BASE_URL: "https://lrhk187t-5033.euw.devtunnels.ms",
  // BASE_URL: "http://dev.iva.al:8081",
  // BASE_URL: "http://test1.iva.al:8081",
  BASE_URL: "http://ivadev.iva.al:8080",

  CUSTOMER: "/api/Customers",
  CUSTOMER_CATEGORY: "/api/CustomerCategory",
  EMPLOYEE: "/api/Employee",
  PRODUCT_CATEGORY: "/api/ProductCategory",
  PRODUCT: "/api/Products",
  SUPPLIER: "/api/Supplier",
  ROLE: "/api/Role",
  UOM: "/api/UoM",
  VAT: "/api/VatRate",
  SALESORDER: "/api/SalesOrders",
  PURCHASEORDER: "/api/PurchaseOrder",
  LOT: "/api/Lot",
  LOGIN: "/api/Login",
  INVENTORY: "/api/Inventory",
  SHOP: "/api/Shop",
  PaymentMethod: "/api/PaymentMethod",
  WAREHOUSE: "/api/Warehouse",
  PaymentTerms: "/api/PaymentTerms",
  Currency: "/api/Currency",
  CashAccount: "/api/CashAccount",
  Tables: "/api/Tables",
  CashAccountOwner: "/api/CashAccountOwner",
  CompositeProduct: "/api/CompositeProduct",
  ExchangeRates: "/api/ExchangeRates",
  BUSINESSREGISTER: "/api/Business",
  ChangePassword: "/api/ChangePassword",
  LOGGEDBUSINESS: "/api/Business/logged-business",
  ResetPassword: "/api/ResetPassword",
  PERMISSION: "/api/Permission",
  CASHLEGDER: "/api/CashLedger",
  ACCOUNTTRANSFER: "/api/AccountTransfer",
  BusinessConfigs: "/api/BusinessConfigs",
  SendCode: "/api/Verify/Send-Code",
  VerifyEmail: "/api/Verify/Verify-Email",
  UploadCert: "/api/Business/UploadCert",
  idBySubdomain: "/api/Business/idBySubdomain",
TabelOrder:"/api/TableOrder",
CloseTabel:"/api/TableOrder/CloseTable",
ResendVerificationEmail:"/api/Verify/Resend-Verification-Email",
  forgetUsername: "/api/Business/supersuer-username",
  forgetPassword: "/api/Employee/forgot-password",
  resetPassword: "/api/Employee/reset-password",
  Dashboard: "/api/DashboardData",
};

export default API_URLS;
