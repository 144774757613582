import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useNavigate, useLocation } from "react-router-dom";
import "@/css/MobileScreen.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URLS from "@/config";
import { CompositeProduct } from "@/types/CompositeProductType";
import CreateCompositeProductsDto from "@/models/CreateCompositeProductsDto";
import { Product } from "@/interfaces/ProductInterfaces";
import { CompositeProductFormProps } from "@/interfaces/CompositeProductInterfaces";
import DataFetcher from "@/provider/DataFetcher";

import "@/css/LoadingCreate.css";


const PRODUCT_STORAGE_KEY = "productData";
const CompositeProduct_STORAGE_KEY = "compositeproductData";

const CompositeProductForm: React.FC<CompositeProductFormProps> = ({
  mode,
}) => {

  const { t } = useTranslation();


  const formSchema = z.object({
    productId: z.string().min(1, { message: t("Product is required") }),
    compositeId: z.string().min(1, { message: t("Composite Product is required") }),
    quantity: z.number().min(1, { message: t("Quantity is required") }),
  });
  
  type FormValues = z.infer<typeof formSchema>;
  const [loading, setLoading] = useState(false); // Initialize loading state


  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {
    id: localStorage.getItem("compositeproductId"),
  };
  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      productId: "",
      compositeId: "",
      quantity: 0,
    },
  });


  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await DataFetcher.getData<Product[]>(
        `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`,
        PRODUCT_STORAGE_KEY
      );
      if (response) {
        setProducts(response);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    if (id) {
      localStorage.setItem("compositeproductId", id);
    }

    if (mode === "edit" && id) {
      const fetchCompositeProduct = async () => {
        try {
          const response = await DataFetcher.getData<CompositeProduct | null>(
            `${API_URLS.BASE_URL}${API_URLS.CompositeProduct}/${id}`,
            CompositeProduct_STORAGE_KEY
          );

          if (response) {
            methods.reset({
              productId: response.productId.toString(),
              compositeId: response.compositeId.toString(),
              quantity: response.quantity,
            });
          } else {
            console.error("Composite Product data is null.");
            toast.error("Failed to fetch composite product details");
          }
        } catch (error) {
          console.error("Error fetching composite product details:", error);
          toast.error("Failed to fetch composite product details");
        }
      };

      fetchCompositeProduct();
    }
  }, [id, mode, methods]);

  const updateLocalStorage = (updatedCompositeProduct: CompositeProduct) => {
    const existingCompositeProduct = JSON.parse(
      localStorage.getItem("compositeproductData") || "[]"
    );
    const updatedCompositeProducts = Array.isArray(existingCompositeProduct)
      ? existingCompositeProduct.map((product) =>
          product.id === updatedCompositeProduct.id
            ? updatedCompositeProduct
            : product
        )
      : [updatedCompositeProduct];
    localStorage.setItem(
      "compositeproductData",
      JSON.stringify(updatedCompositeProducts)
    );
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLoading(true); // Show loading spinner

    const compositeproductData = new CreateCompositeProductsDto({
      productId: parseInt(data.productId),
      compositeId: parseInt(data.compositeId),
      quantity: data.quantity,
    });
  
    try {
      let successMessage = "";
  
      if (mode === "create") {
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.CompositeProduct}`,
          compositeproductData
        );
        console.log("Composite Product created successfully", response.data);
        successMessage = t("Composite Product created successfully");
  
        const existingCompositeProduct = JSON.parse(
          localStorage.getItem("compositeproductData") || "[]"
        );
        existingCompositeProduct.push(response.data);
        localStorage.setItem(
          "compositeproductData",
          JSON.stringify(existingCompositeProduct)
        );
      } else if (mode === "edit" && id) {
        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.CompositeProduct}/${id}`,
          compositeproductData
        );
        console.log("Composite Product updated successfully:", response.data);
        successMessage = t("Composite Product updated successfully");
  
        updateLocalStorage(response.data);
      }
  
      methods.reset();
      localStorage.removeItem("compositeproductId");
      navigate(t("/productcompositelistpath"));
  
      // Show toast after navigation
      setTimeout(() => {
        if (successMessage) {
          toast.success(successMessage);
        }
      }, 250);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status, data } = error.response;
  
        if (status === 403) {
          toast.error(t("You do not have permission to perform this action."));
        } else if (status === 500) {
          toast.error(t("A server error occurred. Please try again later."));
        } else if (data && Array.isArray(data)) {
          data.forEach((err: { errorMessage: string }) => {
            const translatedMessage =
              t(`errorCompositeProduct.${err.errorMessage}`) ||
              t("errorCompositeProduct.An error occurred");
            toast.error(translatedMessage);
          });
        } else {
          toast.error(t("An error occurred"));
        }
      } else {
        toast.error(t("An unexpected error occurred"));
      }
      console.error("Error submitting Composite Product:", error);
    } 
    finally {
      setLoading(false); // Hide loading spinner
    }
  };
  
  

  return (
    <FormProvider {...methods}>
      <div className="flex justify-center items-center">
      {loading && (
          <div className="loader-overlay">
            <div className="loader"></div>
          </div>
        )}
        <div className="w-full max-w-7xl p-6 rounded shadow-md">
          <h1 className="text-xl font-bold mb-6">
            {mode === "create"
              ? t("/createproductcomposite")
              : t("/editproductcomposite")}
          </h1>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-2 gap-6">
              <FormItem>
                <FormLabel>{t("product")}<span style={{ color: "red" }}>*</span></FormLabel>
                <FormControl>
                  <Controller
                    name="productId"
                    control={methods.control}
                    render={({ field }) => (
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder={t("selectProduct")} />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>{t("product")}</SelectLabel>
                            {products.map((productId) => (
                              <SelectItem
                                key={productId.id}
                                value={productId.id.toString()}
                              >
                                {productId.name}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    )}
                  />
                </FormControl>
                {methods.formState.errors.productId && (
                  <FormMessage>
                    {methods.formState.errors.productId.message}
                  </FormMessage>
                )}
              </FormItem>
              <FormItem>
                <FormLabel>{t("composite")}<span style={{ color: "red" }}>*</span></FormLabel>
                <FormControl>
                  <Controller
                    name="compositeId"
                    control={methods.control}
                    render={({ field }) => (
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder={t("selectProduct")} />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>{t("product")}</SelectLabel>
                            {products.map((compositeId) => (
                              <SelectItem
                                key={compositeId.id}
                                value={compositeId.id.toString()}
                              >
                                {compositeId.name}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    )}
                  />
                </FormControl>
                {methods.formState.errors.compositeId && (
                  <FormMessage>
                    {methods.formState.errors.compositeId.message}
                  </FormMessage>
                )}
              </FormItem>
              <FormItem>
                <FormLabel>{t("quantity")}<span style={{ color: "red" }}>*</span></FormLabel>
                <FormControl>
                  <Input
                    {...methods.register("quantity", { valueAsNumber: true })}
                    type="number"
                    placeholder={t("putQuantity")}
                    className="w-full"
                  />
                </FormControl>
                {methods.formState.errors.quantity && (
                  <FormMessage>
                    {methods.formState.errors.quantity.message}
                  </FormMessage>
                )}
              </FormItem>
            </div>
            <div className="flex justify-center gap-4 mt-6">
              <Button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                {t("savelbtn")}
              </Button>
              <Button
                type="button"
                onClick={() => navigate(t("/productcompositelistpath"))}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                {t("canclelbtn")}
              </Button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </FormProvider>
  );
};

export default CompositeProductForm;
