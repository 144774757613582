class CreateCurrencyDto {
  name: string;
  symbol: string;
  code:string

  constructor(currency: { name?: string; symbol?: string ; code:string}) {
    this.name = currency.name || "";
    this.symbol = currency.symbol || "";
    this.code=currency.code || "";
  }
}

export default CreateCurrencyDto;
