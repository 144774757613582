class UploadCertifikateDto {
  CertPassword: string;
  BusinessSubdomain: string;
  Certificate: File | null; // Certificate as a file

  constructor(Upload: {
    CertPassword: string;
    BusinessSubdomain: string;
    Certificate: File | null;
  }) {
    this.CertPassword = Upload.CertPassword ?? "";
    this.BusinessSubdomain = Upload.BusinessSubdomain ?? "";
    this.CertPassword = Upload.CertPassword ?? "";
    this.Certificate = Upload.Certificate; // Expecting File or null
  }


}

export default UploadCertifikateDto;
