export class CreateExchangeRateDto {
  currency1Id?: number | null;
  currency2Id?: number | null;
  rate: number;

  constructor(createexchangerate: {
    currency1Id?: number | null;
    currency2Id?: number | null;
    rate: number;
  }) {
    this.currency1Id = createexchangerate.currency1Id ?? null;
    this.currency2Id = createexchangerate.currency2Id ?? null;
    this.rate = createexchangerate.rate;
  }
}

export default CreateExchangeRateDto;
