import React, { createContext, useContext, useState } from "react";
import { Product } from "@/types/ProductType";

// Define a shared type for products in orders
interface OrderProduct {
  product: Product;
  quantity: number;
  discount?: number;
  discountType?: "value" | "percentage";
  isEditing?: boolean;
  isAddingDiscount?: boolean;
}
interface FastOrderProduct {
  id: string;
  name: string;
  barcode: string;
  price: number;
  uoMId: number;
  vatRateId: number;
  categoryId: string;
  quantity: number;
  vatRate: number;
  isRawMaterial: boolean;
}

// Sales Order context type
interface SalesOrderProductsContextProps {
  salesOrderProducts: OrderProduct[];
  setSalesOrderProducts: React.Dispatch<React.SetStateAction<OrderProduct[]>>;
}

// Purchase Order context type
interface PurchaseOrderProductsContextProps {
  purchaseOrderProducts: OrderProduct[];
  setPurchaseOrderProducts: React.Dispatch<
    React.SetStateAction<OrderProduct[]>
  >;
}

interface FastSaleProductsContextProps {
  fastSaleProducts: FastOrderProduct[];
  setFastSaleProducts: React.Dispatch<React.SetStateAction<FastOrderProduct[]>>;
}

interface PosBarProductsContextProps {
  posBarProducts: FastOrderProduct[];
  setPosBarProducts: React.Dispatch<React.SetStateAction<FastOrderProduct[]>>;
}

// Create context for Sales Orders
const SalesOrderProductsContext = createContext<SalesOrderProductsContextProps>(
  {
    salesOrderProducts: [],
    setSalesOrderProducts: () => [],
  }
);

// Create context for Purchase Orders
const PurchaseOrderProductsContext =
  createContext<PurchaseOrderProductsContextProps>({
    purchaseOrderProducts: [],
    setPurchaseOrderProducts: () => [],
  });

const FastSaleProductsContext = createContext<FastSaleProductsContextProps>({
  fastSaleProducts: [],
  setFastSaleProducts: () => [],
});

const PosBarProductsContext = createContext<PosBarProductsContextProps>({
  posBarProducts: [],
  setPosBarProducts: () => [],
});
// Custom hooks to use the respective contexts
export const useSalesOrderProducts = () =>
  useContext(SalesOrderProductsContext);
export const usePurchaseOrderProducts = () =>
  useContext(PurchaseOrderProductsContext);
export const useFastSaleProducts = () => useContext(FastSaleProductsContext);
export const usePosBarProducts = () => useContext(PosBarProductsContext);
// Combined Provider component
export const SelectedProductsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // State for Sales Orders
  const [salesOrderProducts, setSalesOrderProducts] = useState<OrderProduct[]>(
    []
  );

  // State for Purchase Orders
  const [purchaseOrderProducts, setPurchaseOrderProducts] = useState<
    OrderProduct[]
  >([]);

  const [fastSaleProducts, setFastSaleProducts] = useState<FastOrderProduct[]>(
    []
  );

  const [posBarProducts, setPosBarProducts] = useState<FastOrderProduct[]>([]);

  return (
    <SalesOrderProductsContext.Provider
      value={{ salesOrderProducts, setSalesOrderProducts }}
    >
      <PurchaseOrderProductsContext.Provider
        value={{ purchaseOrderProducts, setPurchaseOrderProducts }}
      >
        <FastSaleProductsContext.Provider
          value={{ fastSaleProducts, setFastSaleProducts }}
        >
          <PosBarProductsContext.Provider
            value={{ posBarProducts, setPosBarProducts }}
          >
            {children}
          </PosBarProductsContext.Provider>
        </FastSaleProductsContext.Provider>
      </PurchaseOrderProductsContext.Provider>
    </SalesOrderProductsContext.Provider>
  );
};
