class CreateCategoryDto {
  parentCategoryId: number | null;
  name: string;
  description: string;
  image: string | null;

  constructor(CustomerCategory: {
    parentCategoryId?: number | null;
    name?: string;
    description?: string;
    image?: string | null;
  }) {
    this.parentCategoryId = CustomerCategory.parentCategoryId ?? null;
    this.name = CustomerCategory.name ?? "";
    this.description = CustomerCategory.description ?? "";
    this.image = CustomerCategory.image ?? null;
  }
}

export default CreateCategoryDto;
