import React, { useState } from "react";

interface SearchBarProps {
  onSearch: (query: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    onSearch(query);
  };

  return (
    <div className="p-2">
      <input
        type="search"
        value={searchQuery}
        onChange={handleChange}
        placeholder="Search..."
        className="w-full p-2 rounded bg-gray-700 text-white"
      />
    </div>
  );
};

export default SearchBar;
