import CreateProd from "@/pages/product/CreateProd";
import CreateCustomer from "@/pages/customer/CreateCustomer";
import CreateCategoryCustomer from "@/pages/customerCategory/CreateCustomerCategory";
import CreateCatgory from "@/pages/productCategory/CreateProductCategory";
import CustomerList from "@/pages/customer/CustomerList";
import ProductsList from "@/pages/product/ProductsList";
import CustomerCategoryList from "@/pages/customerCategory/CustomerCategoryList";
import ProductCategoryList from "@/pages/productCategory/ProductCategoryList";
import CreateSupplier from "@/pages/supplier/CreateSupplier";
import SupplierList from "@/pages/supplier/SupplierList";
import CreateRole from "@/pages/role/CreateRole";
import EmployeeList from "@/pages/employee/EmployeeList";
import RoleList from "@/pages/role/RoleList";
import CreateEmp from "@/pages/employee/CreateEmp";
import EditProductCategory from "@/pages/productCategory/EditProductCategory";
import EditCustomerCategory from "@/pages/customerCategory/EditCustomerCategory";
import EditProducts from "@/pages/product/EditProducts";
import EditSupplier from "@/pages/supplier/EditSupplier";
import EditCustomer from "@/pages/customer/EditCustomer";
import EditRole from "@/pages/role/EditRole";
import EditEmployee from "@/pages/employee/EditEmp";
import { TFunction } from "i18next";
import UomList from "@/pages/UOM/UomList";
import CreateUom from "@/pages/UOM/CreateUom";
import EditUom from "@/pages/UOM/EditUom";
import VatList from "@/pages/VAT/VatList";
import EditVat from "@/pages/VAT/EditVat";
import CreateVat from "@/pages/VAT/CreateVat";
import SalesOrder from "@/pages/salesOrder/salesOrder";
import LotList from "@/pages/LOT/LotList";
import CreateLot from "@/pages/LOT/CreateLot";
import EditLot from "@/pages/LOT/EditLot";
import EditInventory from "@/pages/inventory/EditInventory";
import CreateInventory from "@/pages/inventory/CreateInventory";
import InventoryList from "@/pages/inventory/InventoryList";
import CreateShop from "@/pages/shop/CreateShop";
import EditShop from "@/pages/shop/EditShop";
import ShopList from "@/pages/shop/ShopList";
import PaymentmethodsList from "@/pages/paymentmethods/PaymentMethodsList";
import CreatePaymentMethods from "@/pages/paymentmethods/Createpaymentsmethod";
import Editpaymentmethods from "@/pages/paymentmethods/Editpaymentmethods";
import CreateWarehouse from "@/pages/warehouse/CreateWarehouse";
import EditWarehouse from "@/pages/warehouse/EditWarehouse";
import WarehouseList from "@/pages/warehouse/WarehouseList";
import PaymentTermsList from "@/pages/PaymentTerms/PaymentTermsList";
import CreatePaymentTerms from "@/pages/PaymentTerms/CreatepaymentTerms";
import EditPaymentTerms from "@/pages/PaymentTerms/EditpaymentTerms";
import CurrencyList from "@/pages/currency/CurrencyList";
import CreateCurrency from "@/pages/currency/CreateCurrency";
import EditCurrency from "@/pages/currency/EditCurrency";
import CashAccountList from "@/pages/cashaccount/CashAccountList";
import CreateCashAccount from "@/pages/cashaccount/CreateCashAccount";
import EditCashAccount from "@/pages/cashaccount/EditCashAccount";
import SalesOrder2 from "@/pages/salesOrder/salesOrder2";
import PurchaseOrder from "@/pages/purchaseorder/purchaseOrder";
import PosBar from "@/pages/POSBAR/PosBar";

import PurchaseOrdeList from "@/pages/purchaseorder/purchaseOrderList";
import SalesOrdeList from "@/pages/salesOrder/salesOrderList";
import CompositeProductList from "@/pages/compositeproducts/CompositeProductList";
import CreateCompositeProduct from "@/pages/compositeproducts/CreateCompositeProduct";
import EditCompositeProduct from "@/pages/compositeproducts/EditCompositeProduct";
import ExchangeRateList from "@/pages/exchangerates/ExchangeRateList";
import CreateExchangeRate from "@/pages/exchangerates/CreateExchangeRate";
import EditExchangeRate from "@/pages/exchangerates/EditExchangeRate";
import CreateBusinessRegister from "@/pages/businessRegsiter/CreateBusinessRegister";
import EditBusinessRegister from "@/pages/businessRegsiter/EditBusinessRegister";
import ChangePasswordForm from "@/pages/password/Password";
import CashBalanceEditor from "@/componentsPos/CashBalanceEditor";
import CompanyInfo from "@/componentsPos/CompanyInfo";
import ImportEmployee from "@/componentsPos/ImportEmployee";
import ImportCustomer from "@/componentsPos/ImportCustomer";
import ImportProduct from "@/componentsPos/ImportProduct";
import ImportSuplier from "@/componentsPos/ImportSupplier";
import ImportLot from "@/componentsPos/ImportLot";
import Account from "@/componentsPos/Account";
import FastSale from "@/pages/salesOrder/FastSale";
import CashAccountTransfer from "@/componentsPos/CashAccountTransfer";
import CashLedger from "@/componentsPos/CashLedger";
import CompanyConfig from "@/componentsPos/CompanyConfig";
import CashLedgerList from "@/pages/CashLedgerList/CashLedgerList";
import Certificate from "@/componentsPos/Certificate.";
import Dashboard from "@/dashboardPos/Dashboard";

const routesConfig = (t: TFunction) => [
  { path: t("/createprodpath"), element: <CreateProd /> },
  { path: t("/listcustomerpath"), element: <CustomerList /> },
  { path: t("/prodlistpath"), element: <ProductsList /> },
  { path: t("/createcustomerpath"), element: <CreateCustomer /> },
  {
    path: t("/createcustomercategorypath"),
    element: <CreateCategoryCustomer />,
  },
  { path: t("/createcproductcategorypath"), element: <CreateCatgory /> },
  { path: t("/categorylsitcustomer"), element: <CustomerCategoryList /> },
  { path: t("/categorylsitproduct"), element: <ProductCategoryList /> },
  { path: t("/createsupplierpath"), element: <CreateSupplier /> },
  { path: t("/supplierlistpath"), element: <SupplierList /> },
  { path: t("/uomlistpath"), element: <UomList /> },
  { path: t("/vatlistpath"), element: <VatList /> },
  { path: t("/editvatlistpath"), element: <EditVat /> },
  { path: t("/createvatlistpath"), element: <CreateVat /> },
  { path: t("/lotlistpath"), element: <LotList /> },
  { path: t("/createlotpath"), element: <CreateLot /> },
  { path: t("/editlotpath"), element: <EditLot /> },
  { path: t("/createruompath"), element: <CreateUom /> },
  { path: t("/editruompath"), element: <EditUom /> },
  { path: t("/paymentlistpath"), element: <PaymentmethodsList /> },
  { path: t("/createpaymentmethodpath"), element: <CreatePaymentMethods /> },
  { path: t("/editpaymentmethodpath"), element: <Editpaymentmethods /> },
  { path: t("/paymentTermslistpath"), element: <PaymentTermsList /> },
  { path: t("/createpaymentTerms"), element: <CreatePaymentTerms /> },
  { path: t("/editpaymentTerms"), element: <EditPaymentTerms /> },
  { path: t("/currencylistpath"), element: <CurrencyList /> },
  { path: t("/createcurrencypath"), element: <CreateCurrency /> },
  { path: t("/editcurrencypath"), element: <EditCurrency /> },
  { path: t("/cashaccountlistpath"), element: <CashAccountList /> },
  { path: t("/createcashaccount"), element: <CreateCashAccount /> },
  { path: t("/editcashaccount"), element: <EditCashAccount /> },
  { path: t("/salesorderlistpath"), element: <SalesOrdeList /> },
  { path: t("/createrolepath"), element: <CreateRole /> },
  { path: t("/emplistpath"), element: <EmployeeList /> },
  { path: t("/rolelistpath"), element: <RoleList /> },
  { path: t("/createemppath"), element: <CreateEmp /> },
  { path: t("/editcategoryproductpath"), element: <EditProductCategory /> },
  { path: t("/editcustomatorcategorypath"), element: <EditCustomerCategory /> },
  { path: t("/editproductpath"), element: <EditProducts /> },
  { path: t("/editsupplierpath"), element: <EditSupplier /> },
  { path: t("/editcutomerpath"), element: <EditCustomer /> },
  { path: t("/editrolepath"), element: <EditRole /> },
  { path: t("/editemppath"), element: <EditEmployee /> },
  { path: t("/salesorderpath"), element: <SalesOrder /> },
  { path: t("/salesorder2path"), element: <SalesOrder2 /> },
  { path: t("/fastsale"), element: <FastSale /> },

  { path: t("/posbarpath"), element: <PosBar /> },

  { path: t("/purchaseorderlistpath"), element: <PurchaseOrdeList /> },
  { path: t("/purchaseorderpath"), element: <PurchaseOrder /> },
  { path: t("/editinventorypath"), element: <EditInventory /> },
  { path: t("/createinventorypath"), element: <CreateInventory /> },
  { path: t("/inventorylistpath"), element: <InventoryList /> },
  { path: t("/editshoppath"), element: <EditShop /> },
  { path: t("/createshoppath"), element: <CreateShop /> },
  { path: t("/shoplistpath"), element: <ShopList /> },
  { path: t("/editwarehousepath"), element: <EditWarehouse /> },
  { path: t("/createwarehousepath"), element: <CreateWarehouse /> },
  { path: t("/warehouselistpath"), element: <WarehouseList /> },
  { path: t("/productcompositelistpath"), element: <CompositeProductList /> },
  {
    path: t("/createproductcompositepath"),
    element: <CreateCompositeProduct />,
  },
  { path: t("/editproductcompositepath"), element: <EditCompositeProduct /> },
  { path: t("/exchangeratelistpath"), element: <ExchangeRateList /> },
  { path: t("/createexchangeratepath"), element: <CreateExchangeRate /> },
  { path: t("/editexchangeratepath"), element: <EditExchangeRate /> },
  {
    path: t("/createbusinessregisterpath"),
    element: <CreateBusinessRegister />,
  },
  { path: t("/editbusinessregisterpath"), element: <EditBusinessRegister /> },
  { path: t("/changepassword"), element: <ChangePasswordForm /> },
  { path: t("/balance"), element: <CashBalanceEditor /> },
  { path: t("/companyinfo"), element: <CompanyInfo /> },
  { path: t("/importresult"), element: <ImportEmployee /> },
  { path: t("/importcustomers"), element: <ImportCustomer /> },
  { path: t("/importproduct"), element: <ImportProduct /> },
  { path: t("/importsupplier"), element: <ImportSuplier /> },
  { path: t("/importlot"), element: <ImportLot /> },
  { path: t("/account"), element: <Account /> },
  { path: t("/accountTransfer"), element: <CashAccountTransfer /> },
  { path: t("/cashledger"), element: <CashLedger /> },
  { path: t("/companyconfig"), element: <CompanyConfig /> },
  { path: t("/cashledgerlist"), element: <CashLedgerList /> },
  { path: t("/companycertificate"), element: <Certificate /> },
  { path: t("/"), element: <Dashboard /> },
];

export default routesConfig;
