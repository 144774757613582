export class InventoryDto {
  warehouseId?: number | null;
  productId: number;
  lotId?: number | null;
  quantity: number;

  constructor(inventory: {
    warehouseId?: number | null;
    productId: number;
    lotId?: number | null;
    quantity: number;
  }) {
    this.warehouseId = inventory.warehouseId ?? null;
    this.productId = inventory.productId;
    this.lotId = inventory.lotId ?? null;
    this.quantity = inventory.quantity;
  }
}

export default InventoryDto;
