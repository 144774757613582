import React, { useState, useEffect } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useAuth } from "../provider/authProvider";
import { useNavigate } from "react-router-dom";
import API_URLS from "@/config";
import { useTranslation } from "react-i18next";
import DataFetcher from "@/provider/DataFetcher";

interface EmployeeData {
  id: number;
  name: string;
  username: string;
  email: string;
  roleId: number;
  shopId: number;
  phoneNumber: string;
  isSuperUser: boolean;
  roleName?: string;
  shopName?: string;
}

export type Role = {
  id: number;
  name: string;
};

export type Shop = {
  id: number;
  name: string;
};

const ROLE_STORAGE_KEY = "roleData";
const SHOP_STORAGE_KEY = "shopData";
const EMPLOYEE_STORAGE_KEY = "employeeData";

const EmployeeInfo: React.FC = () => {
  const { token, setToken, isSuperUser } = useAuth();
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState<EmployeeData | null>(null);
  const [, setRoles] = useState<Role[]>([]);
  const [, setShops] = useState<Shop[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const [avatarSrc, setAvatarSrc] = useState<string | undefined>(undefined);
  useEffect(() => {
    const fetchEmployeeData = async () => {
      if (!token) {
        setError("No token provided");
        return;
      }

      try {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const employeeId = decodedToken.employeeId;
        console.log(employeeId);
        if (!employeeId) {
          setError("Invalid employeeId in token");
          return;
        }

        console.log("employeeId from Token:", employeeId);

        const [rolesData, shopsData, employeesData] = await Promise.all([
          DataFetcher.getData<Role[]>(
            `${API_URLS.BASE_URL}${API_URLS.ROLE}`,
            ROLE_STORAGE_KEY,
            true
          ),
          DataFetcher.getData<Shop[]>(
            `${API_URLS.BASE_URL}${API_URLS.SHOP}`,
            SHOP_STORAGE_KEY,
            true
          ),
          DataFetcher.getData<EmployeeData[]>(
            `${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`,
            EMPLOYEE_STORAGE_KEY,
            true
          ),
        ]);

        if (!rolesData || !shopsData || !employeesData) {
          throw new Error("Failed to fetch necessary data");
        }

        const employee = employeesData.find(
          (emp) => emp.id === parseInt(employeeId)
        );

        if (!employee) {
          setError("No employee data found with the employeeId from token");
          return;
        }
        const savedAvatar = getAvatarFromLocalStorage();
        if (savedAvatar) {
          setAvatarSrc(savedAvatar);
        }

        const role = rolesData.find((role) => role.id === employee.roleId);
        const shop = shopsData.find((shop) => shop.id === employee.shopId);

        setEmployeeData({
          ...employee,
          roleName: role ? role.name : "Unknown Role",
          shopName: shop ? shop.name : "Unknown Shop",
        });

        setRoles(rolesData);
        setShops(shopsData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Your Token has expired you need to log in please");
      }
    };
    fetchEmployeeData();
  }, [token]);

  const getAvatarFromLocalStorage = (): string | null => {
    return localStorage.getItem("avatarSrc");
  };
  const logout = () => {
      localStorage.clear(); // Clears all items from local storage
    setToken(null);
    navigate("/", { replace: true });
  };

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center p-4">
        <p className="text-red-500 mb-4">{error}</p>
        <a
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          href="/login"
          onClick={logout}
        >
          {t("login")}
        </a>
      </div>
    );
  }

  if (!employeeData) {
    return <p>Loading...</p>;
  }

  return (
    <div className="p-4 overflow-x-auto">
      <div className="flex flex-col items-center space-y-4">
        <div className="w-24 h-24">
          <Avatar className="w-full h-full rounded-full">
            <AvatarImage
              src={avatarSrc || "https://github.com/shadcn.png"}
              alt="Company Logo"
            />
            <AvatarFallback>{employeeData?.name}</AvatarFallback>
          </Avatar>
        </div>
        <div className="w-full">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              {t("Employee Name")}
            </label>
            <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
              {employeeData.name}
            </p>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              {t("username")}
            </label>
            <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
              {employeeData.username}
            </p>
          </div>
          {employeeData.email && (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700">
      {t("email")}
    </label>
    <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
      {employeeData.email}
    </p>
  </div>
)}

         {isSuperUser !== true && employeeData.roleName !== "Unknown Role" && (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700">
      {t("selectrole")}
    </label>
    <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
      {employeeData.roleName}
    </p>
  </div>
)}

         {employeeData.phoneNumber && (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700">
      {t("telnumber")}
    </label>
    <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
      {employeeData.phoneNumber}
    </p>
  </div>
)}
{employeeData.shopName && employeeData.shopName !== "Unknown Shop" && (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700">
      {t("shopId")}
    </label>
    <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
      {employeeData.shopName}
    </p>
  </div>
)}


          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              {t("superuser")}
            </label>
            <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
              {employeeData.isSuperUser ? "Yes" : "No"}
            </p>
          </div>
          <a
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            href="/login"
            onClick={logout}
          >
            {t("logout")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default EmployeeInfo;
