import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DataFetcher from "@/provider/DataFetcher";
import API_URLS from "@/config";

const SalesChart: React.FC = () => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState([]);
  const DASHBOARD_STORAGE_KEY = "dashboardData";

  const monthNames = [
    t("Jan"),
    t("Feb"),
    t("Mar"),
    t("Apr"),
    t("May"),
    t("Jun"),
    t("Jul"),
    t("Aug"),
    t("Sep"),
    t("Oct"),
    t("Nov"),
    t("Dec"),
  ];

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        console.warn("No token found. Please log in.");
        return;
      }

      try {
        const response = await DataFetcher.getData<any>(
          `${API_URLS.BASE_URL}${API_URLS.Dashboard}`,
          DASHBOARD_STORAGE_KEY,
          true
        );

        if (response && response.salesByMonths) {
          const formattedData = response.salesByMonths.map((item: any) => ({
            name: monthNames[item.month - 1],
            sales: item.amount,
          }));
          setChartData(formattedData);
        }
      } catch (error) {
        console.error("Error fetching sales data:", error);
      }
    };

    fetchData();
  }, [t]); // Depend on monthNames to update when translations change

  return (
    <div style={{ width: "100%", height: 300, paddingBottom: 20 }}>
      <h2>{t("Monthly Sales")}</h2>
      <ResponsiveContainer>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="sales" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SalesChart;
