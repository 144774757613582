import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Input } from "@/components/ui/input";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import CheckoutPanel from "@/componentsPos/checkoutPanel";
import NumericKeypad from "@/componentsPos/numericKeyboard";
import "@/css/salesorder.css";
import { Product } from "@/types/ProductType";
import { MoreHorizontal, X, Coins } from "lucide-react";
import CreateSalesOrderDto from "@/models/CreateSalesOrderDto";
import API_URLS from "@/config";
import QuantityInput from "@/componentsPos/QuantityInput";
import CustomerForm from "../customer/CustomerForm";
import debounce from "lodash/debounce";
import InvoiceThermalPrint from "@/componentsPos/InvocieThermalPrint";
import { useReactToPrint } from "react-to-print";
import SalesStatus from "@/models/SalesStatus";
import { useAuth } from "@/provider/authProvider";
import { CustomerType } from "@/types/CustomerType";
import { UomType } from "@/types/UomType";
import { VATType } from "@/types/VatType";
import { Paymentmethods } from "@/types/PaymentMethdosType";
import { InventoryType } from "@/types/InventoryType";
import DataFetcher from "@/provider/DataFetcher";
import DiscountDialog from "@/componentsPos/DiscountDialog";
import { toast, ToastContainer } from "react-toastify";
import "@/css/LoadingCreate.css";
import { CashAccount } from "@/types/CashAccountType";
import { useSalesOrderProducts } from "@/provider/SelectedProductsContext";
import { Warehouse } from "@/types/WarehouseType";
import { useTheme } from "@/componentsPos/ThemeContext";
interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}
interface Currency {
  id: number;
  name: string;
  symbol: string;
}

const SalesOrder: React.FC = () => {
  const Currency_STORAGE_KEY = "currencyData";

  const PRODUCT_STORAGE_KEY = "productData";
  const CUSTOMER_STORAGE_KEY = "customerData";
  const UOM_STORAGE_KEY = "uomData";
  const VAT_STORAGE_KEY = "vatData";
  const PAYMENT_METHOD_STORAGE_KEY = "paymentMethodData";
  const INVENTORY_STORAGE_KEY = "inventoryData";
  // const CashAccountOwner_STORAGE_KEY = "cashaccountownerData";
  const WAREHOUSE_STORAGE_KEY = "warehouseData";
  const CashAccount_STORAGE_KEY = "cashAccountData";

  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [totalDiscountType, setTotalDiscountType] = useState<
    "value" | "percentage"
  >("value");

  const [productList, setProductList] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [selectedExchangeRate, setSelectedExchangeRate] = useState<
    number | null
  >(null);

  const { salesOrderProducts, setSalesOrderProducts } = useSalesOrderProducts();
  const [customers, setCustomers] = useState<CustomerType[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<CustomerType[]>(
    []
  );
  const [selectedCustomer, setSelectedCustomer] = useState<number | null>();
  const [uomList, setUomList] = useState<UomType[]>([]);
  const [vatList, setVatList] = useState<VATType[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<Paymentmethods[]>([]);
  const [filteredPaymentMethods, setFilteredPaymentMethods] = useState<
    Paymentmethods[]
  >([]);
  const [salesStatus, setSalesStatus] = useState<SalesStatus>(
    SalesStatus.Completed
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>("");
  const [inventory, setInventory] = useState<InventoryType[]>([]);
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse | null>(
    null
  );
  const [isCustomerDialogOpen, setIsCustomerDialogOpen] = useState(false);
  const [cashAccountBalance, setCashAccountBalance] = useState<number>(0);
  const [isDiscountDialogOpen, setIsDiscountDialogOpen] = useState(false);
  const [currentProductForDiscount, setCurrentProductForDiscount] =
    useState<Product | null>(null);
  const [loading, setLoading] = useState(false);
  const [orderNo, setOrderNo] = useState();
  const [cashAccounts, setCashAccounts] = useState<CashAccount[]>([]);
  const [filteredCashAccounts, setFilteredCashAccounts] = useState<
    CashAccount[]
  >([]);
  const [selectedCashAccount, setSelectedCashAccount] = useState<number | null>(
    null
  );
  const [baseCurrencyName, setBaseCurrencyName] = useState("Lekë"); // Default currency
  const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L"); // Default currency symbol
  const [, setCurrencies] = useState<Currency[]>([]); // Holds list of currencies
  const searchInputRef = useRef<HTMLInputElement>(null);
  const barcodeInputRef = useRef("");
  const { t } = useTranslation();
  const invoiceRef = useRef<HTMLDivElement>(null);
  const { employeeId, isSuperUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useTheme();

  const [isScanning, setIsScanning] = useState(false);
  const [, setIsInvalidBarcode] = useState(false);
  const [printerType, setPrinterType] = useState<number>(() => {
    const businessConfig = JSON.parse(
      localStorage.getItem("businessConfigs") || "{}"
    );
    return businessConfig?.printerType;
  });

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });

  const handleCloseDialogCustomer = (newCustomer?: {
    id: number;
    name: string;
  }) => {
    setIsCustomerDialogOpen(false);
    if (newCustomer) {
      setCustomers((prevCustomers) => {
        const updatedCustomers = [...prevCustomers, newCustomer];
        console.log("Updated Customers:", updatedCustomers);
        return updatedCustomers;
      });
      setSelectedCustomer(newCustomer.id);
      console.log(newCustomer.id);
    }
  };

  useEffect(() => {
    setFilteredCustomers(customers);
  }, [customers]);

  const handleTotalDiscountChange = (
    discount: number,
    discountType: "value" | "percentage"
  ) => {
    setTotalDiscount(discount);
    setTotalDiscountType(discountType);
  };

  const handleVoidInvoice = () => {
    setSalesOrderProducts([]);
    setTotalDiscount(0);
    setTotalDiscountType("value");
    setSelectedCustomer(null);
    setSelectedPaymentMethod("");
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await DataFetcher.getData<Product[]>(
        `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`,
        PRODUCT_STORAGE_KEY
      );
      if (response) {
        const filteredProducts = response.filter(
          (product) => !product.isRawMaterial
        );
        setProductList(filteredProducts);
      }
    };
    const fetchCustomers = async () => {
      const response = await DataFetcher.getData<CustomerType[]>(
        `${API_URLS.BASE_URL}${API_URLS.CUSTOMER}`,
        CUSTOMER_STORAGE_KEY
      );
      if (response) {
        setCustomers(response);
        localStorage.setItem(CUSTOMER_STORAGE_KEY, JSON.stringify(response));
        setFilteredCustomers(response);
      }
    };

    const fetchUoMs = async () => {
      const response = await DataFetcher.getData<UomType[]>(
        `${API_URLS.BASE_URL}${API_URLS.UOM}`,
        UOM_STORAGE_KEY
      );
      if (response) {
        setUomList(response);
      }
    };

    const fetchVatRates = async () => {
      const response = await DataFetcher.getData<VATType[]>(
        `${API_URLS.BASE_URL}${API_URLS.VAT}`,
        VAT_STORAGE_KEY
      );
      if (response) {
        setVatList(response);
      }
    };

    const fetchPaymentMethods = async () => {
      const response = await DataFetcher.getData<Paymentmethods[]>(
        `${API_URLS.BASE_URL}${API_URLS.PaymentMethod}`,
        PAYMENT_METHOD_STORAGE_KEY
      );
      if (response) {
        setPaymentMethods(response);
        setFilteredPaymentMethods(response);
      }
    };

    const fetchInventory = async () => {
      const response = await DataFetcher.getData<InventoryType[]>(
        `${API_URLS.BASE_URL}${API_URLS.INVENTORY}`,
        INVENTORY_STORAGE_KEY,
        true
      );
      if (response) {
        setInventory(response);
      }
    };

    const fetchWarehouses = async () => {
      const response = await DataFetcher.getData<Warehouse[]>(
        `${API_URLS.BASE_URL}${API_URLS.WAREHOUSE}`,
        WAREHOUSE_STORAGE_KEY
      );
      if (response) {
        setWarehouses(response);
        setSelectedWarehouse(response[0] || null);
      }
    };
    fetchProducts();
    fetchCustomers();
    fetchUoMs();
    fetchVatRates();
    fetchPaymentMethods();
    fetchInventory();
    fetchWarehouses();
  }, []);

  useEffect(() => {
    if (employeeId !== null) {
      const parsedEmployeeId = parseInt(employeeId, 10);
      if (!isNaN(parsedEmployeeId)) {
        const fetchCashAccounts = async () => {
          try {
            const response = await DataFetcher.getData<CashAccount[] | null>(
              `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
              CashAccount_STORAGE_KEY,
              true
            );

            if (response && Array.isArray(response)) {
              const ownedCashAccounts = isSuperUser
                ? response
                : response.filter(
                    (account) =>
                      account.owners.some(
                        (owner: { id: number }) => owner.id === parsedEmployeeId
                      ) && account.isBank === false
                  );
              console.log(ownedCashAccounts);
              setCashAccounts(ownedCashAccounts);
              setFilteredCashAccounts(ownedCashAccounts);
            }
          } catch (error) {
            toast.error("Failed to fetch cash accounts");
            console.error(error);
          }
        };

        if (parsedEmployeeId !== null || isSuperUser) {
          fetchCashAccounts();
        }
      }
    }
  }, [employeeId, isSuperUser]);

  useEffect(() => {
    // Fetch available currencies using DataFetcher with caching
    const fetchCurrencyData = async () => {
      try {
        const response = await DataFetcher.getData<Currency[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`, // API endpoint
          Currency_STORAGE_KEY // LocalStorage key
        );
        setCurrencies(response || []); // Set fetched currencies, default to an empty array if none

        // Find and set base currency name and symbol
        const businessConfigs: BusinessConfigs = JSON.parse(
          localStorage.getItem("businessConfigs") || "{}"
        );
        const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1 if not set
        const baseCurrency = response?.find(
          (currency) => currency.id === baseCurrencyId
        );

        setBaseCurrencyName(baseCurrency?.name || "Leke"); // Set base currency name, default to "Leke"
        setBaseCurrencySymbol(baseCurrency?.symbol || "L"); // Set base currency symbol, default to "L"
      } catch (error) {
        console.error("Error fetching currencies:", error);
        toast.error(t("Failed to load currencies"));
      }
    };

    fetchCurrencyData();
  }, [t]);

  useEffect(() => {
    const storedCashAccount = localStorage.getItem("selectedCashAccount");

    if (storedCashAccount) {
      const savedAccount = JSON.parse(storedCashAccount);
      setSelectedCashAccount(savedAccount.id);
      setCashAccountBalance(savedAccount.balance);
    }
  }, [cashAccounts]);
  const handleCashAccountSelection = (accountId: number) => {
    setSelectedCashAccount(accountId);

    const selectedAccount = cashAccounts.find(
      (account) => parseInt(account.id) === accountId
    );

    if (selectedAccount) {
      const { balance, lastOpenBalance } = selectedAccount;
      setCashAccountBalance(balance);

      // Save selected cash account to localStorage
      localStorage.setItem(
        "selectedCashAccount",
        JSON.stringify(selectedAccount)
      );

      // Check if lastUpdated is today
      const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
      if (!lastOpenBalance || lastOpenBalance.split("T")[0] !== today) {
        // Navigate to CashLedger with the cashAccountId and other data
        navigate(t("/cashledger"), {
          state: {
            cashAccountId: selectedAccount.id,
            transactionType: 5, // Assuming 5 is "Open Balance" or you can pass the appropriate value
            toastMessage: t("You have to open balance every day before sale."), // Add toast message
          },
        });
      }
    }
  };

  useEffect(() => {
    const firstCustomer = customers.length > 0 ? customers[0].id : null;
    setSelectedCustomer(firstCustomer);
    const firstPaymentMethod =
      paymentMethods.length > 0 ? paymentMethods[0] : null;
    if (firstPaymentMethod) {
      console.log(firstPaymentMethod);
      setSelectedPaymentMethod(firstPaymentMethod.id);
      localStorage.setItem(
        "selectedPaymentMethod",
        JSON.stringify(firstPaymentMethod)
      );
    }
    const firstCashAccount = cashAccounts.length > 0 ? cashAccounts[0] : null;
    if (firstCashAccount) {
      console.log(firstCashAccount);
      setSelectedCashAccount(Number(firstCashAccount.id));
      setCashAccountBalance(firstCashAccount.balance);
      localStorage.setItem(
        "selectedCashAccount",
        JSON.stringify(firstCashAccount)
      ); // Store the full object
    }
  }, [customers, paymentMethods, cashAccounts]);

  const handleCustomerSelection = (customerId: number) => {
    setSelectedCustomer(customerId);

    const selectedCustomer = customers.find(
      (customer) => customer.id === customerId
    );
    if (selectedCustomer) {
      localStorage.setItem(
        "selectedCustomer",
        JSON.stringify(selectedCustomer)
      );
    }
  };
  const handleWarehouseSelection = (warehouseId: number) => {
    const selected = warehouses.find(
      (warehouse) => Number(warehouse.id) === warehouseId
    );
    if (selected) {
      setSelectedWarehouse(selected);
      localStorage.setItem("selectedWarehouse", JSON.stringify(selected));
    }
  };
  useEffect(() => {
    const storedCustomer = localStorage.getItem("selectedCustomer");

    if (storedCustomer) {
      const savedCustomer = JSON.parse(storedCustomer);
      setSelectedCustomer(savedCustomer.id);
    }
  }, [customers]);

  useEffect(() => {
    // Load the saved warehouse from localStorage on component mount
    const storedWarehouse = localStorage.getItem("selectedWarehouse");
    if (storedWarehouse) {
      setSelectedWarehouse(JSON.parse(storedWarehouse));
    }
  }, [warehouses]);

  const handlePaymentMethodSelection = (methodId: string) => {
    const selectedPaymentMethod = paymentMethods.find(
      (method) => method.id === methodId
    );

    if (selectedPaymentMethod) {
      setSelectedPaymentMethod(methodId);
      if (!selectedPaymentMethod.isCash) {
        setSelectedCashAccount(null);
        setCashAccountBalance(0);
      }
      localStorage.removeItem("selectedCashAccount");

      localStorage.setItem(
        "selectedPaymentMethod",
        JSON.stringify(selectedPaymentMethod)
      );
    }
  };

  useEffect(() => {
    const storedPaymentMethod = localStorage.getItem("selectedPaymentMethod");

    if (storedPaymentMethod) {
      const savedPaymentMethod = JSON.parse(storedPaymentMethod);
      setSelectedPaymentMethod(savedPaymentMethod.id);

      if (!savedPaymentMethod.isCash) {
        setSelectedCashAccount(null);
      }
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (location.state?.selectedProducts) {
      addSelectedProducts(location.state.selectedProducts);
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state, navigate]);

  const addSelectedProducts = (newSelectedProducts: Product[]) => {
    setSalesOrderProducts((prevOrderProducts) => {
      const updatedOrderProducts = [...prevOrderProducts];

      newSelectedProducts.forEach((newProduct) => {
        const existingProductIndex = updatedOrderProducts.findIndex(
          (p) => p.product.id === newProduct.id
        );

        if (existingProductIndex === -1) {
          updatedOrderProducts.push({
            product: newProduct,
            quantity: 1,
            isEditing: false,
            isAddingDiscount: false,
          });
        }
      });

      return updatedOrderProducts;
    });
  };
  // useEffect(() => {
  //   if (employeeId !== null) {
  //     const parsedEmployeeId = parseInt(employeeId, 10);
  //     if (!isNaN(parsedEmployeeId)) {
  //       const fetchCashAccountBalance = async () => {
  //         try {
  //           const response = await DataFetcher.getData<any>(
  //             `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
  //             CashAccount_STORAGE_KEY,
  //             true
  //           );
  //           console.log(response);
  //           const cashAccounts = response;

  //           if (cashAccounts && cashAccounts.length > 0) {
  //             const cashAccount = cashAccounts.find(
  //               (account: { owners: { id: number }[] }) =>
  //                 account.owners.some((owner) => owner.id === parsedEmployeeId)
  //             );
  //             console.log(cashAccount);
  //             if (cashAccount) {
  //               setCashAccountBalance(cashAccount.balance);
  //               console.log(cashAccount.balance);
  //             } else {
  //               setCashAccountBalance(0);
  //               console.log("No cash account found for this employee");
  //             }
  //           } else {
  //             setCashAccountBalance(0);
  //             console.log("No cash accounts found");
  //           }
  //         } catch (error) {
  //           console.error("Error fetching cash account balance:", error);
  //         }
  //       };

  //       fetchCashAccountBalance();
  //     }
  //   }
  // }, [employeeId]);

  const debouncedSetFilteredProducts = debounce((query: string) => {
    if (query === "") {
      setFilteredProducts([]);
      setSelectedProductIndex(0);
    } else {
      const results = productList.filter(
        (product) =>
          product.name.toLowerCase().includes(query.toLowerCase()) ||
          (product.barcode && product.barcode.includes(query))
      );
      setFilteredProducts(results);
      setSelectedProductIndex(0);
    }
  }, 500);

  useEffect(() => {
    debouncedSetFilteredProducts(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    if (
      filteredProducts.length > 0 &&
      selectedProductIndex >= filteredProducts.length
    ) {
      setSelectedProductIndex(filteredProducts.length - 1);
    }
  }, [filteredProducts, selectedProductIndex]);

  const handleCustomerSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    const results = customers.filter((customer) =>
      customer.name.toLowerCase().includes(value)
    );
    setFilteredCustomers(results);
  };

  const handlePaymentMethodSearch = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value.toLowerCase();
    const results = paymentMethods.filter((method) =>
      method.name.toLowerCase().includes(value)
    );
    setFilteredPaymentMethods(results);
  };

  const handleCashAccountSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    const results = cashAccounts.filter((account) =>
      account.name.toLowerCase().includes(value)
    );
    setFilteredCashAccounts(results);
  };

  const addToOrder = (product: Product) => {
    setSalesOrderProducts((prevProducts) => {
      const existingProduct = prevProducts.find(
        (p) => p.product.id === product.id
      );

      // Corrected typing for discountType
      const updatedProducts = existingProduct
        ? prevProducts.map((p) =>
            p.product.id === product.id ? { ...p, quantity: p.quantity + 1 } : p
          )
        : [
            ...prevProducts,
            {
              product,
              quantity: 1,
              discountType: "value" as "value" | "percentage",
            },
          ];

      return updatedProducts;
    });

    setSearchQuery("");
    setFilteredProducts([]);
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const openDiscountDialog = (product: Product) => {
    setCurrentProductForDiscount(product);
    setIsDiscountDialogOpen(true);
  };

  const handleDiscountSave = (
    discountType: "value" | "percentage",
    discount: number
  ) => {
    if (currentProductForDiscount) {
      setSalesOrderProducts((prevProducts) =>
        prevProducts.map((p) =>
          p.product.id === currentProductForDiscount.id
            ? { ...p, discountType, discount }
            : p
        )
      );
      setCurrentProductForDiscount(null);
    }
  };

  const removeFromOrder = (productId: string) => {
    setSalesOrderProducts((prevProducts) =>
      prevProducts.filter((p) => p.product.id !== productId)
    );
  };

  const calculateSubtotalAndDiscount = () => {
    let subtotal = 0;
    let totalProductDiscounts = 0;

    salesOrderProducts.forEach(
      ({ product, quantity, discount, discountType }) => {
        const price = product.price * quantity;
        let discountedPrice = price;

        if (discount !== undefined) {
          if (discountType === "percentage") {
            discountedPrice = price * (1 - discount / 100);
          } else {
            discountedPrice = price - discount;
          }
        }

        subtotal += discountedPrice;
        totalProductDiscounts += price - discountedPrice;
      }
    );

    const vatAmounts = salesOrderProducts.map(
      ({ product, quantity, discount, discountType }) => {
        const vatRate =
          vatList.find((v) => v.id === product.vatRateId)?.rate || 0;
        const price = product.price * quantity;
        let discountedPrice = price;

        if (discount !== undefined) {
          if (discountType === "percentage") {
            discountedPrice = price * (1 - discount / 100);
          } else {
            discountedPrice = price - discount;
          }
        }

        return discountedPrice * (vatRate / 100);
      }
    );

    const totalVAT = vatAmounts.reduce((sum, vat) => sum + vat, 0);

    let finalSubtotal = subtotal - totalVAT;

    let discountFromTotalPrice = 0;

    if (totalDiscountType === "percentage") {
      discountFromTotalPrice = finalSubtotal * (totalDiscount / 100);
      finalSubtotal -= discountFromTotalPrice;
    } else {
      discountFromTotalPrice = totalDiscount;
      finalSubtotal -= totalDiscount;
    }

    const totalDiscountSum = totalProductDiscounts + discountFromTotalPrice;

    return {
      subtotal: finalSubtotal.toFixed(2),
      totalOrderDiscount: discountFromTotalPrice.toFixed(2),
      totalProductDiscounts: totalProductDiscounts.toFixed(2),
      totalDiscountSum: totalDiscountSum.toFixed(2),
    };
  };

  const calculateVAT = () => {
    const vatAmounts = salesOrderProducts.map(
      ({ product, quantity, discount, discountType }) => {
        const vatRate =
          vatList.find((v) => v.id === product.vatRateId)?.rate || 0;
        const price = product.price * quantity;
        let discountedPrice = price;

        if (discount !== undefined) {
          if (discountType === "percentage") {
            discountedPrice = price * (1 - discount / 100);
          } else {
            discountedPrice = price - discount;
          }
        }

        return discountedPrice * (vatRate / 100);
      }
    );

    const totalVAT = vatAmounts.reduce((sum, vat) => sum + vat, 0);

    return totalVAT.toFixed(2);
  };

  const calculateTotal = () => {
    const { subtotal } = calculateSubtotalAndDiscount();
    const vat = parseFloat(calculateVAT());

    const validSubtotal = isNaN(parseFloat(subtotal))
      ? 0
      : parseFloat(subtotal);
    const validVat = isNaN(vat) ? 0 : vat;

    const total = validSubtotal + validVat;

    return total.toFixed(2);
  };

  const calculateProductDiscount = (
    productPrice: number,
    quantity: number,
    discount: number,
    discountType: "value" | "percentage"
  ) => {
    if (discountType === "percentage") {
      return (productPrice * quantity * discount) / 100;
    } else {
      return discount;
    }
  };

  const fetchInventory = async () => {
    try {
      const response = await DataFetcher.getData<InventoryType[]>(
        `${API_URLS.BASE_URL}${API_URLS.INVENTORY}`,
        INVENTORY_STORAGE_KEY,
        true
      );
      if (response) {
        setInventory(response);
      }
    } catch (error) {
      console.error("Error fetching inventory:", error);
    }
  };

  const fetchCashAccounts = async (parsedEmployeeId: number) => {
    try {
      const response = await DataFetcher.getData<CashAccount[] | null>(
        `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
        CashAccount_STORAGE_KEY,
        true
      );

      if (response && Array.isArray(response)) {
        const ownedCashAccounts = response.filter(
          (account) =>
            account.owners.some(
              (owner: { id: number }) => owner.id === parsedEmployeeId
            ) && account.isBank === false
        );
        setCashAccounts(ownedCashAccounts);
        setFilteredCashAccounts(ownedCashAccounts);
      }
    } catch (error) {
      toast.error("Failed to fetch cash accounts");
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedPaymentMethod) {
      const paymentMethod = paymentMethods.find(
        (method) => method.id === selectedPaymentMethod
      );

      // If payment method is cash, filter out bank accounts
      if (paymentMethod && paymentMethod.isCash) {
        setFilteredCashAccounts(
          cashAccounts.filter((account) => !account.isBank)
        );
      } else {
        // If not a cash payment method, show all cash accounts
        setFilteredCashAccounts(cashAccounts);
      }
    }
  }, [selectedPaymentMethod, paymentMethods, cashAccounts]);

  const handleCheckout = async (
    amountReceived: number,
    printInvoice: () => void,
    cashAccountId?: number,
    paymentTermId?: number,
    exchangeRate?: number,
    selectedCurrencyDetails?: { id: number; name: string; symbol: string },
    changeAmount?: number
  ) => {
    console.log(amountReceived);
    const totalAmount = parseFloat(calculateTotal());
    const selectedPaymentMethodInt = parseInt(selectedPaymentMethod, 10);
    setLoading(true); // Show loading spinner
    setSelectedExchangeRate(exchangeRate || null);
    console.log("Selected Currency Details:", selectedCurrencyDetails);

    if (employeeId !== null) {
      const parsedEmployeeId = parseInt(employeeId, 10);
      const businessConfigs = JSON.parse(
        localStorage.getItem("businessConfigs") as string
      );
      console.log("Change Amount Received in SalesOrder:", changeAmount);

      // Directly access allowNegativeInventory since it's always present
      const allowNegativeInventory: boolean =
        businessConfigs.allowNegativeInventory;
      const baseCurrencyId: number = businessConfigs.baseCurrencyId;

      const isCashPayment = paymentMethods.find(
        (method) => method.id === selectedPaymentMethod
      )?.isCash;

      let amountDue = 0;

      if (isCashPayment) {
        if (changeAmount !== undefined && changeAmount < 0) {
          amountDue = Math.abs(changeAmount);
        } else {
          amountDue = 0;
        }
      }

      console.log(amountDue);
      // Check if cash account has an open balance for today
      const selectedAccount = cashAccounts.find(
        (account) =>
          parseInt(account.id) === (cashAccountId || selectedCashAccount)
      );

      if (selectedAccount) {
        const { balance, lastOpenBalance } = selectedAccount;
        setCashAccountBalance(balance);

        // Save selected cash account to localStorage

        // Get today's date in YYYY-MM-DD format
        const today = new Date().toISOString().split("T")[0];

        // If no open balance for today, navigate to CashLedger
        if (!lastOpenBalance || lastOpenBalance.split("T")[0] !== today) {
          navigate(t("/cashledger"), {
            state: {
              cashAccountId: selectedAccount.id,
              transactionType: 5, // Assuming 5 is "Open Balance"
              toastMessage: t(
                "You have to open balance every day before sale."
              ), // Add toast message
            },
          });
          return; // Stop further execution since the user is navigated to CashLedger
        }
      }

      // const orderNumber = 0;
      const dto = new CreateSalesOrderDto({
        // orderNo: orderNumber,
        orderDateUtc: new Date().toISOString(),
        customerId: selectedCustomer ?? null,
        currencyId: baseCurrencyId,
        totalAmount: totalAmount,
        amountDue: amountDue,
        quantity: salesOrderProducts.reduce(
          (total, { quantity }) => total + quantity,
          0
        ),
        vatAmount: parseFloat(calculateVAT()),
        discountAmount: parseFloat(
          calculateSubtotalAndDiscount().totalOrderDiscount
        ),
        sameTaxes: 0,
        shippingCharges: 0,
        adjustedCharges: 0,
        employeeId: parsedEmployeeId ?? 0,
        // shopId: 1,
        warehouseId: selectedWarehouse
          ? parseInt(selectedWarehouse.id.toString(), 10)
          : 0,
        paymentTermsId: paymentTermId,
        paymentMethodId: selectedPaymentMethodInt,
        orderType: 0,
        orderStatus: salesStatus,
        notes: "",
        cashAccountId: selectedCashAccount || cashAccountId || 0,
        shiftId: 1,
        PaymentExchangeRate: exchangeRate,
        PaymentCurrencyId: selectedCurrencyDetails?.id,
        PaymentAmount: amountReceived,
        ExchangeRate: 1,
        allowNegativeInvetory: allowNegativeInventory,
        salesOrderLines: salesOrderProducts.map(
          ({ product, quantity, discount, discountType }) => {
            return {
              productId: parseInt(product.id),
              uoMId: product.uoMId,
              quantity: quantity,
              lineNote: "",
              discountAmount: calculateProductDiscount(
                product.price,
                quantity,
                discount || 0,
                discountType || "value"
              ),
            };
          }
        ),
      });

      try {
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.SALESORDER}`,
          dto
        );
        const orderNo = response.data.orderNo;
        setOrderNo(orderNo);

        console.log("Order created successfully", response.data);
        await updateCashAccountBalance(
          totalAmount,
          amountReceived,
          changeAmount
        );
        await fetchCashAccounts(parsedEmployeeId);
        printInvoice();
        resetSalesOrder();
        await fetchInventory();
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const { status, data } = error.response;

          if (status === 403) {
            toast.error(
              t("You do not have permission to perform this action.")
            );
          } else if (status === 500) {
            toast.error(t("A server error occurred. Please try again later."));
          } else if (data && Array.isArray(data)) {
            data.forEach((err: { errorMessage: string }) => {
              const translatedMessage =
                t(`errorSalesOrder.${err.errorMessage}`) ||
                t("errorSalesOrder.An error occurred");
              toast.error(translatedMessage);
            });
          } else if (data && typeof data.message === "string") {
            // Check for the specific error message
            if (data.message.includes("Insufficient total inventory")) {
              toast.error(t("Insufficient total inventory")); // Display the specific message
            } else {
              toast.error(t(data.message)); // Display other messages if necessary
            }
          } else {
            toast.error(t("An error occurred"));
          }
        } else {
          toast.error(t("An unexpected error occurred"));
        }
        console.error("Error creating order:", error);
      } finally {
        setLoading(false); // Hide loading spinner
      }
    }
  };

  const resetSalesOrder = () => {
    setSalesOrderProducts([]);
    setSalesStatus(SalesStatus.Completed);
    setSearchQuery("");
    setFilteredProducts([]);
    setTotalDiscount(0);
    setTotalDiscountType("value");
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
  };

  const updateCashAccountBalance = async (
    salesAmount: number,
    amountReceived: number,
    changeAmount: number | undefined
  ) => {
    if (!selectedCashAccount) {
      console.error("No cash account selected");
      return;
    }

    try {
      const selectedAccount = cashAccounts.find(
        (account) => parseInt(account.id) === selectedCashAccount
      );

      console.log(salesAmount);
      console.log(amountReceived);
      console.log(changeAmount);

      if (selectedAccount) {
        let updatedBalance: number = selectedAccount.balance;

        if (changeAmount !== undefined) {
          if (changeAmount >= 0) {
            updatedBalance += salesAmount;
          } else if (changeAmount < 0) {
            updatedBalance += amountReceived;
          }
        } else {
          console.warn("changeAmount is undefined");
        }

        setCashAccountBalance(updatedBalance);

        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.CashAccount}/${selectedCashAccount}`,
          {
            ...selectedAccount,
            balance: updatedBalance,
          }
        );

        const updatedAccount = { ...selectedAccount, balance: updatedBalance };
        localStorage.setItem(
          "selectedCashAccount",
          JSON.stringify(updatedAccount)
        );

        console.log("Cash account balance updated successfully", response.data);
      }
    } catch (error) {
      console.error("Error updating cash account balance:", error);
      toast.error("Error updating cash account balance");
    }
  };

  const getUoMName = (uomId: number) => {
    const uom = uomList.find((u) => u.id === uomId);
    return uom ? uom.name : "";
  };

  const getVATRate = (vatRateId: number) => {
    const vatRate = vatList.find((v) => v.id === vatRateId);
    return vatRate ? vatRate.rate : 0;
  };

  const getVATRateName = (vatRateId: number) => {
    const vatRate = vatList.find((v) => v.id === vatRateId);
    return vatRate ? vatRate.name : "";
  };

  const handleEditToggle = (productId: string) => {
    setSalesOrderProducts((prevProducts) =>
      prevProducts.map((p) =>
        p.product.id === productId ? { ...p, isEditing: !p.isEditing } : p
      )
    );
  };

  const handleProductChange = (productId: string, key: string, value: any) => {
    setSalesOrderProducts((prevProducts) =>
      prevProducts.map((p) => {
        if (p.product.id === productId) {
          if (key in p.product) {
            return {
              ...p,
              product: {
                ...p.product,
                [key]: value,
              },
            };
          } else {
            return {
              ...p,
              [key]: value,
            };
          }
        }
        return p;
      })
    );
  };

  const editcashbalance = () => {
    navigate(t("/balance"));
  };

  const handleRemoveCustomer = () => {
    setSelectedCustomer(null);
    localStorage.removeItem("selectedCustomer");
  };
  const handleRemoveWarehouse = () => {
    setSelectedWarehouse(null);
    localStorage.removeItem("selectedWarehouse");
  };

  const handleRemoveCashAccount = () => {
    setSelectedCashAccount(null);
    setCashAccountBalance(0);
    // Remove selected cash account from localStorage
    localStorage.removeItem("selectedCashAccount");
  };

  const handleRemovePaymentMethod = () => {
    setSelectedPaymentMethod("");
    localStorage.removeItem("selectedPaymentMethod");
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && filteredProducts.length > 0) {
      addToOrder(filteredProducts[selectedProductIndex]);
    }
  };

  const handleBarcodeInput = (event: KeyboardEvent) => {
    if (isScanning) return;

    if (event.key === "Enter") {
      const barcode = barcodeInputRef.current.trim();

      const isNumericBarcode = /^\d+$/.test(barcode);
      const isValidLength = barcode.length > 8;

      if (barcode === "" || !isNumericBarcode) {
        barcodeInputRef.current = "";
        setIsScanning(false);
        return;
      }

      setIsScanning(true);

      const product = productList.find(
        (product) => product.barcode === barcode
      );

      barcodeInputRef.current = "";

      if (product) {
        addToOrder(product);
        setIsInvalidBarcode(false);
        setIsScanning(false);
        return;
      }

      if (isNumericBarcode && isValidLength) {
        toast.error("Product not found!");
        setIsInvalidBarcode(true);
      }

      setIsScanning(false);
    } else {
      if (event.key === "Backspace") {
        barcodeInputRef.current = "";
        setIsInvalidBarcode(false);
      } else {
        barcodeInputRef.current += event.key;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleBarcodeInput);
    return () => {
      window.removeEventListener("keydown", handleBarcodeInput);
    };
  }, [productList, inventory]);

  const { subtotal, totalOrderDiscount, totalProductDiscounts } =
    calculateSubtotalAndDiscount();

  const handleNumericInput = (value: string) => {
    if (searchInputRef.current) {
      searchInputRef.current.value += value;
      setSearchQuery(searchInputRef.current.value);
    }
  };

  const handleClearInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
      setSearchQuery("");
    }
  };

  const handleBackspace = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = searchInputRef.current.value.slice(0, -1);
      setSearchQuery(searchInputRef.current.value);
    }
  };

  const transformedCustomers = customers.map((customer) => ({
    id: customer.id.toString(),
    name: customer.name,
  }));

  console.log("Selected Customer:", selectedCustomer);
  return (
    <div className="pos-display">
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <div className="pos-body">
        <div className="pos-add">
          <div className="selector items-baseline">
            <div className="printer-type-select">
              <label>
                <input
                  type="radio"
                  value="2"
                  checked={printerType === 2}
                  onChange={() => setPrinterType(2)}
                />
                80mm
              </label>
              <label>
                <input
                  type="radio"
                  value="1"
                  checked={printerType === 1}
                  onChange={() => setPrinterType(1)}
                />
                A4
              </label>
            </div>
            <div className="customer-select pb-5">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline">
                    <span>
                      {selectedCustomer
                        ? t("changecustomer")
                        : t("Select a customer")}
                    </span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="start"
                  className="customer-dropdown"
                >
                  <Input
                    placeholder={t("Search a customer")}
                    onChange={handleCustomerSearch}
                    className="mb-2"
                  />
                  {filteredCustomers.length > 0 ? (
                    filteredCustomers.map((customer) => (
                      <DropdownMenuItem
                        key={customer.id}
                        onClick={() => handleCustomerSelection(customer.id)}
                      >
                        {customer.name}
                      </DropdownMenuItem>
                    ))
                  ) : (
                    <DropdownMenuLabel>
                      {t("No customers found")}
                    </DropdownMenuLabel>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
              <Dialog
                open={isCustomerDialogOpen}
                onOpenChange={setIsCustomerDialogOpen}
              >
                <DialogTrigger asChild>
                  <Button
                    type="button"
                    className="mt-6 bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    +
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <CustomerForm
                    mode="create"
                    inDialog={true}
                    onClose={handleCloseDialogCustomer}
                  />
                </DialogContent>
              </Dialog>
              {selectedCustomer && (
                <div className="mt-2 flex items-center">
                  <span className="selected-customer-label ml-2 px-2 py-1 border border-gray-300 rounded">
                    {customers.find((c) => c.id === selectedCustomer)?.name}
                  </span>
                  <Button
                    variant="ghost"
                    onClick={handleRemoveCustomer}
                    className="ml-2"
                  >
                    <X className="h-4 w-4 icon" />
                  </Button>
                </div>
              )}
            </div>

            <div className="payment-method-select pb-5">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline">
                    {selectedPaymentMethod
                      ? t("Change payment method")
                      : t("Select a payment method")}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="start"
                  className="payment-method-dropdown"
                >
                  <Input
                    placeholder={t("Search payment method")}
                    onChange={handlePaymentMethodSearch}
                    className="mb-2"
                  />
                  {filteredPaymentMethods.length > 0 ? (
                    filteredPaymentMethods.map((method) => (
                      <DropdownMenuItem
                        key={method.id}
                        onClick={() => handlePaymentMethodSelection(method.id)}
                      >
                        {method.name}
                      </DropdownMenuItem>
                    ))
                  ) : (
                    <DropdownMenuLabel>
                      {t("No payment methods found")}
                    </DropdownMenuLabel>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
              {selectedPaymentMethod && (
                <div className="mt-2 flex items-center">
                  <span className="selected-payment-label ml-2 px-2 py-1 border border-gray-300 rounded">
                    {
                      paymentMethods.find(
                        (method) => method.id === selectedPaymentMethod
                      )?.name
                    }
                  </span>
                  <Button
                    variant="ghost"
                    onClick={handleRemovePaymentMethod}
                    className="ml-2"
                  >
                    <X className="h-4 w-4 icon" />
                  </Button>
                </div>
              )}
            </div>

            {/* <div className="purchase-status-select pb-5">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline">{t("SelectSalesStatus")}</Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="start"
                  className="purchase-status-dropdown"
                >
                  {Object.keys(SalesStatus)
                    .filter((key) => !isNaN(Number(SalesStatus[key as any])))
                    .map((key) => (
                      <DropdownMenuItem
                        key={key}
                        onClick={() =>
                          setSalesStatus(
                            SalesStatus[key as keyof typeof SalesStatus]
                          )
                        } // Casting 'key' as a key of SalesStatus
                      >
                        {key}
                      </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
              </DropdownMenu>
              {salesStatus !== undefined && (
                <div className="mt-2 flex items-center">
                  <span className="selected-purchase-status">
                    {SalesStatus[salesStatus]}
                  </span>
                </div>
              )}
            </div> */}
            <div className="cash-account-select pb-5">
              {selectedPaymentMethod &&
                paymentMethods.find(
                  (method) => method.id === selectedPaymentMethod
                )?.isCash && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline">
                        {selectedCashAccount
                          ? t("ChangeCashAccount")
                          : t("SelectCashAccount")}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      align="start"
                      className="cash-account-dropdown"
                    >
                      <Input
                        placeholder={t("Search cash account")}
                        onChange={handleCashAccountSearch}
                        className="mb-2"
                      />
                      {filteredCashAccounts.length > 0 ? (
                        filteredCashAccounts.map((account) => (
                          <DropdownMenuItem
                            key={account.id}
                            onClick={() =>
                              handleCashAccountSelection(parseInt(account.id))
                            }
                          >
                            {account.name} (Balance: {account.balance})
                          </DropdownMenuItem>
                        ))
                      ) : (
                        <DropdownMenuItem disabled>
                          {t("No cash accounts found")}
                        </DropdownMenuItem>
                      )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              {selectedCashAccount && (
                <div className="mt-2 flex items-center">
                  <span className="selected-cash-account-label ml-2 px-2 py-1 border border-gray-300 rounded">
                    {
                      cashAccounts.find(
                        (account) =>
                          parseInt(account.id) === selectedCashAccount
                      )?.name
                    }
                  </span>
                  <Button
                    variant="ghost"
                    onClick={handleRemoveCashAccount}
                    className="ml-2"
                  >
                    <X className="h-4 w-4 icon" />
                  </Button>
                </div>
              )}
            </div>
            <div className="warehouse-select pb-5">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline">
                    {selectedWarehouse
                      ? "Change Warehouse"
                      : "Select Warehouse"}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="start"
                  className="warehouse-dropdown"
                >
                  <Input placeholder="Search warehouse" className="mb-2" />
                  {warehouses.length > 0 ? (
                    warehouses.map((warehouse) => (
                      <DropdownMenuItem
                        key={warehouse.id}
                        onClick={() =>
                          handleWarehouseSelection(Number(warehouse.id))
                        }
                      >
                        {warehouse.name}
                      </DropdownMenuItem>
                    ))
                  ) : (
                    <DropdownMenuLabel>No warehouses found</DropdownMenuLabel>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
              {selectedWarehouse && (
                <div className="mt-2 flex items-center">
                  <span className="selected-warehouse-label ml-2 px-2 py-1 border border-gray-300 rounded">
                    {selectedWarehouse.name}
                  </span>
                  <Button
                    variant="ghost"
                    onClick={handleRemoveWarehouse}
                    className="ml-2"
                  >
                    <X className="h-4 w-4 icon" />
                  </Button>
                </div>
              )}
            </div>
          </div>
          <Input
            ref={searchInputRef}
            placeholder={t("Search by barcode or name")}
            value={searchQuery}
            onKeyDown={handleKeyPress}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="mb-4"
          />
          {filteredProducts.length > 0 && (
            <div className="filtered-products">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>{t("Product Info")}</TableHead>
                    <TableHead>{t("price")}</TableHead>
                    <TableHead>{t("uom")}</TableHead>
                    <TableHead>{t("Inventory Quantity")}</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredProducts.map((product, index) => {
                    const inventoryItem = inventory.find(
                      (item) => item.productId === product.id
                    );
                    const availableQuantity = inventoryItem
                      ? inventoryItem.quantity
                      : "N/A";
                    const isSelected = index === selectedProductIndex;
                    return (
                      <TableRow
                        key={product.id}
                        className={
                          isSelected
                            ? `selected ${
                                theme === "dark"
                                  ? "text-white bg-blue-800"
                                  : "text-black bg-gray-100"
                              }`
                            : ""
                        }
                        onClick={() => addToOrder(product)}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell>
                          {product.name} ({product.barcode})
                        </TableCell>
                        <TableCell>
                          {product.price} {baseCurrencyName}
                        </TableCell>
                        <TableCell>{getUoMName(product.uoMId)}</TableCell>
                        <TableCell>{availableQuantity}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          )}
          <div className="order-products">
            <h2>{t("Order Products")}</h2>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>{t("Product Info")}</TableHead>
                  <TableHead>{t("quantity")}</TableHead>
                  <TableHead>{t("price")}</TableHead>
                  <TableHead>{t("uom")}</TableHead>
                  <TableHead>{t("total")}</TableHead>
                  <TableHead>{t("discount")}</TableHead>
                  <TableHead>{t("action")}</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {salesOrderProducts.map(
                  ({
                    product,
                    quantity,
                    discount,
                    discountType,
                    isEditing,
                    isAddingDiscount,
                  }) => (
                    <TableRow key={product.id}>
                      <TableCell>
                        <h3>{product.name}</h3>
                      </TableCell>
                      <TableCell>
                        <QuantityInput
                          quantity={quantity}
                          onIncrement={() =>
                            handleProductChange(
                              product.id,
                              "quantity",
                              quantity + 1
                            )
                          }
                          onDecrement={() =>
                            handleProductChange(
                              product.id,
                              "quantity",
                              Math.max(quantity - 1, 1)
                            )
                          }
                          onChange={(value) =>
                            handleProductChange(product.id, "quantity", value)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {isEditing ? (
                          <Input
                            type="number"
                            value={product.price}
                            onChange={(e) =>
                              handleProductChange(
                                product.id,
                                "price",
                                parseFloat(e.target.value)
                              )
                            }
                          />
                        ) : (
                          `${new Intl.NumberFormat("sq-AL", {
                            style: "decimal", // Use decimal to avoid double currency formatting
                          }).format(product.price)} ${baseCurrencyName}`
                        )}
                      </TableCell>

                      <TableCell>{getUoMName(product.uoMId)}</TableCell>
                      <TableCell>
                        {new Intl.NumberFormat("sq-AL", {}).format(
                          discountType === "percentage"
                            ? product.price *
                                quantity *
                                (1 - (discount || 0) / 100)
                            : product.price * quantity - (discount || 0)
                        )}{" "}
                        {baseCurrencyName}
                      </TableCell>
                      <TableCell className="w-24">
                        {isAddingDiscount ? (
                          <div>
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  checked={discountType === "value"}
                                  onChange={() =>
                                    handleProductChange(
                                      product.id,
                                      "discountType",
                                      "value"
                                    )
                                  }
                                />
                                {baseCurrencySymbol}
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  checked={discountType === "percentage"}
                                  onChange={() =>
                                    handleProductChange(
                                      product.id,
                                      "discountType",
                                      "percentage"
                                    )
                                  }
                                />
                                (%)
                              </label>
                            </div>
                            <Input
                              type="text"
                              value={
                                discount !== undefined
                                  ? discountType === "value"
                                    ? `${discount}${baseCurrencySymbol}` // Use baseCurrencySymbol instead of "L"
                                    : `${discount}%`
                                  : ""
                              }
                              onChange={(e) => {
                                const value = e.target.value.replace(
                                  /[^0-9.]/g,
                                  ""
                                );
                                handleProductChange(
                                  product.id,
                                  "discount",
                                  parseFloat(value)
                                );
                              }}
                              placeholder={
                                discountType === "value"
                                  ? baseCurrencySymbol
                                  : "%"
                              } // Use baseCurrencySymbol instead of "L"
                            />

                            {/* If not in editing mode */}
                            {discount !== undefined
                              ? `${discount} ${
                                  discountType === "percentage"
                                    ? "%"
                                    : baseCurrencySymbol
                                }` // Use baseCurrencySymbol instead of "L"
                              : null}
                          </div>
                        ) : discount !== undefined ? (
                          `${discount} ${
                            discountType === "percentage"
                              ? "%"
                              : baseCurrencySymbol
                          }`
                        ) : null}
                      </TableCell>
                      <TableCell>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                              <span className="sr-only">Open menu</span>
                              <MoreHorizontal className="h-4 w-4 icon" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuLabel>{t("action")}</DropdownMenuLabel>
                            <DropdownMenuItem
                              onClick={() => openDiscountDialog(product)}
                            >
                              {t("AddDiscount")}
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              onClick={() => handleEditToggle(product.id)}
                            >
                              {isEditing ? t("savelbtn") : t("edit")}
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              onClick={() => removeFromOrder(product.id)}
                            >
                              {t("delete")}
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </div>
          <div
            className={`order-summary ${
              theme === "dark"
                ? "text-white bg-black"
                : "text-black bg-gray-100"
            }`}
          >
            <div className="summary-row">
              <span>Lines</span>
              <span>{salesOrderProducts.length}</span>
            </div>
            <div className="summary-row">
              <span>Subtotal</span>
              <span>
                {subtotal}
                {baseCurrencySymbol}{" "}
              </span>
            </div>
            <div className="summary-row">
              <span>INLINE DISCOUNT</span>
              <span>
                {totalProductDiscounts}
                {baseCurrencySymbol}{" "}
              </span>
            </div>
            <div className="summary-row">
              <span>DISCOUNT FROM TOTAL PRICE</span>
              <span>
                {totalOrderDiscount}
                {baseCurrencySymbol}{" "}
              </span>
            </div>
            <div className="summary-row">
              <span>TOTAL DISCOUNT</span>
              <span>
                {calculateSubtotalAndDiscount().totalDiscountSum}
                {baseCurrencySymbol}{" "}
              </span>
            </div>

            <div className="summary-row">
              <span>TAX</span>
              <span>
                {calculateVAT()}
                {baseCurrencySymbol}{" "}
              </span>
            </div>
            <div className="summary-row amount-due">
              <span>AMOUNT DUE</span>
              <span>
                {calculateTotal()}
                {baseCurrencySymbol}{" "}
              </span>
            </div>
            <div className="summary-row">
              <span>PAYMENTS</span>
              <span>0.00{baseCurrencySymbol}</span>
            </div>
          </div>
        </div>
        <div className="pos-menu">
          <div className="cash-account-balance flex justify-between">
            {cashAccountBalance !== null ? (
              <h3>
                {t("CashAccountBalance")}: {cashAccountBalance.toFixed(2)}
                {baseCurrencySymbol}{" "}
              </h3>
            ) : (
              <h3>{t("NoCashAccountFound")}</h3>
            )}
            <Coins className="icon" onClick={editcashbalance} />
          </div>
          <CheckoutPanel
            totalAmount={parseFloat(calculateTotal())}
            onCheckout={(
              amountReceived: number,
              cashAccountId?: number,
              paymentTermId?: number,
              exchangeRate?: number,
              selectedCurrencyDetails?: {
                id: number;
                name: string;
                symbol: string;
              },
              changeAmount?: number
            ) =>
              handleCheckout(
                amountReceived,
                handlePrint,
                cashAccountId,
                paymentTermId,
                exchangeRate,
                selectedCurrencyDetails,
                changeAmount
              )
            }
            onVoidInvoice={handleVoidInvoice}
            orderProducts={salesOrderProducts}
            customers={transformedCustomers}
            selectedCustomer={selectedCustomer?.toString() || ""}
            calculateSubtotalAndDiscount={calculateSubtotalAndDiscount}
            calculateVAT={calculateVAT}
            calculateTotal={calculateTotal}
            getVATRateName={getVATRateName}
            getVATRate={getVATRate}
            openDialogOnCheckout={true}
            disabled={cashAccountBalance === null}
            employeeID={employeeId}
            selectedPaymentMethod={parseInt(selectedPaymentMethod)}
            onTotalDiscountChange={handleTotalDiscountChange}
            orderType="salesOrder"
          />
          <NumericKeypad
            onInput={handleNumericInput}
            onClear={handleClearInput}
            onBackspace={handleBackspace}
            showDisplay={false}
            showPay={false}
            showPoint={false}
            amountReceived={""}
            onEnter={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </div>
      </div>
      <div style={{ display: "none" }}>
        <InvoiceThermalPrint
          printerType={printerType}
          ref={invoiceRef}
          orderProducts={salesOrderProducts}
          customers={transformedCustomers}
          selectedCustomer={selectedCustomer?.toString() || ""}
          calculateSubtotalAndDiscount={calculateSubtotalAndDiscount}
          calculateVAT={calculateVAT}
          calculateTotal={calculateTotal}
          getVATRateName={getVATRateName}
          getVATRate={getVATRate}
          orderNo={orderNo}
          tableId={""}
          tablename={""}
          employeeName={""}
          selectedCurrencyDetails={null}
          totalInSelectedCurrency={""}
          exchangeRate={selectedExchangeRate}
          date={new Date().toLocaleDateString()}
        />
      </div>
      {currentProductForDiscount && (
        <DiscountDialog
          isOpen={isDiscountDialogOpen}
          onClose={() => setIsDiscountDialogOpen(false)}
          onSave={handleDiscountSave}
        />
      )}

      <ToastContainer />
    </div>
  );
};

export default SalesOrder;
