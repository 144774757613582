import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@/css/LoginPage.css";
import API_URLS from "@/config";
import { Link } from "react-router-dom";

const ForgetUsernamePage: React.FC = () => {
  const methods = useForm({ mode: "onSubmit" });
  const { t } = useTranslation();
  const [username, setUsername] = useState<string | null>(null);

  const handleFetchUsername = async () => {
    const hostname = window.location.hostname.split(".")[0]; // Extract the subdomain
    try {
      const response = await axios.get(
        `${API_URLS.BASE_URL}${API_URLS.forgetUsername}`,
        {
          params: { subdomain: hostname },
        }
      );

      const fetchedUsername = response.data;
      setUsername(fetchedUsername);
    } catch (error) {
      console.error("Error fetching username:", error);
      toast.error(t("An unexpected error occurred"));
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="login-container">
        <div className="login-form">
          <div className="avatar">👤</div>
          <div className="forgot-username-link">
            <button onClick={handleFetchUsername} className="button">
              {t("retrieveUsername")}
            </button>
          </div>
          {username && (
            <div className="username-display">
              {t("yourUsernameIs")}: {username}
            </div>
          )}
          <div className="forgot-username-link">
            <Link to="/login">{t("backtologin")}</Link>
          </div>
          <ToastContainer />
        </div>
      </div>
    </FormProvider>
  );
};

export default ForgetUsernamePage;
