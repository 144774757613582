import React from "react";
import AuthProvider from "@/provider/authProvider";
import { SelectedProductsProvider } from "./provider/SelectedProductsContext";
import AppRoutes from "./routes";

const App: React.FC = () => {
  return (
    <SelectedProductsProvider>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </SelectedProductsProvider>
  );
};

export default App;
