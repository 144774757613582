import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Input } from "@/components/ui/input";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import CheckoutPanel from "@/componentsPos/checkoutPanel";
import NumericKeypad from "@/componentsPos/numericKeyboard";
import "@/css/salesorder.css";
import { Product } from "@/types/ProductType";
import { MoreHorizontal, X } from "lucide-react";
import CreatePurchaseOrderDto from "@/models/CreatePurchaseOrderDto";
import PurchaseStatus from "@/models/PurchaseStatus";
import API_URLS from "@/config";
import QuantityInput from "@/componentsPos/QuantityInput";
import SupplierForm from "../supplier/SupplierForm";
import debounce from "lodash/debounce";
import InvoicePrint from "@/componentsPos/InvoicePrint";
import { useReactToPrint } from "react-to-print";
// import { useAuth } from '@/provider/authProvider';
import { SupplierType } from "@/types/SupplierType";
import { UomType } from "@/types/UomType";
import { VATType } from "@/types/VatType";
import { Warehouse } from "@/types/WarehouseType";
// import { PaymentTerms } from "@/types/PaymentTermsType";
import { Paymentmethods } from "@/types/PaymentMethdosType";
import { InventoryType } from "@/types/InventoryType";
import DataFetcher from "@/provider/DataFetcher";
import { toast, ToastContainer } from "react-toastify";
import "@/css/LoadingCreate.css";
import { Lot } from "@/types/LotType";
import { usePurchaseOrderProducts } from "@/provider/SelectedProductsContext";
import { CashAccount } from "@/types/CashAccountType";
import { useAuth } from "@/provider/authProvider";
import { useTheme } from "@/componentsPos/ThemeContext";

interface BusinessConfigs {
  allowNegativeInventory: boolean;
  baseCurrencyId: number;
  secondCurrencyId: number; // Added second currency ID
  printerType: number;
}

// Define TypeScript interface for a Currency
interface Currency {
  id: number;
  name: string;
  symbol: string;
}

const PurchaseOrder: React.FC = () => {
  const PRODUCT_STORAGE_KEY = "productData";
  const SUPPLIER_STORAGE_KEY = "supplierData";
  const UOM_STORAGE_KEY = "uomData";
  const VAT_STORAGE_KEY = "vatData";
  const PAYMENT_METHOD_STORAGE_KEY = "paymentMethodData";
  const Lot_STORAGE_KEY = "lotData";
  const INVENTORY_STORAGE_KEY = "inventoryData";
  const Currency_STORAGE_KEY = "currencyData";
  const CashAccount_STORAGE_KEY = "cashAccountData";
  const WAREHOUSE_STORAGE_KEY = "warehouseData";

  const [productList, setProductList] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [loading, setLoading] = useState(false); // Initialize loading state
  const barcodeInputRef = useRef<string>(""); // Reference to store barcode input
  const [isScanning, setIsScanning] = useState(false);
  const [, setIsInvalidBarcode] = useState(false);
  const { purchaseOrderProducts, setPurchaseOrderProducts } =
    usePurchaseOrderProducts();
  const [suppliers, setSuppliers] = useState<SupplierType[]>([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState<SupplierType[]>(
    []
  );
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse | null>(
    null
  );
  const [selectedSupplier, setSelectedSupplier] = useState<number | null>(null);
  const [uomList, setUomList] = useState<UomType[]>([]);
  const [vatList, setVatList] = useState<VATType[]>([]);
  const [selectedLot, setSelectedLot] = useState<string>("");
  const [filteredLots, setFilteredLots] = useState<Lot[]>([]);
  const [lots, setLots] = useState<Lot[]>([]);
  // const [paymentTerms, setPaymentTerms] = useState<PaymentTerm[]>([]);
  // const [filteredPaymentTerms, setFilteredPaymentTerms] = useState<PaymentTerm[]>([]);
  // const [selectedPaymentTerm, setSelectedPaymentTerm] = useState<string>('');
  const [paymentMethods, setPaymentMethods] = useState<Paymentmethods[]>([]);
  const [filteredPaymentMethods, setFilteredPaymentMethods] = useState<
    Paymentmethods[]
  >([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>("");
  // const [employeeID, setEmployeeID] = useState<number | null>(null);
  const [inventory, setInventory] = useState<InventoryType[]>([]);
  const [isSupplierDialogOpen, setIsSupplierDialogOpen] = useState(false);
  const [purchaseStatus, setPurchaseStatus] = useState<PurchaseStatus>(
    PurchaseStatus.Completed
  );
  const [baseCurrencyName, setBaseCurrencyName] = useState("Lekë"); // Default currency
  const [baseCurrencySymbol, setBaseCurrencySymbol] = useState("L"); // Default currency symbol
  const [cashAccounts, setCashAccounts] = useState<CashAccount[]>([]);
  const [filteredCashAccounts, setFilteredCashAccounts] = useState<
    CashAccount[]
  >([]);
  const [selectedCashAccount, setSelectedCashAccount] = useState<number | null>(
    null
  );
  const [selectedExchangeRate, setSelectedExchangeRate] = useState<
    number | null
  >(null);
  const [cashAccountBalance, setCashAccountBalance] = useState<number>(0);
  const [, setCurrencies] = useState<Currency[]>([]); // Holds list of currencies
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const { employeeId } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const invoiceRef = useRef<HTMLDivElement>(null);
  const { theme } = useTheme();
  // const { employeeId } = useAuth();
  const [printerType, setPrinterType] = useState<number>(() => {
    const businessConfig = JSON.parse(
      localStorage.getItem("businessConfigs") || "{}"
    );
    return businessConfig?.printerType;
  });

  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });

  const handleCloseDialogSupplier = (newSupplier?: {
    id: number;
    name: string;
  }) => {
    setIsSupplierDialogOpen(false);
    if (newSupplier) {
      setSuppliers((prevSuppliers) => [...prevSuppliers, newSupplier]);
      setSelectedSupplier(newSupplier.id);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await DataFetcher.getData<Product[]>(
        `${API_URLS.BASE_URL}${API_URLS.PRODUCT}`,
        PRODUCT_STORAGE_KEY
      );
      if (response) {
        setProductList(response);
      }
    };

    const fetchSuppliers = async () => {
      const response = await DataFetcher.getData<SupplierType[]>(
        `${API_URLS.BASE_URL}${API_URLS.SUPPLIER}`,
        SUPPLIER_STORAGE_KEY
      );
      if (response) {
        setSuppliers(response);
        setFilteredSuppliers(response);
      }
    };

    const fetchUoMs = async () => {
      const response = await DataFetcher.getData<UomType[]>(
        `${API_URLS.BASE_URL}${API_URLS.UOM}`,
        UOM_STORAGE_KEY
      );
      if (response) {
        setUomList(response);
      }
    };

    const fetchVatRates = async () => {
      const response = await DataFetcher.getData<VATType[]>(
        `${API_URLS.BASE_URL}${API_URLS.VAT}`,
        VAT_STORAGE_KEY
      );
      if (response) {
        setVatList(response);
      }
    };
    // const fetchPaymentTerms = async () => {
    //   try {
    //     const response = await axios.get<PaymentTerm[]>(`${API_URLS.BASE_URL}${API_URLS.PaymentTerms}`);
    //     setPaymentTerms(response.data);
    //     setFilteredPaymentTerms(response.data);
    //   } catch (error) {
    //     console.error('Error fetching payment terms:', error);
    //   }
    // };

    const fetchPaymentMethods = async () => {
      const response = await DataFetcher.getData<Paymentmethods[]>(
        `${API_URLS.BASE_URL}${API_URLS.PaymentMethod}`,
        PAYMENT_METHOD_STORAGE_KEY
      );
      if (response) {
        setPaymentMethods(response);
        setFilteredPaymentMethods(response);
      }
    };

    const fetchInventory = async () => {
      const response = await DataFetcher.getData<InventoryType[]>(
        `${API_URLS.BASE_URL}${API_URLS.INVENTORY}`,
        INVENTORY_STORAGE_KEY
      );
      if (response) {
        setInventory(response);
      }
    };

    const fetchLot = async () => {
      const response = await DataFetcher.getData<Lot[]>(
        `${API_URLS.BASE_URL}${API_URLS.LOT}`,
        Lot_STORAGE_KEY
      );
      if (response) {
        setLots(response);
        setFilteredLots(response);
      }
    };

    const fetchWarehouses = async () => {
      const response = await DataFetcher.getData<Warehouse[]>(
        `${API_URLS.BASE_URL}${API_URLS.WAREHOUSE}`,
        WAREHOUSE_STORAGE_KEY
      );
      if (response) {
        setWarehouses(response);
        setSelectedWarehouse(response[0] || null);
      }
    };

    fetchVatRates();
    fetchProducts();
    fetchSuppliers();
    fetchUoMs();
    // fetchPaymentTerms();
    fetchPaymentMethods();
    fetchInventory();
    fetchLot();
    fetchWarehouses();
  }, []);

  //  useEffect(() => {
  //   if (employeeName) {
  //     const fetchEmployeeID = async () => {
  //       try {
  //         const response = await axios.get(`${API_URLS.BASE_URL}${API_URLS.EMPLOYEE}`);
  //         if (response.data && response.data.length > 0) {
  //           const employeeID = response.data[0].id;
  //           setEmployeeID(employeeID)
  //           console.log('Fetched Employee ID:', employeeID);
  //         }
  //       } catch (error) {
  //         console.error('Error fetching employee ID:', error);
  //       }
  //     };

  //     fetchEmployeeID();
  //   }
  // }, [employeeName]);

  useEffect(() => {
    if (employeeId !== null) {
      const parsedEmployeeId = parseInt(employeeId, 10);
      if (!isNaN(parsedEmployeeId)) {
        const fetchCashAccounts = async () => {
          try {
            const response = await DataFetcher.getData<CashAccount[] | null>(
              `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
              CashAccount_STORAGE_KEY,
              true
            );

            if (response && Array.isArray(response)) {
              const ownedCashAccounts = response.filter(
                (account) =>
                  account.owners.some(
                    (owner: { id: number }) => owner.id === parsedEmployeeId
                  ) && account.isBank === false
              );
              console.log(ownedCashAccounts);
              setCashAccounts(ownedCashAccounts);
              setFilteredCashAccounts(ownedCashAccounts);
            }
          } catch (error) {
            toast.error("Failed to fetch cash accounts");
            console.error(error);
          }
        };

        fetchCashAccounts();
      }
    }
  }, [employeeId]);
  useEffect(() => {
    const storedCashAccount = localStorage.getItem(
      "selectedCashAccountPurchase"
    );

    if (storedCashAccount) {
      const savedAccount = JSON.parse(storedCashAccount);
      setSelectedCashAccount(savedAccount.id);
      setCashAccountBalance(savedAccount.balance);
    }
  }, [cashAccounts]);

  useEffect(() => {
    // Load the saved warehouse from localStorage on component mount
    const storedWarehouse = localStorage.getItem("selectedWarehouse");
    if (storedWarehouse) {
      setSelectedWarehouse(JSON.parse(storedWarehouse));
    }
  }, [warehouses]);
  const handleCashAccountSelection = (accountId: number) => {
    setSelectedCashAccount(accountId);

    const selectedAccount = cashAccounts.find(
      (account) => parseInt(account.id) === accountId
    );

    if (selectedAccount) {
      setCashAccountBalance(selectedAccount.balance);
      // Save selected cash account to localStorage
      localStorage.setItem(
        "selectedCashAccountPurchase",
        JSON.stringify(selectedAccount)
      );
    }
  };
  const handleWarehouseSelection = (warehouseId: number) => {
    const selected = warehouses.find(
      (warehouse) => Number(warehouse.id) === warehouseId
    );
    if (selected) {
      setSelectedWarehouse(selected);
      localStorage.setItem("selectedWarehouse", JSON.stringify(selected));
    }
  };

  const debouncedSetFilteredProducts = debounce((query: string) => {
    if (query === "") {
      setFilteredProducts([]);
      setSelectedProductIndex(0);
    } else {
      const results = productList.filter(
        (product) =>
          product.name.toLowerCase().includes(query.toLowerCase()) ||
          (product.barcode && product.barcode.includes(query))
      );
      setFilteredProducts(results);
      setSelectedProductIndex(0);
    }
  }, 500);

  useEffect(() => {
    debouncedSetFilteredProducts(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    if (
      filteredProducts.length > 0 &&
      selectedProductIndex >= filteredProducts.length
    ) {
      setSelectedProductIndex(filteredProducts.length - 1);
    }
  }, [filteredProducts, selectedProductIndex]);

  const handleSupplierSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    const results = suppliers.filter((supplier) =>
      supplier.name.toLowerCase().includes(value)
    );
    setFilteredSuppliers(results);
  };
  useEffect(() => {
    // Fetch available currencies using DataFetcher with caching
    const fetchCurrencyData = async () => {
      try {
        const response = await DataFetcher.getData<Currency[]>(
          `${API_URLS.BASE_URL}${API_URLS.Currency}`, // API endpoint
          Currency_STORAGE_KEY // LocalStorage key
        );
        setCurrencies(response || []); // Set fetched currencies, default to an empty array if none

        // Find and set base currency name and symbol
        const businessConfigs: BusinessConfigs = JSON.parse(
          localStorage.getItem("businessConfigs") || "{}"
        );
        const baseCurrencyId = businessConfigs.baseCurrencyId ?? 1; // Default to 1 if not set
        const baseCurrency = response?.find(
          (currency) => currency.id === baseCurrencyId
        );

        setBaseCurrencyName(baseCurrency?.name || "Leke"); // Set base currency name, default to "Leke"
        setBaseCurrencySymbol(baseCurrency?.symbol || "L"); // Set base currency symbol, default to "L"
      } catch (error) {
        console.error("Error fetching currencies:", error);
        toast.error(t("Failed to load currencies"));
      }
    };

    fetchCurrencyData();
  }, [t]);

  // const handlePaymentTermSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value.toLowerCase();
  //   const results = paymentTerms.filter(term =>
  //     term.name.toLowerCase().includes(value)
  //   );
  //   setFilteredPaymentTerms(results);
  // };

  const handlePaymentMethodSearch = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value.toLowerCase();
    const results = paymentMethods.filter((method) =>
      method.name.toLowerCase().includes(value)
    );
    setFilteredPaymentMethods(results);
  };

  const handleCashAccountSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    const results = cashAccounts.filter((account) =>
      account.name.toLowerCase().includes(value)
    );
    setFilteredCashAccounts(results);
  };
  const handleLotSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    const results = lots.filter((lot) =>
      lot.lotNumber.toLowerCase().includes(value)
    );
    setFilteredLots(results);
  };
  useEffect(() => {
    if (location.state?.selectedProducts) {
      addSelectedProducts(location.state.selectedProducts);
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state, navigate]);

  const addSelectedProducts = (newSelectedProducts: Product[]) => {
    setPurchaseOrderProducts((prevOrderProducts) => {
      const updatedOrderProducts = [...prevOrderProducts];

      newSelectedProducts.forEach((newProduct) => {
        const existingProductIndex = updatedOrderProducts.findIndex(
          (p) => p.product.id === newProduct.id
        );

        if (existingProductIndex === -1) {
          updatedOrderProducts.push({
            product: newProduct,
            quantity: 1,
            isEditing: false,
            isAddingDiscount: false,
          });
        }
      });

      return updatedOrderProducts;
    });
  };
  const addToOrder = (product: Product) => {
    setPurchaseOrderProducts((prevProducts) => {
      const existingProduct = prevProducts.find(
        (p) => p.product.id === product.id
      );

      const updatedProducts = existingProduct
        ? prevProducts.map((p) =>
            p.product.id === product.id ? { ...p, quantity: p.quantity + 1 } : p
          )
        : [
            ...prevProducts,
            {
              product,
              quantity: 1,
              discountType: "value" as "value" | "percentage",
            },
          ];
      return updatedProducts;
    });

    setSearchQuery("");
    setFilteredProducts([]);
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };
  const handleSupplierSelection = (supplierId: number) => {
    setSelectedSupplier(supplierId);

    const selectedSupplier = suppliers.find(
      (supplier) => supplier.id === supplierId
    );
    if (selectedSupplier) {
      localStorage.setItem(
        "selectedSupplierPurchase",
        JSON.stringify(selectedSupplier)
      );
    }
  };
  useEffect(() => {
    const storedSupplier = localStorage.getItem("selectedSupplierPurchase");

    if (storedSupplier) {
      const savedSupplier = JSON.parse(storedSupplier);
      setSelectedSupplier(savedSupplier.id);
    }
  }, [suppliers]);
  const handlePaymentMethodSelection = (methodId: string) => {
    const selectedPaymentMethod = paymentMethods.find(
      (method) => method.id === methodId
    );

    if (selectedPaymentMethod) {
      setSelectedPaymentMethod(methodId);
      if (!selectedPaymentMethod.isCash) {
        setSelectedCashAccount(null);
        setCashAccountBalance(0);
      }
      localStorage.removeItem("selectedCashAccountPurchase");

      localStorage.setItem(
        "selectedPaymentMethodPurchase",
        JSON.stringify(selectedPaymentMethod)
      );
    }
  };

  useEffect(() => {
    const storedPaymentMethod = localStorage.getItem(
      "selectedPaymentMethodPurchase"
    );

    if (storedPaymentMethod) {
      const savedPaymentMethod = JSON.parse(storedPaymentMethod);
      setSelectedPaymentMethod(savedPaymentMethod.id);

      if (!savedPaymentMethod.isCash) {
        setSelectedCashAccount(null);
      }
    }
  }, [paymentMethods]);

  const removeFromOrder = (productId: string) => {
    setPurchaseOrderProducts((prevProducts) =>
      prevProducts.filter((p) => p.product.id !== productId)
    );
  };

  const calculateSubtotalAndDiscount = () => {
    let subtotal = 0;
    let totalProductDiscounts = 0; // This will represent the product-specific discounts
    const totalOrderDiscount = 0; // This will represent an overall order discount if applicable
    let totalVAT = 0;
    let SubTotal = 0;

    purchaseOrderProducts.forEach(
      ({ product, quantity, discount, discountType }) => {
        const price = product.cost * quantity;
        let discountedPrice = price;

        if (discount !== undefined) {
          if (discountType === "percentage") {
            discountedPrice = price * (1 - discount / 100);
          } else {
            discountedPrice = price - discount;
          }
        }

        subtotal += discountedPrice;
        totalProductDiscounts += price - discountedPrice;

        const vatRate =
          vatList.find((v) => v.id === product.vatRateId)?.rate || 0;
        totalVAT += discountedPrice * (vatRate / 100);

        SubTotal = subtotal - totalVAT;
      }
    );

    const totalDiscountSum = (
      totalProductDiscounts + totalOrderDiscount
    ).toFixed(2); // Sum of all discounts

    return {
      subtotal: SubTotal.toFixed(2),
      totalOrderDiscount: totalOrderDiscount.toFixed(2), // If there is any overall order discount
      totalProductDiscounts: totalProductDiscounts.toFixed(2), // Sum of product-specific discounts
      totalDiscountSum, // Sum of both product and order discounts
    };
  };

  const calculateVAT = () => {
    const vatAmounts = purchaseOrderProducts.map(
      ({ product, quantity, discount, discountType }) => {
        const vatRate =
          vatList.find((v) => v.id === product.vatRateId)?.rate || 0;
        const price = product.cost * quantity;
        let discountedPrice = price;

        if (discount !== undefined) {
          if (discountType === "percentage") {
            discountedPrice = price * (1 - discount / 100);
          } else {
            discountedPrice = price - discount;
          }
        }

        return discountedPrice * (vatRate / 100);
      }
    );

    const totalVAT = vatAmounts.reduce((sum, vat) => sum + vat, 0);

    return totalVAT.toFixed(2);
  };

  const calculateTotal = () => {
    const { subtotal } = calculateSubtotalAndDiscount();
    const vat = parseFloat(calculateVAT());
    const total = parseFloat(subtotal) + vat;
    return total.toFixed(2);
  };

  const fetchCashAccounts = async (parsedEmployeeId: number) => {
    try {
      const response = await DataFetcher.getData<CashAccount[] | null>(
        `${API_URLS.BASE_URL}${API_URLS.CashAccount}`,
        CashAccount_STORAGE_KEY,
        true
      );

      if (response && Array.isArray(response)) {
        const ownedCashAccounts = response.filter(
          (account) =>
            account.owners.some(
              (owner: { id: number }) => owner.id === parsedEmployeeId
            ) && account.isBank === false
        );
        setCashAccounts(ownedCashAccounts);
        setFilteredCashAccounts(ownedCashAccounts);
      }
    } catch (error) {
      toast.error("Failed to fetch cash accounts");
      console.error(error);
    }
  };

  const handleCheckout = async (
    amountReceived: number,
    printInvoice: () => void,
    cashAccountId?: number,
    paymentTermId?: number,
    exchangeRate?: number,
    selectedCurrencyDetails?: { id: number; name: string; symbol: string },
    changeAmount?: number
  ) => {
    if (employeeId !== null) {
      const parsedEmployeeId = parseInt(employeeId, 10);
      const selectedPaymentMethodInt = parseInt(selectedPaymentMethod, 10);
      setLoading(true); // Show loading spinner
      setSelectedExchangeRate(exchangeRate || null);
      const isCashPayment = paymentMethods.find(
        (method) => method.id === selectedPaymentMethod
      )?.isCash;

      let amountDue = 0;

      if (isCashPayment) {
        if (changeAmount !== undefined && changeAmount < 0) {
          amountDue = Math.abs(changeAmount);
        } else {
          amountDue = 0;
        }
      }

      const dto = new CreatePurchaseOrderDto({
        deliveryDateUtc: new Date().toISOString(),
        purchaseStatus,
        notes: "",
        supplierId: selectedSupplier || undefined,
        cashAccountId: selectedCashAccount || cashAccountId || 0,
        vatAmount: parseFloat(calculateVAT()),
        customCharges: 0,
        shippingCharges: 0,
        warehouseId: selectedWarehouse
          ? parseInt(selectedWarehouse.id.toString(), 10)
          : 0,
        // employeeId: parsedEmployeeId ?? 0,
        adjustedCharges: 0,
        totalCost: parseFloat(calculateTotal()),
        amountDue: amountDue,
        PaymentExchangeRate: exchangeRate,
        PaymentCurrencyId: selectedCurrencyDetails?.id,
        PaymentAmount: amountReceived,
        paymentTermsId: paymentTermId,
        paymentMethodId: selectedPaymentMethodInt,
        shopId: 1,
        purchaseOrderLines: purchaseOrderProducts.map(
          ({ product, quantity }) => ({
            productId: parseInt(product.id),
            price: product.cost,
            quantity,
            uoMId: product.uoMId,
            // lotId: 15,
            lineVatAmount:
              (product.cost *
                quantity *
                (vatList.find((v) => v.id === product.vatRateId)?.rate || 0)) /
              100,
            lineVatRateId: product.vatRateId,
            lineTotal: product.cost * quantity,
            lineNote: "",
          })
        ),
      });

      try {
        const response = await axios.post(
          `${API_URLS.BASE_URL}${API_URLS.PURCHASEORDER}`,
          dto
        );
        console.log("Order created successfully", response.data);
        await updateCashAccountBalance(
          parseFloat(calculateTotal()),
          amountReceived,
          changeAmount
        );
        await fetchCashAccounts(parsedEmployeeId);
        printInvoice();
        resetSalesOrder();
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const { status, data } = error.response;

          // Handle different HTTP status codes and response data
          if (status === 403) {
            toast.error(
              t("You do not have permission to perform this action.")
            );
          } else if (status === 500) {
            toast.error(t("A server error occurred. Please try again later."));
          } else if (data && Array.isArray(data)) {
            data.forEach((err: { errorMessage: string }) => {
              const translatedMessage =
                t(`errorPurchaseOrders.${err.errorMessage}`) ||
                t("errorPurchaseOrders.An error occurred");
              toast.error(translatedMessage);
            });
          } else {
            const defaultMessage = t("An error occurred");
            toast.error(defaultMessage);
          }
        } else {
          toast.error(t("An unexpected error occurred"));
        }
        console.error("Error creating order:", error);
      } finally {
        setLoading(false); // Hide loading spinner
      }
    }
  };

  const resetSalesOrder = () => {
    setPurchaseOrderProducts([]);
    setPurchaseStatus(PurchaseStatus.Completed);
    setSearchQuery("");
    setFilteredProducts([]);
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
  };

  const updateCashAccountBalance = async (
    salesAmount: number,
    amountReceived: number,
    changeAmount: number | undefined
  ) => {
    if (!selectedCashAccount) {
      console.error("No cash account selected");
      return;
    }

    try {
      const selectedAccount = cashAccounts.find(
        (account) => parseInt(account.id) === selectedCashAccount
      );

      console.log(salesAmount);
      console.log(amountReceived);
      console.log(changeAmount);

      if (selectedAccount) {
        let updatedBalance: number = selectedAccount.balance;

        if (changeAmount !== undefined) {
          if (changeAmount >= 0) {
            updatedBalance -= salesAmount;
          } else if (changeAmount < 0) {
            updatedBalance -= amountReceived;
          }
        } else {
          console.warn("changeAmount is undefined");
        }

        setCashAccountBalance(updatedBalance);

        const response = await axios.put(
          `${API_URLS.BASE_URL}${API_URLS.CashAccount}/${selectedCashAccount}`,
          {
            ...selectedAccount,
            balance: updatedBalance,
          }
        );

        const updatedAccount = { ...selectedAccount, balance: updatedBalance };
        localStorage.setItem(
          "selectedCashAccountPurchase",
          JSON.stringify(updatedAccount)
        );

        console.log("Cash account balance updated successfully", response.data);
      }
    } catch (error) {
      console.error("Error updating cash account balance:", error);
      toast.error("Error updating cash account balance");
    }
  };

  const getUoMName = (uomId: number) => {
    const uom = uomList.find((u) => u.id === uomId);
    return uom ? uom.name : "";
  };

  const getVATRate = (vatRateId: number) => {
    const vatRate = vatList.find((v) => v.id === vatRateId);
    return vatRate ? vatRate.rate : 0;
  };

  const getVATRateName = (vatRateId: number) => {
    const vatRate = vatList.find((v) => v.id === vatRateId);
    return vatRate ? vatRate.name : "";
  };

  // const handleDiscountTypeChange = (productId: string, type: 'value' | 'percentage') => {
  //   setOrderProducts(prevProducts =>
  //     prevProducts.map(p =>
  //       p.product.id === productId ? { ...p, discountType: type } : p
  //     )
  //   );
  // };

  // const handleDiscountValueChange = (productId: string, value: number) => {
  //   setOrderProducts(prevProducts =>
  //     prevProducts.map(p =>
  //       p.product.id === productId ? { ...p, discount: value } : p
  //     )
  //   );
  // };

  const handleEditToggle = (productId: string) => {
    setPurchaseOrderProducts((prevProducts) =>
      prevProducts.map((p) =>
        p.product.id === productId ? { ...p, isEditing: !p.isEditing } : p
      )
    );
  };

  const handleProductChange = (productId: string, key: string, value: any) => {
    setPurchaseOrderProducts((prevProducts) =>
      prevProducts.map((p) => {
        if (p.product.id === productId) {
          if (key in p.product) {
            return {
              ...p,
              product: {
                ...p.product,
                [key]: value,
              },
            };
          } else {
            return {
              ...p,
              [key]: value,
            };
          }
        }
        return p;
      })
    );
  };

  const handleRemoveCustomer = () => {
    setSelectedSupplier(null);
  };

  // const handleRemovePaymentTerm = () => {
  //   setSelectedPaymentTerm('');
  // };

  const handleRemovePaymentMethod = () => {
    setSelectedPaymentMethod("");
  };
  const handleRemoveCashAccount = () => {
    setSelectedCashAccount(null);
    setCashAccountBalance(0);
    // Remove selected cash account from localStorage
    localStorage.removeItem("selectedCashAccountPurchase");
  };

  const handleRemoveLot = () => {
    setSelectedLot("");
  };

  const handleRemoveWarehouse = () => {
    setSelectedWarehouse(null);
    localStorage.removeItem("selectedWarehouse");
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && filteredProducts.length > 0) {
      addToOrder(filteredProducts[selectedProductIndex]);
    }
  };
  const handleBarcodeInput = (event: KeyboardEvent) => {
    if (isScanning) return;

    if (event.key === "Enter") {
      const barcode = barcodeInputRef.current.trim();

      const isNumericBarcode = /^\d+$/.test(barcode);
      const isValidLength = barcode.length > 8;

      if (barcode === "" || !isNumericBarcode) {
        barcodeInputRef.current = "";
        setIsScanning(false);
        return;
      }

      setIsScanning(true);

      const product = productList.find(
        (product) => product.barcode === barcode
      );

      barcodeInputRef.current = "";

      if (product) {
        addToOrder(product);
        setIsInvalidBarcode(false);
        setIsScanning(false);
        return;
      }

      if (isNumericBarcode && isValidLength) {
        toast.error("Product not found!");
        setIsInvalidBarcode(true);
      }

      setIsScanning(false);
    } else {
      if (event.key === "Backspace") {
        barcodeInputRef.current = "";
        setIsInvalidBarcode(false);
      } else {
        barcodeInputRef.current += event.key;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleBarcodeInput);
    return () => {
      window.removeEventListener("keydown", handleBarcodeInput);
    };
  }, [productList, inventory]);

  // const { subtotal, totalDiscount } = calculateSubtotalAndDiscount();
  const { subtotal } = calculateSubtotalAndDiscount();

  const handleNumericInput = (value: string) => {
    if (searchInputRef.current) {
      searchInputRef.current.value += value;
      setSearchQuery(searchInputRef.current.value);
    }
  };

  const handleClearInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
      setSearchQuery("");
    }
  };
  const handleBackspace = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = searchInputRef.current.value.slice(0, -1);
      setSearchQuery(searchInputRef.current.value);
    }
  };

  const handleVoidInvoice = () => {
    setPurchaseOrderProducts([]);
  };

  const transformedSuppliers = suppliers.map((supplier) => ({
    id: supplier.id.toString(),
    name: supplier.name,
  }));

  return (
    <div className="pos-display">
      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
      <div className="pos-body">
        <div className="pos-add">
          <div className="selector flex justify-between items-baseline">
            <div className="printer-type-select">
              <label>
                <input
                  type="radio"
                  value="2"
                  checked={printerType === 2}
                  onChange={() => setPrinterType(2)}
                />
                80mm
              </label>
              <label>
                <input
                  type="radio"
                  value="1"
                  checked={printerType === 1}
                  onChange={() => setPrinterType(1)}
                />
                A4
              </label>
            </div>
            <div className="supplier-select pb-5">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline">
                    {selectedSupplier
                      ? t("changesupplier")
                      : t("Select a supplier")}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="start"
                  className="supplier-dropdown"
                >
                  <Input
                    placeholder={t("Search a supplier")}
                    onChange={handleSupplierSearch}
                    className="mb-2"
                  />
                  {filteredSuppliers.length > 0 ? (
                    filteredSuppliers.map((supplier) => (
                      <DropdownMenuItem
                        key={supplier.id}
                        onClick={() => handleSupplierSelection(supplier.id)}
                      >
                        {supplier.name}
                      </DropdownMenuItem>
                    ))
                  ) : (
                    <DropdownMenuLabel>
                      {t("No suppliers found")}
                    </DropdownMenuLabel>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
              <Dialog
                open={isSupplierDialogOpen}
                onOpenChange={setIsSupplierDialogOpen}
              >
                <DialogTrigger asChild>
                  <Button
                    type="button"
                    className="mt-6 bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    +
                  </Button>
                </DialogTrigger>
                <DialogContent>
                  <SupplierForm
                    mode="create"
                    inDialog={true}
                    onClose={handleCloseDialogSupplier}
                  />
                </DialogContent>
              </Dialog>
              {selectedSupplier && (
                <div className="mt-2 flex items-center">
                  <span className="selected-supplier-label ml-2 px-2 py-1 border border-gray-300 rounded">
                    {suppliers.find((s) => s.id === selectedSupplier)?.name}
                  </span>
                  <Button
                    variant="ghost"
                    onClick={handleRemoveCustomer}
                    className="ml-2"
                  >
                    <X className="h-4 w-4 icon" />
                  </Button>
                </div>
              )}
            </div>

            {/* <div className="payment-term-select pb-5">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline">
                    {selectedPaymentTerm ? t("Change payment term") : t("Select a payment term")}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start" className="payment-term-dropdown">
                  <Input
                    placeholder={t("Search payment term")}
                    onChange={handlePaymentTermSearch}
                    className="mb-2"
                  />
                  {filteredPaymentTerms.length > 0 ? (
                    filteredPaymentTerms.map(term => (
                      <DropdownMenuItem
                        key={term.id}
                        onClick={() => setSelectedPaymentTerm(term.id)}
                      >
                        {term.name}
                      </DropdownMenuItem>
                    ))
                  ) : (
                    <DropdownMenuLabel>{t("No payment term found")}</DropdownMenuLabel>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
              {selectedPaymentTerm && (
                <div className="mt-2 flex items-center">
                  <span className="selected-payment-term">{paymentTerms.find(term => term.id === selectedPaymentTerm)?.name}</span>
                  <Button variant="ghost" onClick={handleRemovePaymentTerm} className="ml-2">
                    <X className="h-4 w-4 icon" />
                  </Button>
                </div>
              )}
            </div> */}

            <div className="payment-method-select pb-5">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline">
                    {selectedPaymentMethod
                      ? t("Change payment method")
                      : t("Select a payment method")}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="start"
                  className="payment-method-dropdown"
                >
                  <Input
                    placeholder={t("Search payment method")}
                    onChange={handlePaymentMethodSearch}
                    className="mb-2"
                  />
                  {filteredPaymentMethods.length > 0 ? (
                    filteredPaymentMethods.map((method) => (
                      <DropdownMenuItem
                        key={method.id}
                        onClick={() => handlePaymentMethodSelection(method.id)}
                      >
                        {method.name}
                      </DropdownMenuItem>
                    ))
                  ) : (
                    <DropdownMenuLabel>
                      {t("No payment methods found")}
                    </DropdownMenuLabel>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
              {selectedPaymentMethod && (
                <div className="mt-2 flex items-center">
                  <span className="selected-payment-label ml-2 px-2 py-1 border border-gray-300 rounded">
                    {
                      paymentMethods.find(
                        (method) => method.id === selectedPaymentMethod
                      )?.name
                    }
                  </span>
                  <Button
                    variant="ghost"
                    onClick={handleRemovePaymentMethod}
                    className="ml-2"
                  >
                    <X className="h-4 w-4 icon" />
                  </Button>
                </div>
              )}
            </div>

            {/* <div className="purchase-status-select pb-5">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline">{t("SelectPurchaseStatus")}</Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="start"
                  className="purchase-status-dropdown"
                >
                  {Object.keys(PurchaseStatus)
                    .filter((key) => !isNaN(Number(PurchaseStatus[key as any])))
                    .map((key) => (
                      <DropdownMenuItem
                        key={PurchaseStatus[key as keyof typeof PurchaseStatus]} // Use 'keyof' to ensure the key matches the enum
                        onClick={() =>
                          setPurchaseStatus(
                            PurchaseStatus[key as keyof typeof PurchaseStatus]
                          )
                        }
                      >
                        {key}
                      </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
              </DropdownMenu>
              {purchaseStatus !== undefined && (
                <div className="mt-2 flex items-center">
                  <span className="selected-purchase-status">
                    {PurchaseStatus[purchaseStatus]}
                  </span>
                </div>
              )}
            </div> */}
            <div className="lot-select pb-5 hidden">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline">
                    {selectedLot ? t("Change lot") : t("Select a lot")}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start" className="lot-dropdown">
                  <Input
                    placeholder={t("Search lot")}
                    onChange={handleLotSearch}
                    className="mb-2"
                  />
                  {filteredLots.length > 0 ? (
                    filteredLots.map((lot) => (
                      <DropdownMenuItem
                        key={lot.id}
                        onClick={() => setSelectedLot(lot.id)}
                      >
                        {lot.lotNumber}
                      </DropdownMenuItem>
                    ))
                  ) : (
                    <DropdownMenuLabel>{t("No lots found")}</DropdownMenuLabel>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
              {selectedLot && (
                <div className="mt-2 flex items-center">
                  <span className="selected-lot">
                    {lots.find((l) => l.id === selectedLot)?.lotNumber}
                  </span>
                  <Button
                    variant="ghost"
                    onClick={handleRemoveLot}
                    className="ml-2"
                  >
                    <X className="h-4 w-4 icon" />
                  </Button>
                </div>
              )}
            </div>
            <div className="cash-account-select pb-5">
              {selectedPaymentMethod &&
                paymentMethods.find(
                  (method) => method.id === selectedPaymentMethod
                )?.isCash && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="outline">
                        {selectedCashAccount
                          ? t("ChangeCashAccount")
                          : t("SelectCashAccount")}
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      align="start"
                      className="cash-account-dropdown"
                    >
                      <Input
                        placeholder="Search cash account"
                        onChange={handleCashAccountSearch}
                        className="mb-2"
                      />
                      {filteredCashAccounts.length > 0 ? (
                        filteredCashAccounts.map((account) => (
                          <DropdownMenuItem
                            key={account.id}
                            onClick={() =>
                              handleCashAccountSelection(parseInt(account.id))
                            }
                          >
                            {account.name} (Balance: {account.balance})
                          </DropdownMenuItem>
                        ))
                      ) : (
                        <DropdownMenuItem disabled>
                          No cash accounts found
                        </DropdownMenuItem>
                      )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              {selectedCashAccount && (
                <div className="mt-2 flex items-center">
                  <span className="selected-cash-account-label ml-2 px-2 py-1 border border-gray-300 rounded">
                    {
                      cashAccounts.find(
                        (account) =>
                          parseInt(account.id) === selectedCashAccount
                      )?.name
                    }
                  </span>
                  <Button
                    variant="ghost"
                    onClick={handleRemoveCashAccount}
                    className="ml-2"
                  >
                    <X className="h-4 w-4 icon" />
                  </Button>
                </div>
              )}
            </div>
            <div className="warehouse-select pb-5">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline">
                    {selectedWarehouse
                      ? "Change Warehouse"
                      : "Select Warehouse"}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="start"
                  className="warehouse-dropdown"
                >
                  <Input placeholder="Search warehouse" className="mb-2" />
                  {warehouses.length > 0 ? (
                    warehouses.map((warehouse) => (
                      <DropdownMenuItem
                        key={warehouse.id}
                        onClick={() =>
                          handleWarehouseSelection(Number(warehouse.id))
                        }
                      >
                        {warehouse.name}
                      </DropdownMenuItem>
                    ))
                  ) : (
                    <DropdownMenuLabel>No warehouses found</DropdownMenuLabel>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
              {selectedWarehouse && (
                <div className="mt-2 flex items-center">
                  <span className="selected-warehouse-label ml-2 px-2 py-1 border border-gray-300 rounded">
                    {selectedWarehouse.name}
                  </span>
                  <Button
                    variant="ghost"
                    onClick={handleRemoveWarehouse}
                    className="ml-2"
                  >
                    <X className="h-4 w-4 icon" />
                  </Button>
                </div>
              )}
            </div>
          </div>
          <Input
            ref={searchInputRef}
            placeholder={t("Search by barcode or name")}
            value={searchQuery}
            onKeyDown={handleKeyPress}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="mb-4"
          />
          {filteredProducts.length > 0 && (
            <div className="filtered-products">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>{t("Product Info")}</TableHead>
                    <TableHead>{t("price")}</TableHead>
                    <TableHead>{t("uom")}</TableHead>
                    <TableHead>{t("Inventory Quantity")}</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredProducts.map((product, index) => {
                    const inventoryItem = inventory.find(
                      (item) => item.productId === product.id
                    );
                    const availableQuantity = inventoryItem
                      ? inventoryItem.quantity
                      : "N/A";
                    const isSelected = index === selectedProductIndex;
                    return (
                      <TableRow
                        key={product.id}
                        className={
                          isSelected
                            ? `selected ${
                                theme === "dark"
                                  ? "text-white bg-blue-800"
                                  : "text-black bg-gray-100"
                              }`
                            : ""
                        }
                        onClick={() => addToOrder(product)}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell>
                          {product.name} ({product.barcode})
                        </TableCell>
                        <TableCell>
                          {product.cost} {baseCurrencyName}
                        </TableCell>
                        <TableCell>{getUoMName(product.uoMId)}</TableCell>
                        <TableCell>{availableQuantity}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          )}
          <div className="order-products">
            <h2>{t("Order Products")}</h2>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>{t("Product Info")}</TableHead>
                  <TableHead>{t("quantity")}</TableHead>
                  <TableHead>{t("price")}</TableHead>
                  <TableHead>{t("uom")}</TableHead>
                  <TableHead>{t("total")}</TableHead>
                  {/* <TableHead>{t("Discount")}</TableHead> */}
                  <TableHead>{t("action")}</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {/* {orderProducts.map(({ product, quantity, discount, discountType, isEditing, isAddingDiscount }) => ( */}
                {purchaseOrderProducts.map(
                  ({
                    product,
                    quantity,
                    discount,
                    discountType,
                    isEditing,
                  }) => (
                    <TableRow key={product.id}>
                      <TableCell>
                        <h3>{product.name}</h3>
                      </TableCell>
                      <TableCell>
                        <QuantityInput
                          quantity={quantity}
                          onIncrement={() =>
                            handleProductChange(
                              product.id,
                              "quantity",
                              quantity + 1
                            )
                          }
                          onDecrement={() =>
                            handleProductChange(
                              product.id,
                              "quantity",
                              Math.max(quantity - 1, 1)
                            )
                          }
                          onChange={(value) =>
                            handleProductChange(product.id, "quantity", value)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {isEditing ? (
                          <Input
                            type="number"
                            value={product.cost}
                            onChange={(e) =>
                              handleProductChange(
                                product.id,
                                "cost",
                                parseFloat(e.target.value)
                              )
                            }
                          />
                        ) : (
                          `${new Intl.NumberFormat("sq-AL", {
                            style: "decimal", // Use decimal to avoid double currency formatting
                          }).format(product.cost)} ${baseCurrencyName}`
                        )}
                      </TableCell>
                      <TableCell>{getUoMName(product.uoMId)}</TableCell>
                      <TableCell>
                        {new Intl.NumberFormat("sq-AL", {}).format(
                          discountType === "percentage"
                            ? product.cost *
                                quantity *
                                (1 - (discount || 0) / 100)
                            : product.cost * quantity - (discount || 0)
                        )}{" "}
                        {baseCurrencyName}
                      </TableCell>
                      {/* <TableCell className='w-24'>
                      {isAddingDiscount ? (
                        <div>
                          <div>
                            <label>
                              <input
                                type="radio"
                                checked={discountType === 'value'}
                                onChange={() => handleDiscountTypeChange(product.id, 'value')}
                              />
                             (L)
                            </label>
                            <label>
                              <input
                                type="radio"
                                checked={discountType === 'percentage'}
                                onChange={() => handleDiscountTypeChange(product.id, 'percentage')}
                              />
                             (%)
                            </label>
                          </div>
                          <Input
                            type="text"
                            value={discount !== undefined ? (discountType === 'value' ? `${discount}L` : `${discount}%`) : ''}
                            onChange={(e) => {
                              const value = e.target.value.replace(/[^0-9.]/g, '');
                              handleDiscountValueChange(product.id, parseFloat(value));
                            }}
                            placeholder={discountType === 'value' ? 'L' : '%'}
                          />
                        </div>
                      ) : (
                        discount !== undefined ? `${discount} ${discountType === 'percentage' ? '%' : 'L'}` : null
                      )}
                    </TableCell> */}
                      <TableCell>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0">
                              <span className="sr-only">Open menu</span>
                              <MoreHorizontal className="h-4 w-4 icon" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            <DropdownMenuLabel>{t("action")}</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              onClick={() => handleEditToggle(product.id)}
                            >
                              {isEditing ? t("savelbtn") : t("edit")}
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem
                              onClick={() => removeFromOrder(product.id)}
                            >
                              {t("delete")}
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </div>
          <div
            className={`order-summary ${
              theme === "dark"
                ? "text-white bg-black"
                : "text-black bg-gray-100"
            }`}
          >
            <div className="summary-row">
              <span>{t("Lines")}</span>
              <span>{purchaseOrderProducts.length}</span>
            </div>
            <div className="summary-row">
              <span>{t("Subtotal")}</span>
              <span>
                {subtotal}
                {baseCurrencySymbol}
              </span>
            </div>
            {/* <div className="summary-row">
              <span>{t("DISCOUNTS")}</span>
              <span>{totalDiscount}{currencySymbol}</span>
            </div> */}
            <div className="summary-row">
              <span>{t("TAX")}</span>
              <span>
                {calculateVAT()}
                {baseCurrencySymbol}
              </span>
            </div>
            {/* <div className="summary-row">
              <span>{t("VAT DETAILS")}</span>
              <ul>
                {orderProducts.map(({ product }) => (
                  <li key={product.id}>
                    {getVATRateName(product.vatRateId)}:{" "}
                    {getVATRate(product.vatRateId)}%
                  </li>
                ))}
              </ul>
            </div> */}
            <div className="summary-row amount-due">
              <span>{t("AMOUNT DUE")}</span>
              <span>
                {calculateTotal()}
                {baseCurrencySymbol}
              </span>
            </div>
            <div className="summary-row">
              <span>{t("PAYMENTS")}</span>
              <span>0.00{baseCurrencySymbol}</span>
            </div>
          </div>
        </div>
        <div className="pos-menu">
          <div className="cash-account-balance flex justify-between">
            {cashAccountBalance !== null ? (
              <h3>
                {t("CashAccountBalance")}: {cashAccountBalance.toFixed(2)}
                {baseCurrencySymbol}{" "}
              </h3>
            ) : (
              <h3>{t("NoCashAccountFound")}</h3>
            )}
          </div>
          <CheckoutPanel
            totalAmount={parseFloat(calculateTotal())}
            onCheckout={(
              amountReceived: number,
              cashAccountId?: number,
              paymentTermId?: number,
              exchangeRate?: number,
              selectedCurrencyDetails?: {
                id: number;
                name: string;
                symbol: string;
              },
              changeAmount?: number
            ) =>
              handleCheckout(
                amountReceived,
                handlePrint,
                cashAccountId,
                paymentTermId,
                exchangeRate,
                selectedCurrencyDetails,
                changeAmount
              )
            }
            onVoidInvoice={handleVoidInvoice}
            orderProducts={purchaseOrderProducts}
            customers={transformedSuppliers}
            selectedCustomer={selectedSupplier?.toString() || ""}
            calculateSubtotalAndDiscount={calculateSubtotalAndDiscount}
            calculateVAT={calculateVAT}
            calculateTotal={calculateTotal}
            getVATRateName={getVATRateName}
            getVATRate={getVATRate}
            openDialogOnCheckout={true}
            orderType="purchaseOrder"
            employeeID={null}
            selectedPaymentMethod={parseInt(selectedPaymentMethod)}
            onTotalDiscountChange={function (
              _discount: number,
              _discountType: "value" | "percentage"
            ): void {
              throw new Error("Function not implemented.");
            }}
          />

          <NumericKeypad
            onInput={handleNumericInput}
            onClear={handleClearInput}
            onBackspace={handleBackspace}
            showDisplay={false}
            showPay={false}
            showPoint={false}
            amountReceived={""}
            onEnter={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </div>
      </div>
      <div style={{ display: "none" }}>
        <InvoicePrint
          printerType={printerType}
          ref={invoiceRef}
          orderProducts={purchaseOrderProducts}
          customers={transformedSuppliers}
          selectedCustomer={selectedSupplier?.toString() || ""}
          calculateSubtotalAndDiscount={calculateSubtotalAndDiscount}
          calculateVAT={calculateVAT}
          calculateTotal={calculateTotal}
          getVATRateName={getVATRateName}
          getVATRate={getVATRate}
          selectedCurrencyDetails={null}
          exchangeRate={selectedExchangeRate}
          date={new Date().toLocaleDateString()} // employeeId={employeeId || ""}
        />
      </div>
      <ToastContainer />
    </div>
  );
};
export default PurchaseOrder;
