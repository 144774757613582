class CreateWarehouseDto {
  shopId?: number | null;
  name: string;
  address: string;

  constructor(Warehouse: {
    shopId?: number | null;
    name?: string;
    address?: string;
  }) {
    this.shopId = Warehouse.shopId ?? null;
    this.name = Warehouse.name ?? "";
    this.address = Warehouse.address ?? "";
  }
}

export default CreateWarehouseDto;
